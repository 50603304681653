import * as React from 'react';
import { useSelector } from 'react-redux';

import classnames from 'classnames';
import { components } from 'generated/apiTypes';
import { selectSeparatorsSolution } from 'store/taskSlice';

import TableHead from '../../../components/TableHead';
import TableWrapper from '../../../components/TableWrapper';
import { IThead } from '../../../types';
import TableRow from './TableRow';
import TemplateTableRow from './TemplateTableRow';
import { templateRowsNumber } from './utils';

export interface ITableProps {
  tableHead: IThead;
}

const AvgTable: React.FC<ITableProps> = ({ tableHead }) => {
  const avgTable = useSelector(selectSeparatorsSolution);
  const [data, setData] = React.useState<any[]>([]);
  const headKeys = Object.keys(tableHead);

  const getData = () => {
    const newData: any = [];
    (
      avgTable?.solution as components['schemas']['GetBaseSolutionSeparatorAverageStateQueryResult'][]
    ).forEach(state => {
      newData.push({
        separator_name: state.separator_name,
        start_pressure__atm: state.start_pressure__atm,
        end_pressure__atm: state.end_pressure__atm,
        start_fluid_volume_flow__m3_per_day:
          state.start_fluid_volume_flow__m3_per_day,
        end_fluid_volume_flow__m3_per_day:
          state.end_fluid_volume_flow__m3_per_day,
        start_oil_mass_flow__t_per_day: state.start_oil_mass_flow__t_per_day,
        end_oil_mass_flow__t_per_day: state.end_oil_mass_flow__t_per_day,
        start_gas_volume_flow__thos_m3_per_day:
          state.start_gas_volume_flow__thos_m3_per_day,
        end_gas_volume_flow__thos_m3_per_day:
          state.end_gas_volume_flow__thos_m3_per_day,
      });
    });
    setData(newData);
  };

  React.useEffect(() => {
    getData();
  }, [avgTable]);

  return (
    <TableWrapper vertical horizontal smoothScrolling>
      <table className={classnames('table', 'table__sources-table')}>
        <TableHead headKeys={headKeys} tableHead={tableHead} />
        <tbody>
          {data?.length > 0
            ? data.map((value: any, index: number) => {
                return <TableRow key={index} headKeys={headKeys} el={value} />;
              })
            : Array.from({ length: templateRowsNumber }, (item, index) => (
                <TemplateTableRow key={index} headKeys={headKeys} />
              ))}
        </tbody>
      </table>
    </TableWrapper>
  );
};

export default AvgTable;
