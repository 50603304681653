import React from 'react';
import { connect, useDispatch } from 'react-redux';

import { ReactComponent as AddNode } from 'images/Project/Subtract.svg';
import { ReactComponent as AddSegment } from 'images/Project/Vector.svg';
import { setActiveObject } from 'store/mapSlice';

import { updateDrawState } from '../../../store/mapSlice';
import { RootState } from '../../../store/store';
import DrawButton from '../ProjectHeader/DrawButton';

function DrawPanel(props: any) {
  const dispatch = useDispatch();
  const handleClick = (elem: string, params: any) => {
    dispatch(updateDrawState({ isActive: true, activeElement: elem, params }));
    if (props.activeObject.object_uid) {
      dispatch(
        setActiveObject({
          node_uid: null,
          type: null,
          object_uid: null,
          object_name: null,
        }),
      );
    }
  };

  return (
    <div className="draw-panel">
      <div className="draw-panel__label">Создать:</div>
      {[
        {
          type: 'junction',
          name: 'Узел',
          icon: <AddNode className="draw-icon" />,
        },
        {
          type: 'segment',
          name: 'Труба',
          icon: <AddSegment className="draw-icon" />,
        },
      ].map((item: any, i: any) => (
        <DrawButton
          key={i}
          items={item}
          handleClick={handleClick}
          drawState={props.drawState}
        />
      ))}
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  drawState: state.map.drawState,
  activeObject: state.map.activeObject,
});

export default connect(mapStateToProps)(DrawPanel);
