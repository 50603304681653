import * as React from 'react';

import UATable from './Table/Table';
import { nodesTableHead } from './Table/utils';

const Nodes: React.FC = () => {
  return <UATable tableHead={nodesTableHead} nodeUids={[]} />;
};

export default Nodes;
