import * as React from 'react';

import { threeDigitsFormatter, twoDigitsFormatter } from 'utils';

export const templateRowsNumber = 5;

export const layersTableHead = {
  name: 'Название',
  oil_flow_rate: {
    title: 'Расход нефти',
    component: (
      <>
        Расход нефти,
        <br /> т/сут
      </>
    ),
  },
  water_flow_rate: {
    title: 'Расход воды',
    component: (
      <>
        Расход воды,
        <br /> м<span className="sup">3</span>/сут
      </>
    ),
  },
  gas_flow_rate: {
    title: 'Расход газа',
    component: (
      <>
        Расход газа, <br />
        тыс.м<span className="sup">3</span>/сут
      </>
    ),
  },
  injection_flow_rate: {
    title: 'Расход закачки',
    component: (
      <>
        Расход закачки,
        <br /> м<span className="sup">3</span>/сут
      </>
    ),
  },
  pressure: {
    title: 'Пластовое давление',
    component: (
      <>
        Пластовое давление, <br />
        атм
      </>
    ),
  },
};

export const layersTableItems = ({
  name,
  oil_production__t_per_day,
  oil_production_abs__t_per_day,
  oil_production_rel,
  water_production__m3_per_day,
  water_production_abs__m3_per_day,
  water_production_rel,
  gas_production__m3_per_day,
  gas_production_abs__m3_per_day,
  gas_production_rel,
  water_injection__m3_per_day,
  water_injection_abs__m3_per_day,
  water_injection_rel,
  pressure__atm,
  pressure_abs__atm,
  pressure_rel,
}: any = {}) => [
  {
    dem: 'name',
    value: name,
  },
  {
    dem: 'oil_flow_rate',
    value: oil_production__t_per_day,
    abs: oil_production_abs__t_per_day,
    rel: oil_production_rel,
    unit: 'т/сут',
  },
  {
    dem: 'water_flow_rate',
    value: water_production__m3_per_day,
    abs: water_production_abs__m3_per_day,
    rel: water_production_rel,
    unit: (
      <>
        м<span className="sup">3</span>/сут
      </>
    ),
  },
  {
    dem: 'gas_flow_rate',
    value: gas_production__m3_per_day / 1000,
    abs: gas_production_abs__m3_per_day / 1000,
    rel: gas_production_rel,
    unit: (
      <>
        тыс. м<span className="sup">3</span>/сут
      </>
    ),
  },
  {
    dem: 'injection_flow_rate',
    value: water_injection__m3_per_day,
    abs: water_injection_abs__m3_per_day,
    rel: water_injection_rel,
    unit: (
      <>
        м<span className="sup">3</span>/сут
      </>
    ),
  },
  {
    dem: 'pressure',
    value: pressure__atm,
    abs: pressure_abs__atm,
    rel: pressure_rel,
    unit: 'атм',
  },
];
