import * as React from 'react';

export const templateRowsNumber = 5;

export const separatorsAvgTableHead = {
  separator_name: 'Название',
  start_pressure__atm: {
    title: 'Давление (вход.)',
    component: (
      <>
        Давление (вход.), <br />
        атм
      </>
    ),
  },
  end_pressure__atm: {
    title: 'Давление (вых.)',
    component: (
      <>
        Давление (вых.), <br />
        атм
      </>
    ),
  },
  start_fluid_volume_flow__m3_per_day: {
    title: 'Расход жидкости (вход.)',
    component: (
      <>
        Расход жидкости <br />
        (вход.), м3/сут
      </>
    ),
  },
  end_fluid_volume_flow__m3_per_day: {
    title: 'Расход жидкости (вых.)',
    component: (
      <>
        Расход жидкости (вых.), <br />
        м3/сут
      </>
    ),
  },
  start_oil_mass_flow__t_per_day: {
    title: 'Расход нефти (вход.)',
    component: (
      <>
        Расход нефти (вход.), <br />
        т/сут
      </>
    ),
  },
  end_oil_mass_flow__t_per_day: {
    title: 'Расход нефти (вых.)',
    component: (
      <>
        Расход нефти (вых.), <br />
        т/сут
      </>
    ),
  },
  start_gas_volume_flow__thos_m3_per_day: {
    title: 'Расход газа (вход.)',
    component: (
      <>
        Расход газа (вход.), <br />
        тыс. м3/сут
      </>
    ),
  },
  end_gas_volume_flow__thos_m3_per_day: {
    title: 'Расход газа (вых.)',
    component: (
      <>
        Расход газа (вых.), <br />
        тыс. м3/сут
      </>
    ),
  },
};
