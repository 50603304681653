import { useParams } from 'react-router';

import axios, { AxiosRequestConfig } from 'axios';

import { handleStorage } from './services/handleStorage';
import store from './store/store';
import RequestSocketActions from './utils/appDB/RequestSocketActions';
// import RequestHttpsActions from './utils/appDB/RequestHttpsActions';
import updateAuthToken from './utils/updateAuthToken';

// const requestHttpsActions = new RequestHttpsActions();
const requestSocketActions = new RequestSocketActions();

let baseURL: string;
let wsURL: string;
if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  baseURL = 'https://api.pno.numdes.com';
  wsURL = 'wss://api.pno.numdes.com';
} else if (process.env.REACT_APP_ENVIRONMENT === 'development') {
  baseURL = 'https://develop.api.pno.numdes.com';
  wsURL = 'wss://develop.api.pno.numdes.com';
} else if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
  baseURL = 'https://staging.api.pno.numdes.com';
  wsURL = 'wss://staging.api.pno.numdes.com';
} else {
  baseURL = process.env.REACT_APP_ENVIRONMENT as string;
  if (baseURL?.startsWith('https')) {
    wsURL = `wss${baseURL?.replace('https', '')}`;
  } else {
    wsURL = `ws${baseURL?.replace('http', '')}`;
  }
}
export const WSURL = wsURL;
const config: AxiosRequestConfig = {
  baseURL: baseURL as string,
  headers: {
    'Content-Type': 'application/json',
  },
};

export const api = axios.create(config);

const handleRequest = (request: AxiosRequestConfig) => {
  const dispatch = store.dispatch;
  const socketState: any = store.getState().socket.socketState;
  if (socketState.isLoading || socketState.isConnect) {
    dispatch({ type: 'SOCKET_DISCONNECT' });
  }
  if (!request.headers?.common?.Authorization) {
    const token = handleStorage.getToken();
    if (!token) {
      return request;
    }
    updateAuthToken(token);
    request.headers.common.Authorization = `Bearer ${token}`;
  }
  return request;
};

const handleResponse = async (res: any) => {
  return res;
};

const handleError = async (error: any) => {
  if (error?.response?.status === 401) {
    handleStorage.removeToken();
    updateAuthToken(false);
    if (!window.location.href.includes('login')) {
      window.location.replace('#/login');
    }
  }

  return Promise.reject(error);
};

api.interceptors.request.use(handleRequest);

api.interceptors.response.use((res: any) => handleResponse(res), handleError);
