import * as React from 'react';
import { useSelector } from 'react-redux';
import { Tabs } from 'react-tabs';

import classnames from 'classnames';
import { selectWellCompareStateTable } from 'store/taskSlice';

import TableHead from '../../components/TableHead';
import TableWrapper from '../../components/TableWrapper';
import { IThead } from '../../types';
import CompareTableRow from '../Sources/UA/Table/CompareTableRow';
import TemplateTableRow from '../Sources/UA/Table/TemplateTableRow';
import {
  compareTableItems,
  compareWellsTableHead,
  templateRowsNumber,
} from '../Sources/UA/Table/utils';

interface IWellCompareTableProps {
  tableHead: IThead;
}
const CompareWellTable: React.FC<IWellCompareTableProps> = ({ tableHead }) => {
  const compareWellsTable = useSelector(selectWellCompareStateTable);
  const [data, setData] = React.useState<any[]>([]);
  const headKeys = Object.keys(tableHead);

  React.useEffect(() => {
    setData(compareWellsTable?.states);
  }, [compareWellsTable]);

  return (
    <TableWrapper
      vertical
      horizontal
      smoothScrolling
      className="compare-table"
      verticalContainerStyle={{ height: '45vh' }}
    >
      <table className={classnames('table', 'table__sources-table')}>
        <TableHead headKeys={headKeys} tableHead={tableHead} />
        <tbody>
          {data?.length > 0
            ? data.map((value: any, index: number) => {
                return (
                  <CompareTableRow
                    key={index}
                    headKeys={headKeys}
                    el={compareTableItems(value)}
                  />
                );
              })
            : Array.from({ length: templateRowsNumber }, (item, index) => (
                <TemplateTableRow key={index} headKeys={headKeys} />
              ))}
        </tbody>
      </table>
    </TableWrapper>
  );
};
const UACompareResults: React.FC = () => {
  const [tabIndex, setTabIndex] = React.useState(1);

  return (
    <Tabs
      className="results__tabs"
      selectedIndex={tabIndex}
      onSelect={index => setTabIndex(index)}
    >
      <div
        className="results__tab-list underline"
        style={{ marginBottom: '3vh' }}
      >
        <div className="results__tab compare">Скважины</div>
      </div>
      <div className="results-tables__container">
        <CompareWellTable tableHead={compareWellsTableHead} />
      </div>
    </Tabs>
  );
};

export default UACompareResults;
