import * as React from 'react';

export const templateRowsNumber = 5;

export const layersTableHead = {
  name: 'Название',
  oil_flow_rate: {
    title: 'Расход нефти',
    component: (
      <>
        Расход нефти,
        <br /> т/сут
      </>
    ),
  },
  water_flow_rate: {
    title: 'Расход воды',
    component: (
      <>
        Расход воды,
        <br /> м<span className="sup">3</span>/сут
      </>
    ),
  },
  gas_flow_rate: {
    title: 'Расход газа',
    component: (
      <>
        Расход газа, <br />
        тыс.м<span className="sup">3</span>/сут
      </>
    ),
  },
  water_injection: {
    title: 'Расход закачки',
    component: (
      <>
        Расход закачки,
        <br /> м<span className="sup">3</span>/сут
      </>
    ),
  },
  pressure: {
    title: 'Пластовое давление',
    component: (
      <>
        Пластовое давление, <br />
        атм
      </>
    ),
  },
};
