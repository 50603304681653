import React from 'react';

import { components } from '../../../../../generated/apiTypes';
import {
  formatter,
  oneDigitsFormatter,
  smartFormat,
} from '../../../../../utils';
import { seriesColors } from '../../WellModel/Chart/utils';

const useChartData = (pump: components['schemas']['PNOPump'] | undefined) => {
  const { flows } = React.useMemo(() => {
    if (pump?.volume_fluid_flows__m3_per_day) {
      return {
        flows: pump?.volume_fluid_flows__m3_per_day?.map((flow: any) => {
          return oneDigitsFormatter.format(flow);
        }),
      };
    }
    return {};
  }, [pump?.volume_fluid_flows__m3_per_day]);

  return React.useMemo(() => {
    if (flows) {
      const chartData: { name: string; data: any[] }[] = [];
      chartData.push({
        name: '',
        data: flows.map((flow: any, flow_ix: number) => {
          return pump?.head__m && pump?.head__m[flow_ix];
        }),
      });

      return {
        labels: flows,
        datasets: Array.from(chartData).map((el, index) => {
          return {
            label: el.name,
            data: el.data,
            fill: false,
            backgroundColor: seriesColors[index % 9],
            borderColor: seriesColors[index % 9],
            color: seriesColors[index % 9],
            borderWidth: 1,
            tension: 0.1,
            pointHoverBorderWidth: 0,
            pointRadius: 0,
            pointHitRadius: 0,
            pointBorderWidth: 1,
            pointHoverRadius: 1,
          };
        }),
      };
    }
    return { labels: [], datasets: [] };
  }, [flows, pump?.head__m]);
};

export default useChartData;
