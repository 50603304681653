import * as React from 'react';
import { useSelector } from 'react-redux';

import classnames from 'classnames';
import { components } from 'generated/apiTypes';
import { selectSolution } from 'store/taskSlice';

import TableHead from '../../../../components/TableHead';
import TableWrapper from '../../../../components/TableWrapper';
import { IThead } from '../../../../types';
import TableRow from './TableRow';
import TemplateTableRow from './TemplateTableRow';
import { templateRowsNumber } from './utils';

export interface ISourcesTableProps {
  tableHead: IThead;
  keyD: string;
}

const SourcesByStepTable: React.FC<ISourcesTableProps> = ({
  tableHead,
  keyD,
}) => {
  const solution = useSelector(selectSolution)
    ?.solution as components['schemas']['PNOPeriodicNodalAnalysisProblem'];
  const [data, setData] = React.useState<any[]>([]);
  const headKeys = Object.keys(tableHead);

  const getData = () => {
    const newData: any = [];
    solution?.solution?.well_step_table?.time2states[keyD]?.forEach(state => {
      newData.push({
        current_state: state.current_state,
        well_name: state.well_name,
        operation_type: state.operation_type,
        control_type: state.control_type,
        offset_time: state.offset_time,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        fluid_flow_rate: state.fluid_flow_rate?.m3_per_day,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        oil_flow_rate: state.oil_flow_rate?.t_per_day,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        gas_flow_rate: state.gas_flow_rate?.thousand_m3_per_day,
        power: state.operation_type === 'ЭЦН' && state.power?.kW,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        pressure: state.pressure?.atm,
        control_flow:
          state.operation_type === 'Газлифт' &&
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          state.control?.thousand_m3_per_day,
        control_freq:
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          state.operation_type === 'ЭЦН' && state.control?.hertz,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        accumulation_freq: state.accumulation_control?.hertz,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        interval: state.operation_type === 'Время' && state.control?.value,
        work_time: state.work_time__min,
        accumulation_time: state.accumulation_time__min,
      });
    });

    setData(newData);
  };

  React.useEffect(() => {
    getData();
  }, [solution, keyD]);

  return (
    <TableWrapper vertical horizontal smoothScrolling>
      <table className={classnames('table', 'table__sources-table')}>
        <TableHead headKeys={headKeys} tableHead={tableHead} />
        <tbody>
          {data?.length > 0
            ? data.map((value: any, index: number) => {
                return <TableRow key={index} headKeys={headKeys} el={value} />;
              })
            : Array.from({ length: templateRowsNumber }, (item, index) => (
                <TemplateTableRow key={index} headKeys={headKeys} />
              ))}
        </tbody>
      </table>
    </TableWrapper>
  );
};

export default SourcesByStepTable;
