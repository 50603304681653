import * as React from 'react';

export const templateRowsNumber = 5;

export const equipmentsTableHead = {
  name: 'Название',
  type: {
    title: 'Тип',
    component: <>Тип</>,
  },
  in_oil_flow_rate: {
    title: 'Расход нефти',
    component: (
      <>
        Расход нефти (Вх.),
        <br /> т/сут
      </>
    ),
  },
  out_oil_flow_rate: {
    title: 'Расход нефти',
    component: (
      <>
        Расход нефти (Вых.),
        <br /> т/сут
      </>
    ),
  },
  in_fluid_flow_rate: {
    title: 'Расход жидкости',
    component: (
      <>
        Расход жидкости (Вх.),
        <br /> м<span className="sup">3</span>/сут
      </>
    ),
  },
  out_fluid_flow_rate: {
    title: 'Расход жидкости',
    component: (
      <>
        Расход жидкости (Вых.),
        <br /> м<span className="sup">3</span>/сут
      </>
    ),
  },
  in_gas_flow_rate: {
    title: 'Расход газа',
    component: (
      <>
        Расход газа (Вх.), <br />
        тыс.м<span className="sup">3</span>/сут
      </>
    ),
  },
  out_gas_flow_rate: {
    title: 'Расход газа',
    component: (
      <>
        Расход газа (Вых.), <br />
        тыс.м<span className="sup">3</span>/сут
      </>
    ),
  },
  in_pressure: {
    title: 'Давление',
    component: (
      <>
        Давление (Вх.), <br />
        атм
      </>
    ),
  },
  out_pressure: {
    title: 'Давление',
    component: (
      <>
        Давление (Вых.), <br />
        атм
      </>
    ),
  },
};
