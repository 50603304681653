import * as React from 'react';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { AxiosResponse } from 'axios';
import Map from 'Components/Map';
import { resetCommonSliceState } from 'store/commonSlice';
import { resetMapSliceState } from 'store/mapSlice';
import {
  resetProjectSliceState,
  selectNodes,
  selectProject,
  selectProjectId,
  selectReservoir,
} from 'store/projectSlice';
import {
  resetTaskSliceState,
  selectExperimentSettings,
  selectExperimentStatus,
  selectExperimentType,
  selectShowResults,
  selectSolution,
  selectTaskId,
  setCalculationTime,
  setExperimentMessage,
  setExperimentStatus,
  setIsActual,
  setPercentage,
  setSolution,
  setTaskId,
} from 'store/taskSlice';

import { WSURL } from '../../api';
import DialogWindow from '../../Components/DialogWindow';
import { components } from '../../generated/apiTypes';
import {
  calcFactAdaptationTask,
  calcModeOptimizationTask,
  calcNodalAnalysisTask,
  calcReservoirBaseTask,
  calcReservoirOptimizationTask,
  getTasks,
  setExperimentSettings,
} from '../../services/apiRequests';
import { handleStorage } from '../../services/handleStorage';
import PipesCatalog from './DetailsPanel';
import DrawPanel from './DrawPanel/DrawPanel';
import fetchProject from './hooks/use-get-project';
import useGetSolution from './hooks/use-get-solution';
import Issues from './Issues';
import LeftCol from './LeftCol';
import LeftColBottom from './LeftColBottom';
import NetworkObjects from './NetworkObjects';
import ProjectHeader from './ProjectHeader/ProjectHeader';
import ProjectMap from './ProjectMap';
import RightCol from './RightCol';
import { taskByExperimentType } from './utils';

import './style.scss';

const Project: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams() as { id: string };
  const taskType = useSelector(selectExperimentType);
  const storeSolution = useSelector(selectSolution)?.solution;
  const status = useSelector(selectExperimentStatus);
  const experimentSettings = useSelector(selectExperimentSettings);
  const showRes = useSelector(selectShowResults);
  const nodes = useSelector(selectNodes);
  const projectID = useSelector(selectProjectId);
  const taskId = useSelector(selectTaskId);
  const reservoir = useSelector(selectReservoir);
  const timerId = React.useRef<ReturnType<typeof setTimeout>>();
  const wsocket = useRef<WebSocket | undefined>(undefined);
  useGetSolution(params.id, taskId, status);

  const resetStore = () => {
    dispatch(resetTaskSliceState());
    dispatch(resetProjectSliceState());
    dispatch(resetMapSliceState());
    dispatch(resetCommonSliceState());
    wsocket.current = disconnect(wsocket.current!);
  };

  React.useEffect(() => {
    resetStore();
    fetchProject(params.id, dispatch);
    return () => resetStore();
  }, [params.id]);

  const fetchTasks = async () => {
    if (timerId.current) clearInterval(timerId.current);
    try {
      const response = await getTasks(params.id);
      if (response?.data?.length > 0) {
        const task = response?.data
          ?.filter(
            item =>
              item.task_type ===
              taskByExperimentType(taskType, reservoir?.uid !== undefined),
          )
          .slice(-1)[0];
        task && dispatch(setIsActual(task.is_actual!));
        if (task?.task_uid) {
          dispatch(
            setSolution({
              type: taskType,
              solution: storeSolution,
            }),
          );
          dispatch(setTaskId(task?.task_uid));
          wsocket.current = connect(task?.task_uid);
          const currentStatus = status;
          if (currentStatus) {
            dispatch(setExperimentStatus(currentStatus));
          } else {
            dispatch(setSolution({ type: 'Базовый режим', solution: null }));
            dispatch(setExperimentStatus('NONE'));
          }
        } else {
          dispatch(setSolution({ type: 'Базовый режим', solution: null }));
          dispatch(setExperimentStatus('NONE'));
        }
      } else {
        dispatch(setSolution({ type: 'Базовый режим', solution: null }));
        dispatch(setExperimentStatus('NONE'));
      }
    } catch (e) {
      //
    }
  };

  React.useEffect(() => {
    projectID && fetchTasks();
    return () => {
      if (timerId.current) clearInterval(timerId.current);
    };
  }, [taskType, projectID, params.id]);

  const clearTaskId = () => {
    dispatch(setTaskId(''));
  };

  const calcTask = async () => {
    dispatch(setSolution({ type: taskType, solution: null }));
    clearTaskId();
    try {
      let response:
        | AxiosResponse<components['schemas']['TaskStatusTracer']>
        | undefined;
      switch (taskType) {
        case 'Базовый режим':
          if (reservoir?.uid) {
            response = await calcReservoirBaseTask({ project_id: params.id });
          } else {
            // await setExperimentSettings(params.id, experimentSettings);
            response = await calcNodalAnalysisTask({ project_id: params.id });
          }
          break;
        case 'Оптимизация режима':
          if (reservoir?.uid) {
            // await setExperimentSettings(params.id, experimentSettings);
            response = await calcReservoirOptimizationTask({
              project_id: params.id,
            });
          } else
            response = await calcModeOptimizationTask({
              project_id: params.id,
            });
          break;
        case 'Адаптация на факт':
          response = await calcFactAdaptationTask({ project_id: params.id });
          break;
        default:
          break;
      }
      if (response?.data?.status)
        dispatch(setExperimentStatus(response.data.status));
      if (response?.data?.task_uid) {
        wsocket.current = connect(response?.data?.task_uid);
      }
      dispatch(setTaskId(response?.data?.task_uid as string));
    } catch (e) {
      //
    }
  };

  const mapCenter = [
    nodes.items[0]?.coordinates?.[0] || 55.24747026552241,
    nodes.items[0]?.coordinates?.[1] || 51.59632314294971,
  ] as [number, number];

  const mapComponent = React.useMemo(
    () => (
      <Map
        mapProps={{ center: mapCenter }}
        height={showRes ? 'calc(100vh - 56px)' : 'calc(100vh - 100px)'}
      >
        <ProjectMap center={mapCenter} />
      </Map>
    ),
    [
      nodes.items[0]?.coordinates?.[0],
      nodes.items[0]?.coordinates?.[1],
      showRes,
    ],
  );

  const connect = (taskUID: string) => {
    const token = handleStorage.getToken();
    const url: any = `${WSURL}/api/tasks/${taskUID}/status?token=${token}`;
    const socket = new WebSocket(url);
    if (socket) {
      socket.onopen = () => {
        console.log(`Соединение ${url.split('?')[0]} установлено`);
      };

      socket.onmessage = (e: any) => {
        const message = JSON.parse(e.data);
        dispatch(setExperimentStatus(message.status));
        dispatch(setPercentage(message.percentage));
        dispatch(setExperimentMessage(message.message));
        dispatch(setCalculationTime(message.ts_calculation_state));
      };
      socket.onclose = () => {
        console.log(`Соединение ${url.split('?')[0]} закрыто`);
      };
    }
    return socket;
  };
  const disconnect = (socket: WebSocket) => {
    if (socket) {
      socket.close(1000, 'Disconnect');
    }
    return undefined;
  };
  return (
    <div className="project-container">
      <DialogWindow />
      <ProjectHeader />
      <div className="d-flex">
        <LeftCol showResultPanel={showRes} taskId={taskId}>
          <NetworkObjects />
          {!!params.id && <LeftColBottom taskId={params.id} />}
        </LeftCol>
        {mapComponent}
        {!showRes && (
          <>
            <Issues />
            <DrawPanel />
            <PipesCatalog />
            <div className="project-task-settings__right-col">
              <RightCol calcTask={calcTask} projectUid={params.id} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Project;
