import * as React from 'react';

import UATable from './Table/Table';
import { equipmentsTableHead } from './Table/utils';

const Equipments: React.FC = () => {
  return <UATable tableHead={equipmentsTableHead} />;
};

export default Equipments;
