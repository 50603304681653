import * as React from 'react';

import SegmentsAvgTable from './SegmentsTable';
import { segmentsTableHead } from './utils';

const Table: React.FC = () => {
  return <SegmentsAvgTable tableHead={segmentsTableHead} />;
};

export default Table;
