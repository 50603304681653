import * as React from 'react';
import { useSelector } from 'react-redux';

import classnames from 'classnames';
import { components } from 'generated/apiTypes';
import { selectSolution } from 'store/taskSlice';

import TableHead from '../../../../components/TableHead';
import TableWrapper from '../../../../components/TableWrapper';
import { ISegmentsTableProps } from '../../../../types';
import TableRow from './TableRow';
import TemplateTableRow from './TemplateTableRow';
import { templateRowsNumber } from './utils';

const SegmentsAvgTable: React.FC<ISegmentsTableProps> = ({ tableHead }) => {
  const solution = useSelector(selectSolution)
    ?.solution as components['schemas']['PNOPeriodicNodalAnalysisProblem'];
  const [data, setData] = React.useState<any[]>([]);
  const headKeys = Object.keys(tableHead);

  const getData = () => {
    const newData: any = [];
    solution?.solution?.segment_avg_table?.states.forEach(segment => {
      newData.push({
        start_node_name: segment?.start_node_name,
        end_node_name: segment?.end_node_name,

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        p_in: segment.pressure_in?.atm,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dp_dl: segment.dp_dl?.atm_per_km,
        dt_dl:
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          segment.dt_dl?.celsius_per_km,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        oil_flow_rate: segment.oil_flow_rate_mass?.t_per_day,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        fluid_flow_rate: segment.fluid_flow_rate_volume?.m3_per_day,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        gas_flow_rate: segment.gas_flow_rate_volume?.thousand_m3_per_day,
        fluid_velocity: segment.fluid_velocity?.m_per_second,
        gas_velocity: segment.gas_velocity?.m_per_second,
      });
    });
    setData(newData);
  };

  React.useEffect(() => {
    getData();
  }, [solution]);

  return (
    <TableWrapper vertical horizontal smoothScrolling>
      <table className={classnames('table', 'table__segments-table')}>
        <TableHead headKeys={headKeys} tableHead={tableHead} />
        <tbody>
          {data?.length > 0
            ? data.map((value: any, index: number) => {
                return <TableRow key={index} headKeys={headKeys} el={value} />;
              })
            : Array.from({ length: templateRowsNumber }, (item, index) => (
                <TemplateTableRow key={index} headKeys={headKeys} />
              ))}
        </tbody>
      </table>
    </TableWrapper>
  );
};

export default SegmentsAvgTable;
