import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import {
  selectPumpById,
  selectShowDetailsPanel,
  setShowDetailsPanel,
} from 'store/projectSlice';

import { ReactComponent as DetailsIcon } from '../../../../images/Project/icn-details.svg';
import { ReactComponent as PumpPPDIcon } from '../../../../images/Project/icn-pump-ppd.svg';
import { IObject } from '../../types';

const PumpPPD: React.FC<IObject> = ({ objectUid }) => {
  const dispatch = useDispatch();
  const pumpById = useSelector(selectPumpById);
  const showDetailsPanel = useSelector(selectShowDetailsPanel);

  const handleToggleDetails = () => {
    if (showDetailsPanel === 'pumpModel') dispatch(setShowDetailsPanel(null));
    else dispatch(setShowDetailsPanel('pumpModel'));
  };

  return (
    <div className="active-object__content full-width">
      <div className="active-object__top-row source-state-row">
        <p className="active-object__title">
          <PumpPPDIcon />
          {pumpById[objectUid as string]?.name}
        </p>
        <button
          className={classNames(
            'active-object__well-model-button',
            showDetailsPanel === 'wellModel' && 'active',
          )}
          type="button"
          onClick={handleToggleDetails}
        >
          <DetailsIcon className="well-model-button-icon" />
        </button>
      </div>
    </div>
  );
};

export default PumpPPD;
