import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import {
  selectExperimentSettings,
  setExperimentSettings as setStoreExperimentSettings,
} from 'store/taskSlice';

import { setExperimentSettings } from '../../../../services/apiRequests';
import { selectClusters } from '../../../../store/projectSlice';
import ObjectSelect from '../../ActiveObject/components/ObjectSelect';
import { ConstraintsType } from '../../types';
import { getConstraintsTypeOptions } from '../options';
import ClusterEqualityConstraint from './ClusterEqualityConstraint';
import ClusterInequalityConstraint from './ClusterInequalityConstraint';
import EqualityConstraint from './EqualityConstraint';
import InequalityConstraint from './InequalityConstrant';

interface MOConstraintsProps {
  setExperimentSettingsSaved(val: boolean): void;
  setIncompleteConstraints(val: boolean): void;
  disabled?: boolean;
}

const MOConstraints: React.FC<MOConstraintsProps> = ({
  setExperimentSettingsSaved,
  setIncompleteConstraints,
  disabled,
}) => {
  const dispatch = useDispatch();
  const params = useParams() as { id: string };
  const settings = useSelector(selectExperimentSettings);
  const clusters = useSelector(selectClusters);
  const [constraintType, setConstraintType] = React.useState<ConstraintsType>();
  const [constraintTypeOptionsCurrent, setConstraintTypeOptionsCurrent] =
    React.useState<
      {
        value: ConstraintsType;
        label: string;
        disabled: boolean;
      }[]
    >(getConstraintsTypeOptions());

  React.useEffect(() => {
    if (settings?.optimization_experiment_settings?.constraints?.type)
      setConstraintType(
        settings?.optimization_experiment_settings?.constraints?.type,
      );
  }, [settings?.optimization_experiment_settings?.constraints?.type]);

  React.useEffect(() => {
    if (clusters?.filter(clusterItem => !clusterItem.is_empty)?.length > 0)
      setConstraintTypeOptionsCurrent(
        getConstraintsTypeOptions().map(item => {
          if (
            [
              'ClusterEqualityNetworkOptimizationExperimentSettingsConstraints',
              'ClusterInequalityNetworkOptimizationExperimentSettingsConstraints',
            ].includes(item.value)
          ) {
            return { ...item, disabled: false };
          }
          return item;
        }),
      );
  }, [clusters]);

  React.useEffect(() => {
    setConstraintType(
      settings?.optimization_experiment_settings?.constraints?.type,
    );
    setIncompleteConstraints(false);
  }, [settings?.optimization_experiment_settings?.target_metric]);

  React.useEffect(() => {
    if (
      settings?.optimization_experiment_settings?.constraints?.type !==
        constraintType &&
      constraintType !== undefined
    ) {
      if (
        constraintType !==
        'EmptyNetworkOptimizationExperimentSettingsConstraints'
      ) {
        setIncompleteConstraints(true);
      } else {
        tryToSaveConstraint().then(() => setIncompleteConstraints(false));
      }
    }
  }, [constraintType]);

  const onChangeConstraintType = (value: ConstraintsType) => {
    setConstraintType(value);
  };

  const tryToSaveConstraint = async () => {
    if (settings?.optimization_experiment_settings) {
      const newValue = {
        ...settings,
        optimization_experiment_settings: {
          ...settings.optimization_experiment_settings,
          constraints: {
            type: 'EmptyNetworkOptimizationExperimentSettingsConstraints' as any,
          },
        },
      };
      dispatch(setStoreExperimentSettings(newValue));
      setExperimentSettingsSaved(false);
      await setExperimentSettings(params.id, newValue);
      setExperimentSettingsSaved(true);
    }
  };

  return (
    <>
      <p className="project-task-settings__title constraints-title">
        Ограничения
      </p>
      <div className="active-object__row">
        <ObjectSelect
          label="Тип ограничений"
          classNamesWrapper="full-width"
          classNames="active-object__row-input"
          name=""
          options={constraintTypeOptionsCurrent || []}
          value={constraintTypeOptionsCurrent?.find(
            item => item.value === constraintType,
          )}
          isOptionDisabled={(option: any) => (option as any)?.disabled}
          saveNewValue={onChangeConstraintType}
          disabled={disabled}
        />
      </div>
      {constraintType ===
        'GlobalEqualityNetworkOptimizationExperimentSettingsConstraints' && (
        <EqualityConstraint
          setIncompleteConstraints={setIncompleteConstraints}
          setExperimentSettingsSaved={setExperimentSettingsSaved}
        />
      )}
      {constraintType ===
        'GlobalInequalityNetworkOptimizationExperimentSettingsConstraints' && (
        <InequalityConstraint
          setIncompleteConstraints={setIncompleteConstraints}
          setExperimentSettingsSaved={setExperimentSettingsSaved}
        />
      )}
      {constraintType ===
        'ClusterEqualityNetworkOptimizationExperimentSettingsConstraints' && (
        <ClusterEqualityConstraint
          setIncompleteConstraints={setIncompleteConstraints}
          setExperimentSettingsSaved={setExperimentSettingsSaved}
        />
      )}
      {constraintType ===
        'ClusterInequalityNetworkOptimizationExperimentSettingsConstraints' && (
        <ClusterInequalityConstraint
          setExperimentSettingsSaved={setExperimentSettingsSaved}
          setIncompleteConstraints={setIncompleteConstraints}
        />
      )}
    </>
  );
};

export default MOConstraints;
