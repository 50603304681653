import React from 'react';

import classnames from 'classnames';

import { threeDigitsFormatter, twoDigitsFormatter } from 'utils';
import { ITableRowProps } from '../../../../types';
import { isNumber, prepareTableValue } from '../../../../utils';

const TableRow: React.FC<ITableRowProps> = ({ el, headKeys }) => {
  const getColor = (item: any) => {
    if (item.value === undefined || item.abs === 0) return '';
    if (item.dem === 'pressure' && item.abs > 0) {
      return 'red';
    }
    if (item.dem === 'oil_flow_rate' && item.abs < 0) {
      return 'red';
    }
    return 'green';
  };
  const formatFloat = (value: number, error: boolean) => {
    if (error) return twoDigitsFormatter.format(value);
    return threeDigitsFormatter.format(value);
  };
  const renderField = (item: any) => {
    return (
      <div>
        <p className={classnames('compare-field', 'value')}>
          {isNumber(item.value) ? formatFloat(item.value, false) : item.value}
        </p>
        {!['well_name', 'operation_type', 'control_type'].includes(item.dem) &&
          !!item.value && (
            <div className={classnames('compare-field', 'compare')}>
              <p>
                <text>
                  {item.abs !== undefined && prepareTableValue(item.abs)}
                </text>
                <text>{item.abs !== undefined && item.unit}</text>
              </p>
              <p>
                <text>
                  {item.rel !== undefined && prepareTableValue(item.rel)}
                </text>
                <text>{item.rel !== undefined && '%'}</text>
              </p>
            </div>
          )}
      </div>
    );
  };
  return (
    <tr className={classnames('table-row')}>
      {el.map((item: any) => (
        <td
          className={classnames(
            'table-data compare',
            ['name'].includes(item.dem) && 'left',
            ['oil_flow_rate', 'pressure'].includes(item.dem) && getColor(item),
          )}
          key={item.dem}
        >
          {renderField(item)}
        </td>
      ))}
    </tr>
  );
};

export default TableRow;
