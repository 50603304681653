import React from 'react';
import { Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { components } from '../../../../generated/apiTypes';
import { ReactComponent as ChartIcon } from '../../../../images/Project/icn-line-chart.svg';
import { ReactComponent as TableIcon } from '../../../../images/Project/icn-table.svg';
import { selectActiveObject } from '../../../../store/mapSlice';
import { selectPumpById } from '../../../../store/projectSlice';
import { getOptions } from '../WellModel/Chart/utils';
import useChartData from './hooks/use-chart-data';
import ViewModel from './Table/ViewModel';

/* export const mockPump = {
  uid: 'ae1d337e-fca4-41b1-ba00-044f60204c61',
  name: 'Pump',
  in_node_uid: '5edfd023-c04c-4190-a2b0-f1e12ea37d98',
  out_node_uid: 'c9ab18f0-242c-4509-982c-d0448fc775d0',
  volume_fluid_flows__m3_per_day: [1.0, 100.0],
  head__m: [100.0, 10.0],
  powers__kW: [1.0, 100.0],
}; */

const pumpModel: React.FC = () => {
  const pumpById = useSelector(selectPumpById);
  const activeObject = useSelector(selectActiveObject);
  const [tabIndex, setTabIndex] = React.useState<number>(0);

  const pump: components['schemas']['PNOPump'] | undefined =
    React.useMemo(() => {
      if (activeObject?.object_uid) {
        return pumpById[activeObject?.object_uid];
      }
      return undefined;
    }, [activeObject]);

  const chartData = useChartData(pump);

  return (
    <>
      {pump?.volume_fluid_flows__m3_per_day &&
      pump?.volume_fluid_flows__m3_per_day.length > 0 ? (
        <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
          <div className="well-model-panel__tab-list-wrapper">
            <TabList className="results__tab-list">
              <Tab className="results__icon">
                <ChartIcon />
              </Tab>
              <Tab className="results__icon" disabled>
                <TableIcon />
              </Tab>
            </TabList>
          </div>
          <div>
            <TabPanel>
              <div className="results__single-chart">
                <div className="well-model-panel__chart-container">
                  <Line
                    data={chartData}
                    options={getOptions('Head', '') as any}
                  />
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <ViewModel pump={pump} />
            </TabPanel>
          </div>
        </Tabs>
      ) : (
        <p className="no-model__message">
          Модель насоса отсутствует в данных конфигурационного файла.
        </p>
      )}
    </>
  );
};

export default pumpModel;
