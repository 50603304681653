import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectActiveObject } from 'store/mapSlice';
import { selectWellModelById } from 'store/projectSlice';

import Button from '../../../../../Components/Button';
import { components } from '../../../../../generated/apiTypes';
import { oneDigitsFormatter, smartFormat } from '../../../../../utils';
import { IViewModel } from './types';
import { getTableHeaderByType, getTableValueByType } from './utils';

const ViewModel: React.FC<IViewModel> = ({
  tableType,
  wellOperationType,
  editModel,
}) => {
  const wellsModelsById = useSelector(selectWellModelById);
  const activeObject = useSelector(selectActiveObject);
  const [wellModel, setWellModel] =
    React.useState<components['schemas']['GetWellModelQueryResult']>();

  React.useEffect(() => {
    setWellModel(wellsModelsById[activeObject.object_uid as string]);
  }, [activeObject.object_uid]);

  const getRowSpan = () => {
    if (wellOperationType === 'ЭЦН') {
      const controlActionsLength = (
        wellModel?.model as components['schemas']['GetPeriodicPumpWellModelQueryResult']
      )?.frequencies__Hz?.length;
      if (controlActionsLength < 3) return 3;
      return controlActionsLength;
    }
    if (wellOperationType === 'Время') {
      const controlActionsLength = (
        wellModel?.model as components['schemas']['GetPermanentTimedWellModelQueryResult']
      )?.work_intervals__min?.length;
      if (controlActionsLength < 5) return 5;
      return controlActionsLength;
    }
    if (wellOperationType === 'Газлифт') {
      const controlActionsLength = (
        wellModel?.model as components['schemas']['GetPermanentGasliftWellModelQueryResult']
      )?.gaslift_flows__thos_m3_per_day?.length;
      if (controlActionsLength < 7) return 7;
      return controlActionsLength;
    }
    return undefined;
  };

  const getControlActions = () => {
    if (wellOperationType === 'ЭЦН') {
      return (
        wellModel?.model as components['schemas']['GetPeriodicPumpWellModelQueryResult']
      )?.frequencies__Hz;
    }
    if (wellOperationType === 'Время') {
      return (
        wellModel?.model as components['schemas']['GetPermanentTimedWellModelQueryResult']
      )?.work_intervals__min;
    }
    if (wellOperationType === 'Газлифт') {
      return (
        wellModel?.model as components['schemas']['GetPermanentGasliftWellModelQueryResult']
      )?.gaslift_flows__thos_m3_per_day;
    }
    return undefined;
  };

  const rowSpan = getRowSpan();
  const controlActions = getControlActions();

  return (
    <>
      <div className="well-model-panel__tables-wrapper">
        <table className="well-model-panel__non-scrollable-table">
          <thead>
            <tr>
              <th
                rowSpan={2}
                colSpan={2}
                className="well-model-panel__table-cell well-model-panel__table-header-cell"
              >
                {' '}
              </th>
              <th
                colSpan={wellModel?.model?.pressures__atmg?.length}
                className="well-model-panel__table-cell well-model-panel__table-header-cell caption-cell"
              >
                P лин, атм
              </th>
            </tr>
            <tr>
              {wellModel?.model?.pressures__atmg?.map(() => (
                <th className="well-model-panel__table-cell well-model-panel__table-header-cell value-cell right-aligned">
                  {' '}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {controlActions?.map((item, control_action_index) => (
              <tr>
                {control_action_index === 0 && (
                  <td
                    rowSpan={rowSpan}
                    className="well-model-panel__table-cell well-model-panel__table-header-cell vertical-text"
                  >
                    {getTableHeaderByType(wellOperationType)}
                  </td>
                )}
                <td className="well-model-panel__table-cell well-model-panel__table-header-cell value-cell control-action-cell right-aligned">
                  {item && smartFormat(item)}
                </td>
                {wellModel?.model?.pressures__atmg?.map(() => (
                  <td> </td>
                ))}
              </tr>
            ))}
            {controlActions?.length &&
              rowSpan &&
              Array.from(Array(rowSpan - controlActions.length).keys()).map(
                (item, index) => (
                  <tr>
                    <td
                      key={`bottom-${index}`}
                      className="well-model-panel__table-bottom-header-cell"
                    >
                      {' '}
                    </td>
                    {wellModel?.model?.pressures__atmg?.map(() => (
                      <td className="well-model-panel__table-cell value-cell">
                        {' '}
                      </td>
                    ))}
                  </tr>
                ),
              )}
          </tbody>
        </table>
        <div className="well-model-panel__scrollable-table-wrapper">
          <table className="well-model-panel__scrollable-table">
            <thead>
              <tr>
                {wellModel?.model?.pressures__atmg?.map(item => (
                  <th className="well-model-panel__table-cell well-model-panel__table-header-cell value-cell pressure-cell right-aligned">
                    {item && oneDigitsFormatter.format(item)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {controlActions?.map((item, control_action_index) => (
                <tr>
                  {wellModel?.model?.pressures__atmg?.map(
                    (val, pressure_index) => (
                      <td className="well-model-panel__table-cell value-cell pressure-cell">
                        {getTableValueByType(
                          tableType,
                          wellModel,
                          pressure_index,
                          control_action_index,
                        )}
                      </td>
                    ),
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="well-model-panel__buttons">
        <Button type="button" mode="secondary" onClick={editModel}>
          Редактировать
        </Button>
      </div>
    </>
  );
};

export default ViewModel;
