import * as React from 'react';
import { useSelector } from 'react-redux';

import classnames from 'classnames';
import { components } from 'generated/apiTypes';
import { selectSolution } from 'store/taskSlice';

import TableWrapper from '../../../../components/TableWrapper';
import { IThead } from '../../../../types';
import TableHead from './TableHead';
import TableRow from './TableRow';
import TemplateTableRow from './TemplateTableRow';
import { templateRowsNumber } from './utils';

export interface INodesTableProps {
  tableHead: IThead;
}

const LayersTable: React.FC<INodesTableProps> = ({ tableHead }) => {
  const solution = useSelector(selectSolution)
    ?.solution as components['schemas']['PNOReservoirBaseProblem'];
  const [data, setData] = React.useState<any[]>([]);
  const headKeys = Object.keys(tableHead);

  const getData = () => {
    const newData: any[] = [];
    const state = solution?.solution?.reservoir_state;
    newData.push({
      name: state.name,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      oil_flow_rate: state?.oil_production__t_per_day,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      water_flow_rate: state?.water_production__m3_per_day,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      gas_flow_rate: state?.gas_production__m3_per_day / 1000,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      pressure: state?.pressure__atm,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      water_injection: state.water_injection__m3_per_day,
    });
    setData(newData);
  };

  React.useEffect(() => {
    getData();
  }, [solution]);

  return (
    <TableWrapper vertical horizontal smoothScrolling>
      <table className={classnames('table', 'table__nodes-table')}>
        <TableHead headKeys={headKeys} tableHead={tableHead} />
        <tbody>
          {data?.length > 0
            ? data.map((value: any, index: number) => {
                return <TableRow key={index} headKeys={headKeys} el={value} />;
              })
            : Array.from({ length: templateRowsNumber }, (item, index) => (
                <TemplateTableRow key={index} headKeys={headKeys} />
              ))}
        </tbody>
      </table>
    </TableWrapper>
  );
};

export default LayersTable;
