import * as React from 'react';

import { threeDigitsFormatter, twoDigitsFormatter } from 'utils';

export const templateRowsNumber = 5;

export const equipmentsTableHead = {
  name: 'Название',
  type: {
    title: 'Тип',
    component: <>Тип</>,
  },
  in_oil_flow_rate: {
    title: 'Расход нефти',
    component: (
      <>
        Расход нефти (Вх.),
        <br /> т/сут
      </>
    ),
  },
  out_oil_flow_rate: {
    title: 'Расход нефти',
    component: (
      <>
        Расход нефти (Вых.),
        <br /> т/сут
      </>
    ),
  },
  in_fluid_flow_rate: {
    title: 'Расход жидкости',
    component: (
      <>
        Расход жидкости (Вх.),
        <br /> м<span className="sup">3</span>/сут
      </>
    ),
  },
  out_fluid_flow_rate: {
    title: 'Расход жидкости',
    component: (
      <>
        Расход жидкости (Вых.),
        <br /> м<span className="sup">3</span>/сут
      </>
    ),
  },
  in_gas_flow_rate: {
    title: 'Расход газа',
    component: (
      <>
        Расход газа (Вх.), <br />
        тыс.м<span className="sup">3</span>/сут
      </>
    ),
  },
  out_gas_flow_rate: {
    title: 'Расход газа',
    component: (
      <>
        Расход газа (Вых.), <br />
        тыс.м<span className="sup">3</span>/сут
      </>
    ),
  },
  in_pressure: {
    title: 'Давление',
    component: (
      <>
        Давление (Вх.), <br />
        атм
      </>
    ),
  },
  out_pressure: {
    title: 'Давление',
    component: (
      <>
        Давление (Вых.), <br />
        атм
      </>
    ),
  },
};

export const pumpTableItems = ({
  pump_name,
  in_oil_flow__t_per_day,
  in_oil_flow_rate_mass_abs__t_per_day,
  in_oil_flow_rate_mass_rel,
  out_oil_flow__t_per_day,
  out_oil_flow_rate_mass_abs__t_per_day,
  out_oil_flow_rate_mass_rel,
  in_fluid_flow__m3_per_day,
  in_fluid_flow_rate_volume_abs__m3_per_day,
  in_fluid_flow_rate_volume_rel,
  out_fluid_flow__m3_per_day,
  out_fluid_flow_rate_volume_abs__m3_per_day,
  out_fluid_flow_rate_volume_rel,
  in_gas_flow__m3_per_day,
  in_gas_flow_rate_volume_abs__m3_per_day,
  in_gas_flow_rate_volume_rel,
  out_gas_flow__m3_per_day,
  out_gas_flow_rate_volume_abs__m3_per_day,
  out_gas_flow_rate_volume_rel,
  in_pressure__atm,
  in_pressure_abs__atm,
  in_pressure_rel,
  out_pressure__atm,
  out_pressure_abs__atm,
  out_pressure_rel,
}: any = {}) => [
  {
    dem: 'name',
    value: pump_name,
  },
  {
    dem: 'type',
    value: 'Насос',
  },
  {
    dem: 'in_oil_flow_rate',
    value: in_oil_flow__t_per_day,
    abs: in_oil_flow_rate_mass_abs__t_per_day,
    rel: in_oil_flow_rate_mass_rel,
    unit: 'т/сут',
  },
  {
    dem: 'out_oil_flow_rate',
    value: out_oil_flow__t_per_day,
    abs: out_oil_flow_rate_mass_abs__t_per_day,
    rel: out_oil_flow_rate_mass_rel,
    unit: 'т/сут',
  },
  {
    dem: 'in_fluid_flow_rate',
    value: in_fluid_flow__m3_per_day,
    abs: in_fluid_flow_rate_volume_abs__m3_per_day,
    rel: in_fluid_flow_rate_volume_rel,
    unit: (
      <>
        м<span className="sup">3</span>/сут
      </>
    ),
  },
  {
    dem: 'out_fluid_flow_rate',
    value: out_fluid_flow__m3_per_day,
    abs: out_fluid_flow_rate_volume_abs__m3_per_day,
    rel: out_fluid_flow_rate_volume_rel,
    unit: (
      <>
        м<span className="sup">3</span>/сут
      </>
    ),
  },
  {
    dem: 'in_gas_flow_rate',
    value: in_gas_flow__m3_per_day / 1000,
    abs: in_gas_flow_rate_volume_abs__m3_per_day / 1000,
    rel: in_gas_flow_rate_volume_rel,
    unit: (
      <>
        тыс. м<span className="sup">3</span>/сут
      </>
    ),
  },
  {
    dem: 'out_gas_flow_rate',
    value: out_gas_flow__m3_per_day / 1000,
    abs: out_gas_flow_rate_volume_abs__m3_per_day / 1000,
    rel: out_gas_flow_rate_volume_rel,
    unit: (
      <>
        тыс. м<span className="sup">3</span>/сут
      </>
    ),
  },
  {
    dem: 'in_pressure',
    value: in_pressure__atm,
    abs: in_pressure_abs__atm,
    rel: in_pressure_rel,
    unit: 'атм',
  },
  {
    dem: 'out_pressure',
    value: out_pressure__atm,
    abs: out_pressure_abs__atm,
    rel: out_pressure_rel,
    unit: 'атм',
  },
];

export const separatorTableItems = ({
  separator_name,
  in_oil_flow__t_per_day,
  in_oil_flow_abs__t_per_day,
  in_oil_mass_flow_rel,
  out_oil_flow__t_per_day,
  out_oil_flow_abs__t_per_day,
  out_oil_mass_flow_rel,
  in_fluid_flow__m3_per_day,
  in_fluid_flow_abs__m3_per_day,
  in_fluid_flow_rel,
  out_fluid_flow__m3_per_day,
  out_fluid_flow_abs__m3_per_day,
  out_fluid_flow_rel,
  in_gas_flow__m3_per_day,
  in_gas_flow_abs__m3_per_day,
  in_gas_flow_rate_rel,
  out_gas_flow__m3_per_day,
  out_gas_flow_abs__m3_per_day,
  out_gas_flow_rate_rel,
  in_pressure__atm,
  in_pressure_abs__atm,
  in_pressure_rel,
  out_pressure__atm,
  out_pressure_abs__atm,
  out_pressure_rel,
}: any = {}) => [
  {
    dem: 'name',
    value: separator_name,
  },
  {
    dem: 'type',
    value: 'Насос',
  },
  {
    dem: 'in_oil_flow_rate',
    value: in_oil_flow__t_per_day,
    abs: in_oil_flow_abs__t_per_day,
    rel: in_oil_mass_flow_rel,
    unit: 'т/сут',
  },
  {
    dem: 'out_oil_flow_rate',
    value: out_oil_flow__t_per_day,
    abs: out_oil_flow_abs__t_per_day,
    rel: out_oil_mass_flow_rel,
    unit: 'т/сут',
  },
  {
    dem: 'in_fluid_flow_rate',
    value: in_fluid_flow__m3_per_day,
    abs: in_fluid_flow_abs__m3_per_day,
    rel: in_fluid_flow_rel,
    unit: (
      <>
        м<span className="sup">3</span>/сут
      </>
    ),
  },
  {
    dem: 'out_fluid_flow_rate',
    value: out_fluid_flow__m3_per_day,
    abs: out_fluid_flow_abs__m3_per_day,
    rel: out_fluid_flow_rel,
    unit: (
      <>
        м<span className="sup">3</span>/сут
      </>
    ),
  },
  {
    dem: 'in_gas_flow_rate',
    value: in_gas_flow__m3_per_day / 1000,
    abs: in_gas_flow_abs__m3_per_day / 1000,
    rel: in_gas_flow_rate_rel,
    unit: (
      <>
        тыс. м<span className="sup">3</span>/сут
      </>
    ),
  },
  {
    dem: 'out_gas_flow_rate',
    value: out_gas_flow__m3_per_day / 1000,
    abs: out_gas_flow_abs__m3_per_day / 1000,
    rel: out_gas_flow_rate_rel,
    unit: (
      <>
        тыс. м<span className="sup">3</span>/сут
      </>
    ),
  },
  {
    dem: 'in_pressure',
    value: in_pressure__atm && in_pressure__atm,
    abs: in_pressure_abs__atm,
    rel: in_pressure_rel,
    unit: 'атм',
  },
  {
    dem: 'out_pressure',
    value: out_pressure__atm && out_pressure__atm,
    abs: out_pressure_abs__atm,
    rel: out_pressure_rel,
    unit: 'атм',
  },
];
