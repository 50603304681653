import * as React from 'react';

export const seriesColors = [
  '#019DDC',
  '#FF72BE',
  '#FAB400',
  '#23D692',
  '#FA8700',
  '#F9D100',
  '#F153FF',
  '#644BA5',
  '#FA3C00',
];

export const templateRowsNumber = 5;

export const segmentsTableHead = {
  start_node_name: {
    title: 'Начальный узел',
    component: <>Начальный узел</>,
  },
  end_node_name: {
    title: 'Конечный узел',
    component: <>Конечный узел</>,
  },
  oil_flow_rate: {
    title: 'Расход нефти',
    component: (
      <>
        Расход нефти, <br />
        т/сут
      </>
    ),
  },
  fluid_flow_rate: {
    title: 'Расход жидкости',
    component: (
      <>
        Расход жидкости,
        <br />м<span className="sup">3</span>/сут
      </>
    ),
  },
  gas_flow_rate: {
    title: 'Расход газа',
    component: (
      <>
        Расход газа,
        <br />
        тыс.м<span className="sup">3</span>/сут
      </>
    ),
  },
  p_in: {
    title: 'Давление на входе',
    component: (
      <>
        Давление на входе,
        <br />
        атм
      </>
    ),
  },
  dp_dl: {
    title: 'Перепад давлений',
    component: (
      <>
        Перепад давлений,
        <br />
        атм/км
      </>
    ),
  },
  dt_dl: {
    title: 'Перепад температур',
    component: (
      <>
        Перепад температур, <br />
        ℃/км
      </>
    ),
  },
  fluid_velocity: {
    title: 'Макс. скорость жидкости',
    component: (
      <>
        Макс. скорость жидкости <br />
        м/с
      </>
    ),
  },
  gas_velocity: {
    title: 'Макс. скорость газа',
    component: (
      <>
        Макс. скорость газа <br />
        м/с
      </>
    ),
  },
};

export const dropdownOptions = [
  { label: 'Расход нефти, т/сут', value: 'oil_flow_rate_mass' },
  { label: 'Расход жидкости, м3/сут', value: 'fluid_flow_rate_mass' },
  { label: 'Расход газа, тыс. м3/сут', value: 'gas_flow_rate_volume' },
  { label: 'Перепад давлений, атм/км', value: 'dp_dl' },
];
