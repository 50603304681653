import * as React from 'react';

import UATable from './Table/Table';
import { layersTableHead } from './Table/utils';

const Layers: React.FC = () => {
  return <UATable tableHead={layersTableHead} />;
};

export default Layers;
