import React from 'react';

import classnames from 'classnames';

import { ITableRowProps } from '../../../../types';
import { formatFloat, isNumber, prepareTableValue } from '../../../../utils';

const TableRow: React.FC<ITableRowProps> = ({ el, headKeys }) => {
  const getColor = (item: any) => {
    if (item.value === undefined || item.abs === 0) return '';
    if (item.dem === 'pressure' && item.abs > 0) {
      return 'red';
    }
    if (item.dem === 'oil_flow_rate' && item.abs < 0) {
      return 'red';
    }
    return 'green';
  };
  const renderField = (item: any) => {
    return (
      <div>
        <p className={classnames('compare-field', 'value')}>
          {isNumber(item.value) ? formatFloat(item.value, false) : item.value}
        </p>
        {!['name', 'type'].includes(item.dem) && !!item.value && (
          <div className={classnames('compare-field', 'compare')}>
            <p>
              <span>
                {item.abs !== undefined && prepareTableValue(item.abs)}
              </span>
              <span>{item.abs !== undefined && item.unit}</span>
            </p>
            <p>
              <span>
                {item.rel !== undefined && prepareTableValue(item.rel)}
              </span>
              <span>{item.rel !== undefined && '%'}</span>
            </p>
          </div>
        )}
      </div>
    );
  };
  return (
    <tr className={classnames('table-row')}>
      {el.map((item: any) => (
        <td
          className={classnames(
            'table-data',
            ['name', 'type'].includes(item.dem)
              ? 'left width-112'
              : 'width-150',
          )}
          key={item.dem}
        >
          {renderField(item)}
        </td>
      ))}
    </tr>
  );
};

export default TableRow;
