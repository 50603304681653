import * as React from 'react';
import { useSelector } from 'react-redux';

import classNames from 'classnames';
import { components } from 'generated/apiTypes';
import { selectIntegralCharacteristics } from 'store/taskSlice';

import Skeleton from '../../../../../../../Components/Skeleton';
import { dashboardItems } from './utils';

const AFCalculationInfoDashboard: React.FC = () => {
  const integralCharacteristics = useSelector(
    selectIntegralCharacteristics,
  ) as components['schemas']['GetFactAdaptationSolutionIntegralCharacteristicQueryResult'];

  const getData = () => {
    return {
      calculation_lasting__s: integralCharacteristics?.calculation_lasting__s,
      achieved_calculation_accuracy__atm:
        integralCharacteristics?.achieved_calculation_accuracy__atm,
      specified_calculation_accuracy__atm:
        integralCharacteristics?.specified_calculation_accuracy__atm,
    };
  };

  return (
    <div className="dashboard calc-info">
      {dashboardItems(getData()).map((el, key) => {
        return (
          <div key={key} className={classNames('calculation_info calc-info')}>
            <p>{el.dem}</p>
            {el.value !== undefined ? el.value : '-'}
            &nbsp;{el.value !== undefined && el.unit}
          </div>
        );
      })}
    </div>
  );
};

export default AFCalculationInfoDashboard;
