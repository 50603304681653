import React from 'react';
import { useSelector } from 'react-redux';

import SearchInput from 'Components/SearchInput';

import Button from '../../Components/Button';
import DialogWindow from '../../Components/DialogWindow';
import { components } from '../../generated/apiTypes';
import { selectProjects } from '../../store/projectsSlice';
import ProjectRow from './ProjectRow';
import ProjectsHeader from './ProjectsHeader';
import TemplateProjectRow from './TemplateProjectRow';
import { ProjectsViewPropsType } from './types';
import { sortByDate } from './utils';

import './style.scss';

const templateRowsNumber = 5;

const ProjectsView: React.FC<ProjectsViewPropsType> = ({
  createProject,
  logout,
}) => {
  const projectsList: components['schemas']['PNOProjectData'][] | null =
    useSelector(selectProjects);
  const [searchInputValue, setSearchInputValue] = React.useState<string>('');
  const [filteredList, setFilteredList] = React.useState<
    components['schemas']['PNOProjectData'][] | null
  >(null);

  React.useEffect(() => {
    setFilteredList(projectsList);
  }, [projectsList]);

  const onChangeSearchInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newList = projectsList?.filter(value => {
      return value.project_name
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
    });
    if (newList) setFilteredList(newList);
    setSearchInputValue(e.target.value);
  };

  const clearSearchInputValue = () => {
    setSearchInputValue('');
    setFilteredList(projectsList);
  };

  const onCreateProject = () => {
    createProject();
  };

  const list = filteredList ? [...filteredList] : null;

  return (
    <>
      <ProjectsHeader logout={logout} />
      <div className="projects-container relative">
        <DialogWindow />
        <div className="projects-top-row relative">
          <div className="projects-title">Проекты</div>
          <Button
            className="projects-create-project-btn"
            type="button"
            onClick={onCreateProject}
          >
            Создать&nbsp;проект
          </Button>
        </div>
        <div className="projects-search">
          <SearchInput
            onInput={onChangeSearchInputValue}
            value={searchInputValue}
            placeholder="Поиск..."
            clearInputValue={clearSearchInputValue}
          />
        </div>
        <div className="projects-list-view">
          {list?.length === 0 && (
            <p className="projects__no-projects-message">
              Не создан ни один проект
            </p>
          )}
          {list?.length !== undefined
            ? list.sort(sortByDate())?.map(value => {
                return (
                  <ProjectRow
                    key={value.project_uid}
                    name={value.project_name}
                    date={value.project_timestamp}
                    id={value.project_uid}
                  />
                );
              })
            : Array.from({ length: templateRowsNumber }, (item, index) => (
                <TemplateProjectRow key={index} />
              ))}
        </div>
      </div>
    </>
  );
};

export default ProjectsView;
