import * as React from 'react';
import { useSelector } from 'react-redux';

import {
  selectNodeById,
  selectPhysChemPackages,
  selectSourceById,
} from 'store/projectSlice';

import { ReactComponent as SourcePPDIcon } from '../../../../images/Project/icn-source-ppd.svg';
import { IObject } from '../../types';
import ObjectInput from '../components/ObjectInput';
import ObjectSelect, { OptionType } from '../components/ObjectSelect';

const SourcePPD: React.FC<IObject> = ({ objectUid }) => {
  const sourceById = useSelector(selectSourceById);
  const physChemPackages = useSelector(selectPhysChemPackages);
  const PCPackages: OptionType<string>[] = physChemPackages.map(value => {
    return {
      value: value?.uid as string,
      label: value?.name as string,
    };
  });

  const saveValue = () => {};

  return (
    <div className="active-object__content">
      <div className="active-object__top-row">
        <p className="active-object__title">
          <SourcePPDIcon />
          {sourceById[objectUid as string]?.name}
        </p>
      </div>
      <div className="active-object__row">
        <div className="d-flex">
          <div className="active-object__input-with-unit-wrapper left">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Давление"
              name=""
              initValue={sourceById[objectUid as string]?.pressure__atmg}
              saveNewValue={saveValue}
              disabled
            />
            <p className="active-object__row-input-unit">атм</p>
          </div>
          <div className="active-object__input-with-unit-wrapper">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Температура смеси"
              name=""
              initValue={sourceById[objectUid as string]?.temperature__degC}
              saveNewValue={saveValue}
              disabled
            />
            <p className="active-object__row-input-unit">˚C</p>
          </div>
        </div>
      </div>
      <div className="active-object__row">
        <div className="d-flex">
          <div className="active-object__input-with-unit-wrapper short">
            <ObjectSelect
              label="Пакет ФХС"
              classNames="active-object__row-input"
              classNamesWrapper="active-object__row-input-wrapper wide"
              name="phys_chem_package_id"
              options={PCPackages}
              value={PCPackages.find(
                item =>
                  item.value === sourceById[objectUid as string]?.phys_chem_id,
              )}
              saveNewValue={saveValue}
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SourcePPD;
