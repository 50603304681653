import * as React from 'react';
import { useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { components } from 'generated/apiTypes';
import StepInput from 'Pages/Project/ResultsPanel/components/StepInput';
import { selectSolution } from 'store/taskSlice';

import SourcesAvgTable from './AvgTable';
import SourcesByStepTable from './ByStepTable';
import { wellfieldsTableHead } from './utils';

const Table: React.FC = () => {
  const solution = useSelector(selectSolution)
    ?.solution as components['schemas']['PNOTechModeOptimizationProblem'];
  const [tabIndex, setTabIndex] = React.useState(0);
  const initStep = '0.0';
  const [step, setStep] = React.useState<string>(initStep);

  return (
    <>
      <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
        <TabList className="results__tab-list tables-tab-list">
          <div className="results__table-tabs">
            <Tab className="results__tab">Средние</Tab>
            <Tab className="results__tab">По шагам</Tab>
          </div>
          <StepInput
            value={step}
            setStep={setStep}
            tabIndex={tabIndex}
            initStep={initStep}
            max={
              solution?.solution?.well_step_table?.time2states
                ? Object.keys(solution?.solution?.well_step_table?.time2states)[
                    Object.entries(
                      solution?.solution?.well_step_table?.time2states,
                    )?.length - 1
                  ]?.toString()
                : '0.0'
            }
            time2states={solution?.solution?.well_step_table?.time2states}
          />
        </TabList>
        <div>
          <TabPanel>
            <SourcesAvgTable tableHead={wellfieldsTableHead} />
          </TabPanel>
          <TabPanel>
            <SourcesByStepTable tableHead={wellfieldsTableHead} keyD={step} />
          </TabPanel>
        </div>
      </Tabs>
    </>
  );
};

export default Table;
