import * as React from 'react';
import { Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { NamedProps } from 'react-select';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import Dropdown from 'Components/Dropdown';
import { ReactComponent as ChartIcon } from 'images/Project/icn-line-chart.svg';
import { ReactComponent as TableIcon } from 'images/Project/icn-table.svg';
import { ArrayElement } from 'Pages/Project/types';
import { selectSolution } from 'store/taskSlice';

import SinkChart from './Chart';
import Table from './Table/Table';
import { dropdownOptions, options } from './utils';

import '../../../chartStyle.scss';

type OptionType = ArrayElement<typeof dropdownOptions> | null;

const Sinks: React.FC = () => {
  const [tabIndex, setTabIndex] = React.useState(0);
  return (
    <>
      <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
        <TabList className="results__tab-list">
          <Tab className="results__icon">
            <ChartIcon />
          </Tab>
          <Tab className="results__icon">
            <TableIcon />
          </Tab>
        </TabList>
        <div>
          <TabPanel>
            <SinkChart />
          </TabPanel>
          <TabPanel>
            <Table />
          </TabPanel>
        </div>
      </Tabs>
    </>
  );
};

export default Sinks;
