import * as React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import classNames from 'classnames';
import { components } from 'generated/apiTypes';
import { selectIntegralCharacteristics, selectSolution } from 'store/taskSlice';

import { dashboardItems } from './utils';

const DashboardPPD: React.FC = () => {
  const integralCharacteristic = useSelector(
    selectIntegralCharacteristics,
  ) as components['schemas']['PNOReservoirBaseSolution']['integral_characteristic'];
  return (
    <div className="dashboard">
      {dashboardItems(integralCharacteristic).map((el, key) => {
        return (
          <div key={key} className={classNames('dashboard__item grow')}>
            <p className="dashboard__item-dem">{el.dem}</p>
            <div className="dashboard__bottom">
              <p className="dashboard__item-value">
                {el.value}
                &nbsp;{el.unit}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DashboardPPD;
