import React from 'react';

import AvgTable from './AvgTable';
import { wellsAvgTableHead } from './utils';

const AFSourcesResults: React.FC = () => {
  return (
    <>
      <div className="separators__table-title">Средние значения</div>
      <AvgTable tableHead={wellsAvgTableHead} />
    </>
  );
};

export default AFSourcesResults;
