import * as React from 'react';

export const templateRowsNumber = 5;

export const wellsAvgTableHead = {
  well_name: 'Название',
  fact_pressure__atm: {
    title: 'Фактическое давление',
    component: (
      <>
        Фактическое давление, <br />
        атм
      </>
    ),
  },
  calculated_pressure__atm: {
    title: 'Расчетное давление',
    component: (
      <>
        Расчетное давление, <br />
        атм
      </>
    ),
  },
  absolute_pressure_error__atm: {
    title: 'Абсолютное отклонение давлений',
    component: (
      <>
        Абсолютное отклонение <br />
        давлений, атм
      </>
    ),
  },
  relative_pressure_error: {
    title: 'Относительное отклонение давлений',
    component: (
      <>
        Относительное отклонение <br />
        давлений, %
      </>
    ),
  },
};
