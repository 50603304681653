import { ChartOptions } from 'chart.js';

import { components } from '../../../../../generated/apiTypes';
import { ChartType } from '../types';

export const seriesColors = [
  '#019DDC',
  '#FF72BE',
  '#FAB400',
  '#23D692',
  '#FA8700',
  '#F9D100',
  '#F153FF',
  '#644BA5',
  '#FA3C00',
];

export const getOptions = (
  chartType: ChartType,
  wellOperationType:
    | components['schemas']['PNOWellOperationType']
    | 'ProdWell'
    | 'InjWell'
    | '',
  targetParameter?: 'PRESSURE' | 'FLOW' | 'FREQ/DIAMETER',
): ChartOptions => {
  const getLegendNameByType = () => {
    if (wellOperationType === 'Газлифт') {
      return 'Расход газлифтного газа (Qг/г)';
    }
    if (wellOperationType === 'ЭЦН') {
      return 'Частоты (F)';
    }
    if (wellOperationType === 'Время') {
      return 'Интервалы работы';
    }
    if (chartType === 'Head') return '';
    if (
      wellOperationType === 'ProdWell' &&
      ['PRESSURE', 'FLOW'].includes(targetParameter as string)
    )
      return 'Частота';
    if (
      wellOperationType === 'ProdWell' &&
      ['FREQ/DIAMETER'].includes(targetParameter as string)
    )
      return 'Дебит жидкости';
    if (
      wellOperationType === 'InjWell' &&
      ['PRESSURE', 'FLOW'].includes(targetParameter as string)
    )
      return 'Диаметр штуцера';
    if (
      wellOperationType === 'InjWell' &&
      ['FREQ/DIAMETER'].includes(targetParameter as string)
    )
      return 'Приемистость';
    return '';
  };

  const getYAxisTitleByType = () => {
    switch (chartType) {
      case 'FlowRates':
        return 'Qж, м3/сут';
      case 'Power':
        return 'Мощность, Вт';
      case 'BottomHolePressures':
        return ['ProdWell', 'InjWell'].includes(wellOperationType)
          ? 'Забойное давление, атм'
          : 'P заб, атм';
      case 'Amperage':
        return 'Сила тока, А';
      case 'Loading':
        return 'Загрузка, %';
      case 'Head':
        return 'Напор, м';
      default:
        return '';
    }
  };

  const getXAxisTitleByType = () => {
    switch (targetParameter) {
      case 'PRESSURE':
        return wellOperationType === 'InjWell'
          ? 'Приемистость, м3/сут'
          : 'Дебит жидкости, м3/сут';
      case 'FLOW':
      case 'FREQ/DIAMETER':
        return 'Линейное давление, атм';
      default: {
        if (chartType === 'Head') return 'Подача, м3/сут';
        return 'Р лин, атм';
      }
    }
  };

  return {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        align: 'start',
        labels: {
          pointStyle: 'line',
          usePointStyle: true,
          filter: item => item.text !== '',
        },
        title: {
          display: true,
          text: getLegendNameByType(),
          position: 'start',
          color: '#aaa',
          font: {
            family: 'Inter, sans-serif',
            size: 10,
          },
          padding: { top: 10, bottom: 6 },
        },
      },
    },
    scales: {
      y: {
        grid: {
          display: true,
          borderWidth: 0,
          drawBorder: false,
        },
        title: {
          display: true,
          text: getYAxisTitleByType(),
          color: '#aaa',
          font: {
            family: 'Inter, sans-serif',
            size: 10,
          },
          padding: { top: 0, bottom: 4 },
        },
        ticks: {
          padding: 0,
          color: '#999999',
          font: {
            size: 10,
            family: 'Inter, sans-serif',
          },
        },
      },
      x: {
        grid: {
          display: true,
          borderDash: [6, 3],
          borderWidth: 0,
          drawBorder: false,
        },
        title: {
          display: true,
          text: getXAxisTitleByType(),
          color: '#aaa',
          font: {
            family: 'Inter, sans-serif',
            size: 10,
          },
          padding: { top: 4, bottom: 0 },
        },
        ticks: {
          padding: 0,
          color: '#999999',
          font: {
            size: 10,
            family: 'Inter, sans-serif',
          },
        },
      },
    },
  };
};
