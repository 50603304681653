import * as React from 'react';
import { useSelector } from 'react-redux';

import classnames from 'classnames';
import { components } from 'generated/apiTypes';
import { selectSolution } from 'store/taskSlice';

import TableWrapper from '../../../../components/TableWrapper';
import { IThead } from '../../../../types';
import TableHead from './TableHead';
import TableRow from './TableRow';
import TemplateTableRow from './TemplateTableRow';
import { sinksTableItems, templateRowsNumber } from './utils';

export interface ISinksTableProps {
  tableHead: IThead;
  keyD: string;
  sinksUID: any;
}
const SinksByStepTable: React.FC<ISinksTableProps> = ({
  tableHead,
  keyD,
  sinksUID,
}) => {
  const solution = useSelector(selectSolution)
    ?.solution as components['schemas']['PNOTechModeOptimizationProblem'];
  const [data, setData] = React.useState<any[]>([]);
  const headKeys = Object.keys(tableHead);

  const getData = () => {
    const newData: any[] = [];
    solution?.solution?.node_step_table?.time2states[keyD]
      ?.filter(state => sinksUID?.includes(state.node_uid))
      .forEach(state => {
        newData.push(state);
      });
    setData(newData);
  };

  React.useEffect(() => {
    getData();
  }, [solution, keyD, sinksUID]);

  return (
    <TableWrapper vertical horizontal smoothScrolling>
      <table className={classnames('table', 'table__nodes-table')}>
        <TableHead headKeys={headKeys} tableHead={tableHead} />
        <tbody>
          {data?.length > 0
            ? data.map((value: any, index: number) => {
                return (
                  <TableRow
                    key={index}
                    headKeys={headKeys}
                    el={sinksTableItems(value)}
                  />
                );
              })
            : Array.from({ length: templateRowsNumber }, (item, index) => (
                <TemplateTableRow key={index} headKeys={headKeys} />
              ))}
        </tbody>
      </table>
    </TableWrapper>
  );
};

export default SinksByStepTable;
