import * as React from 'react';

export const templateRowsNumber = 5;

export const segmentsAvgTableHead = {
  start_node_name: 'Начальный узел',
  end_node_name: 'Конечный узел',
  friction_factor_correction: 'Поправка friction factor',
  holdup_factor_correction: 'Поправка holdup factor',
  pressure_correction: 'Поправка к давлению, атм',
};
