import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { components } from 'generated/apiTypes';
import { selectClusters } from 'store/projectSlice';
import {
  selectExperimentSettings,
  setExperimentSettings as setStoreExperimentSettings,
} from 'store/taskSlice';

import {
  getClusterEqualityOptimizationConstraints,
  getExperimentSettings,
  setExperimentSettings,
} from 'services/apiRequests';
import ObjectInput from '../../ActiveObject/components/ObjectInput';
import ObjectSelect from '../../ActiveObject/components/ObjectSelect';
import { EqualityConstraint } from '../../types';
import { getEqualityConstraintsOptions } from '../options';

interface IClusterEqualityConstraintProps {
  setIncompleteConstraints(val: boolean): void;
  setExperimentSettingsSaved(val: boolean): void;
}

const ClusterEqualityConstraint: React.FC<IClusterEqualityConstraintProps> = ({
  setIncompleteConstraints,
  setExperimentSettingsSaved,
}) => {
  const dispatch = useDispatch();
  const params = useParams() as { id: string };
  const settings = useSelector(selectExperimentSettings);
  const clusters = useSelector(selectClusters);
  const [constraintValue, setConstraintValue] = React.useState<
    EqualityConstraint | undefined
  >();
  const [cluster, setCluster] =
    React.useState<components['schemas']['GetClusterQueryResult']>();
  const [constraintNumber, setConstraintNumber] = React.useState<
    number | undefined
  >();
  const [constraintsOptionsCurrent, setConstraintsOptionsCurrent] =
    React.useState<
      {
        value: EqualityConstraint;
        label: string;
        disabled: boolean;
      }[]
    >();

  const constraintType =
    'ClusterEqualityNetworkOptimizationExperimentSettingsConstraints';

  React.useEffect(() => {
    setIncompleteConstraints(true);
  }, []);

  React.useEffect(() => {
    let allClustersConstraintsSet = true;
    clusters.forEach(clusterItem => {
      if (
        !(
          settings?.optimization_experiment_settings
            ?.constraints as components['schemas']['ClusterEqualityNetworkOptimizationExperimentSettingsConstraints']
        )?.constraints?.find(item => item.cluster_id === clusterItem.id)
      ) {
        allClustersConstraintsSet = false;
      }
    });
    if (allClustersConstraintsSet) {
      setIncompleteConstraints(false);
    } else {
      setIncompleteConstraints(true);
    }
  }, [
    clusters,
    (
      settings?.optimization_experiment_settings
        ?.constraints as components['schemas']['ClusterEqualityNetworkOptimizationExperimentSettingsConstraints']
    )?.constraints,
  ]);

  React.useEffect(() => {
    const type = (
      settings?.optimization_experiment_settings
        ?.constraints as components['schemas']['ClusterEqualityNetworkOptimizationExperimentSettingsConstraints']
    )?.constraints?.find(item => item.cluster_id === cluster?.id)?.constraint
      ?.type;
    if (type && type !== constraintValue) {
      setConstraintValue(type);
    }
  }, [
    (
      settings?.optimization_experiment_settings
        ?.constraints as components['schemas']['ClusterEqualityNetworkOptimizationExperimentSettingsConstraints']
    )?.constraints?.find(item => item.cluster_id === cluster?.id)?.constraint
      ?.type,
  ]);

  React.useEffect(() => {
    if (clusters?.filter(clusterItem => !clusterItem.is_empty)?.length > 0) {
      setCluster(clusters[0]);
    }
  }, [clusters]);

  React.useEffect(() => {
    let optionToDisable:
      | {
          value: EqualityConstraint;
          label: string;
          disabled: boolean;
        }
      | undefined;
    if (constraintType) {
      switch (settings?.optimization_experiment_settings?.target_metric) {
        case 'Maximum oil debit':
          optionToDisable = getEqualityConstraintsOptions().find(
            item =>
              item.value ===
              'OilDebitEqualityNetworkOptimizationExperimentSettingsConstraint',
          );
          break;
        case 'Minimum energy consumption':
          optionToDisable = getEqualityConstraintsOptions().find(
            item =>
              item.value ===
              'EnergyConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint',
          );
          break;
        case 'Minimum active gas consumption':
          optionToDisable = getEqualityConstraintsOptions().find(
            item =>
              item.value ===
              'ActiveGasConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint',
          );
          break;
        case 'MIN_PRODUCED_FLUID_VOLUME':
          optionToDisable = getEqualityConstraintsOptions().find(
            item =>
              item.value ===
              'ProducedFluidVolumeEqualityNetworkOptimizationExperimentSettingsConstraint',
          );
          break;
        case 'MIN_PRODUCED_ASSOCIATED_PETROLEUM_GAS_VOLUME':
        case 'MAX_PRODUCED_ASSOCIATED_PETROLEUM_GAS_VOLUME':
          optionToDisable = getEqualityConstraintsOptions().find(
            item =>
              item.value ===
              'ProducedPetroleumGasVolumeEqualityNetworkOptimizationExperimentSettingsConstraint',
          );
          break;
        case 'MIN_PRODUCED_WATER_VOLUME':
          optionToDisable = getEqualityConstraintsOptions().find(
            item =>
              item.value ===
              'ProducedWaterVolumeEqualityNetworkOptimizationExperimentSettingsConstraint',
          );
          break;
        case 'MIN_SPECIFIC_POWER_CONSUMPTION':
          optionToDisable = getEqualityConstraintsOptions().find(
            item =>
              item.value ===
              'SpecificPowerConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint',
          );
          break;
        case 'MIN_SPECIFIC_GASLIFT_CONSUMPTION':
          optionToDisable = getEqualityConstraintsOptions().find(
            item =>
              item.value ===
              'SpecificGasliftConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint',
          );
          break;
        case 'MIN_TOTAL_GAS':
        case 'MAX_TOTAL_GAS':
          optionToDisable = getEqualityConstraintsOptions().find(
            item =>
              item.value ===
              'TotalGasConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint',
          );
          break;
        default:
          break;
      }
      setConstraintsOptionsCurrent(
        getEqualityConstraintsOptions().map(item => {
          if (item.value === optionToDisable?.value) {
            return { ...optionToDisable, disabled: true };
          }
          if (item.value === constraintValue) {
            const option = getEqualityConstraintsOptions().find(
              option1 => option1.value === constraintValue,
            );
            if (option) return { ...option, disabled: true };
          }
          return item;
        }),
      );
    }
  }, [
    settings?.optimization_experiment_settings?.target_metric,
    constraintValue,
  ]);

  React.useEffect(() => {
    const fetchEqualityConstraints = async () => {
      let response;
      if (constraintValue) {
        response = await getClusterEqualityOptimizationConstraints(
          params.id,
          constraintValue,
        );
      }
      return response;
    };
    const fetchSettings = async () => {
      const experimentSettings = await getExperimentSettings(params.id);
      return Promise.resolve(experimentSettings);
    };
    const saveInitValue = async (initValues: Record<any, number>) => {
      for (const cl of clusters) {
        let experimentSettings;
        await fetchSettings().then(
          response => (experimentSettings = response.data),
        );
        if (experimentSettings) {
          await tryToSaveConstraintWithSettings(
            constraintValue,
            initValues[cl.id],
            cl.id,
            experimentSettings,
          );
        }
      }
    };
    if (
      (
        settings?.optimization_experiment_settings
          ?.constraints as components['schemas']['ClusterEqualityNetworkOptimizationExperimentSettingsConstraints']
      )?.constraints?.find(item => item.cluster_id === cluster?.id)?.constraint
        ?.type !== constraintValue &&
      constraintValue !== undefined
    ) {
      setIncompleteConstraints(true);
      let initValues: Record<any, number> = {};
      fetchEqualityConstraints().then(response => {
        const constraint = response?.data.constraints?.find(
          item => item.cluster_id === cluster?.id,
        )?.constraint;
        if (constraint?.type) {
          if (
            [
              'SpecificPowerConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint',
              'SpecificGasliftConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint',
            ].includes(constraint?.type)
          ) {
            response?.data.constraints?.forEach(
              item =>
                (initValues = {
                  ...initValues,
                  ...{ [item.cluster_id]: item.constraint.value as number },
                }),
            );
          } else {
            response?.data.constraints?.forEach(
              item =>
                (initValues = {
                  ...initValues,
                  ...{
                    [item.cluster_id]: item.constraint?.type
                      ? (item.constraint?.value as any)?.[
                          getUnitByConstraint(item.constraint?.type)
                        ]
                      : 0,
                  },
                }),
            );
          }
        }
        saveInitValue(initValues).then();
      });
      setIncompleteConstraints(false);
    }
  }, [constraintValue]);

  React.useEffect(() => {
    const value = (
      settings?.optimization_experiment_settings
        ?.constraints as components['schemas']['ClusterEqualityNetworkOptimizationExperimentSettingsConstraints']
    )?.constraints?.find(item => item.cluster_id === cluster?.id)?.constraint
      ?.value as any;

    const type = (
      settings?.optimization_experiment_settings
        ?.constraints as components['schemas']['ClusterEqualityNetworkOptimizationExperimentSettingsConstraints']
    )?.constraints?.find(item => item.cluster_id === cluster?.id)?.constraint
      ?.type;

    if (type && value !== undefined && type === constraintValue) {
      const valueToCompare = [
        'SpecificPowerConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint',
        'SpecificGasliftConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint',
      ].includes(type)
        ? value
        : value?.[getUnitByConstraint(type)];
      if (valueToCompare !== constraintNumber) {
        setConstraintNumber(valueToCompare);
        // setIncompleteConstraints(true);
      }
    } else if (constraintNumber !== undefined) {
      setConstraintNumber(undefined);
    }
  }, [
    (
      settings?.optimization_experiment_settings
        ?.constraints as components['schemas']['ClusterEqualityNetworkOptimizationExperimentSettingsConstraints']
    )?.constraints?.find(item => item.cluster_id === cluster?.id)?.constraint
      ?.value,
    constraintValue,
  ]);

  React.useEffect(() => {
    if (constraintValue) {
      let valueToCompare;
      if (
        (
          settings?.optimization_experiment_settings
            ?.constraints as components['schemas']['ClusterEqualityNetworkOptimizationExperimentSettingsConstraints']
        )?.constraints
      ) {
        valueToCompare = [
          'SpecificPowerConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint',
          'SpecificGasliftConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint',
        ].includes(constraintValue)
          ? ((
              settings?.optimization_experiment_settings
                ?.constraints as components['schemas']['ClusterEqualityNetworkOptimizationExperimentSettingsConstraints']
            )?.constraints?.find(item => item.cluster_id === cluster?.id)
              ?.constraint?.value as any)
          : (
              (
                settings?.optimization_experiment_settings
                  ?.constraints as components['schemas']['ClusterEqualityNetworkOptimizationExperimentSettingsConstraints']
              )?.constraints?.find(item => item.cluster_id === cluster?.id)
                ?.constraint?.value as any
            )?.[getUnitByConstraint(constraintValue)];
      }
      /* const value = !(
        settings?.optimization_experiment_settings
          ?.constraints as components['schemas']['ClusterEqualityNetworkOptimizationExperimentSettingsConstraints']
      )?.constraints?.find(item => item.cluster_id === cluster?.id)
        ? undefined
        : valueToCompare; */
      if (valueToCompare !== constraintNumber) {
        tryToSaveConstraint(
          constraintValue,
          constraintNumber,
          cluster?.id,
        ).then();
      }
    }
  }, [constraintNumber]);

  const onChangeConstraintValue = (value: EqualityConstraint) => {
    setConstraintValue(value);
  };

  const onChangeClusterValue = (value: any) => {
    setCluster(clusters.find(item => item.id === value));
  };

  const onChangeConstraintNumber = () => {
    setIncompleteConstraints(true);
  };

  const saveConstraintNumber = (value: number) => {
    setConstraintNumber(value);
  };

  const getUnitByConstraint = (constraint: EqualityConstraint) => {
    switch (constraint) {
      case 'OilDebitEqualityNetworkOptimizationExperimentSettingsConstraint':
        return 't_per_day';
      case 'EnergyConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint':
        return 'kW_h_per_day';
      case 'ActiveGasConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint':
      case 'ProducedPetroleumGasVolumeEqualityNetworkOptimizationExperimentSettingsConstraint':
      case 'TotalGasConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint':
        return 'thousand_m3_per_day';
      case 'ProducedFluidVolumeEqualityNetworkOptimizationExperimentSettingsConstraint':
      case 'ProducedWaterVolumeEqualityNetworkOptimizationExperimentSettingsConstraint':
        return 'm3_per_day';
      case 'SpecificPowerConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint':
        return '';
      case 'SpecificGasliftConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint':
        return '';
      default:
        return '';
    }
  };

  const renderUnitByConstraint = (
    constraint: EqualityConstraint | undefined,
  ) => {
    switch (constraint) {
      case 'OilDebitEqualityNetworkOptimizationExperimentSettingsConstraint':
        return (
          <p className="active-object__row-input-unit">
            <span className="relative">т/сут</span>
          </p>
        );
      case 'EnergyConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint':
        return (
          <p className="active-object__row-input-unit">
            <span className="relative">кВт·ч/сут</span>
          </p>
        );
      case 'ActiveGasConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint':
      case 'ProducedPetroleumGasVolumeEqualityNetworkOptimizationExperimentSettingsConstraint':
      case 'TotalGasConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint':
        return (
          <p className="active-object__row-input-unit">
            <span className="relative">
              тыс.м<span className="index unit-small">3</span>
            </span>
            &nbsp; /сут
          </p>
        );
      case 'ProducedFluidVolumeEqualityNetworkOptimizationExperimentSettingsConstraint':
      case 'ProducedWaterVolumeEqualityNetworkOptimizationExperimentSettingsConstraint':
        return (
          <p className="active-object__row-input-unit">
            <span className="relative">
              м<span className="index unit-small">3</span>
            </span>
            &nbsp; /сут
          </p>
        );
      case 'SpecificPowerConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint':
        return (
          <p className="active-object__row-input-unit">
            <span className="relative">кВт·ч/т</span>
          </p>
        );
      case 'SpecificGasliftConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint':
        return (
          <p className="active-object__row-input-unit">
            <span className="relative">
              тыс.м<span className="index unit-small">3</span>
            </span>
            &nbsp; /т
          </p>
        );
      default:
        return <></>;
    }
  };

  const getNumberValueByConstraint = (
    constraint: EqualityConstraint,
    number: number,
  ) => {
    switch (constraint) {
      case 'OilDebitEqualityNetworkOptimizationExperimentSettingsConstraint':
        return { t_per_day: number };
      case 'EnergyConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint':
        return { kW_h_per_day: number };
      case 'ActiveGasConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint':
      case 'ProducedPetroleumGasVolumeEqualityNetworkOptimizationExperimentSettingsConstraint':
      case 'TotalGasConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint':
        return { thousand_m3_per_day: number };
      case 'ProducedFluidVolumeEqualityNetworkOptimizationExperimentSettingsConstraint':
      case 'ProducedWaterVolumeEqualityNetworkOptimizationExperimentSettingsConstraint':
        return { m3_per_day: number };
      case 'SpecificPowerConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint':
        return number;
      case 'SpecificGasliftConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint':
        return number;
      default:
        return {};
    }
  };

  const tryToSaveConstraint = async (
    constrValue: EqualityConstraint | undefined,
    constrNumber: number | undefined,
    clusterId: string | undefined,
  ) => {
    if (
      settings?.optimization_experiment_settings &&
      constrValue &&
      constrNumber !== undefined
    ) {
      const oldValue = { ...settings };
      const newValue = {
        ...settings,
        optimization_experiment_settings: {
          ...settings.optimization_experiment_settings,
          constraints: {
            type: 'ClusterEqualityNetworkOptimizationExperimentSettingsConstraints' as any,
            constraints: [
              ...((
                settings?.optimization_experiment_settings
                  .constraints as components['schemas']['ClusterEqualityNetworkOptimizationExperimentSettingsConstraints']
              )?.constraints?.filter(
                item =>
                  item.cluster_id !== clusterId &&
                  item.constraint?.type === constraintValue,
              ) || []),
              {
                cluster_id: clusterId,
                constraint: {
                  type: constrValue,
                  value: getNumberValueByConstraint(constrValue, constrNumber),
                },
              },
            ],
          },
        },
      };
      setExperimentSettingsSaved(false);
      try {
        const response = await setExperimentSettings(
          params.id,
          newValue as any,
        );
        dispatch(setStoreExperimentSettings(response.data));
        setExperimentSettingsSaved(true);
      } catch (e) {
        dispatch(setStoreExperimentSettings(newValue as any));
        dispatch(setStoreExperimentSettings(oldValue));
      }
    }
  };

  const tryToSaveConstraintWithSettings = async (
    constrValue: EqualityConstraint | undefined,
    constrNumber: number | undefined,
    clusterId: string | undefined,
    experimentSettings: components['schemas']['ExperimentSettings'],
  ) => {
    if (
      experimentSettings?.optimization_experiment_settings &&
      constrValue &&
      constrNumber !== undefined
    ) {
      const oldValue = { ...settings };
      const newValue = {
        ...experimentSettings,
        optimization_experiment_settings: {
          ...experimentSettings.optimization_experiment_settings,
          constraints: {
            type: 'ClusterEqualityNetworkOptimizationExperimentSettingsConstraints' as any,
            constraints: [
              ...((
                experimentSettings?.optimization_experiment_settings
                  .constraints as components['schemas']['ClusterEqualityNetworkOptimizationExperimentSettingsConstraints']
              )?.constraints?.filter(
                item =>
                  item.cluster_id !== clusterId &&
                  item.constraint?.type === constraintValue,
              ) || []),
              {
                cluster_id: clusterId,
                constraint: {
                  type: constrValue,
                  value: getNumberValueByConstraint(constrValue, constrNumber),
                },
              },
            ],
          },
        },
      };
      setExperimentSettingsSaved(false);
      try {
        const response = await setExperimentSettings(
          params.id,
          newValue as any,
        );
        dispatch(setStoreExperimentSettings(response.data));
        setExperimentSettingsSaved(true);
      } catch (e) {
        dispatch(setStoreExperimentSettings(newValue as any));
        dispatch(setStoreExperimentSettings(oldValue));
      }
    }
  };

  return (
    <>
      <div className="project-task-settings__separator" />
      <div className="active-object__row">
        <ObjectSelect
          label="Ограничение"
          classNamesWrapper="full-width"
          classNames="active-object__row-input"
          name="11"
          options={constraintsOptionsCurrent || []}
          value={constraintsOptionsCurrent?.find(
            item => item.value === constraintValue,
          )}
          isOptionDisabled={(option: any) => (option as any)?.disabled}
          saveNewValue={onChangeConstraintValue}
        />
      </div>
      <div className="project-task-settings__separator" />
      <div className="active-object__row">
        <ObjectSelect
          label="Кластер"
          classNamesWrapper="full-width"
          classNames="active-object__row-input"
          name="11"
          options={
            clusters
              ?.filter(clusterItem => !clusterItem.is_empty)
              ?.map(item => {
                return { label: item.name, value: item.id };
              }) || []
          }
          value={
            cluster ? { label: cluster.name, value: cluster.id } : undefined
          }
          saveNewValue={onChangeClusterValue}
        />
      </div>
      <div className="active-object__row">
        <div className="project-task-settings__row long active-object__input-with-unit-wrapper">
          <ObjectInput
            className="input active-object__row-input with-long-unit"
            label={
              constraintsOptionsCurrent?.find(
                item => item.value === constraintValue,
              )?.label || `‎`
            }
            name="gas_constraint"
            initValue={constraintNumber}
            minValue={0}
            saveNewValue={saveConstraintNumber}
            onChangeValue={onChangeConstraintNumber}
          />
          {renderUnitByConstraint(constraintValue)}
        </div>
      </div>
    </>
  );
};

export default ClusterEqualityConstraint;
