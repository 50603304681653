import React from 'react';

import classnames from 'classnames';

import { threeDigitsFormatter, twoDigitsFormatter } from 'utils';
import { ITableRowProps } from '../../../../types';
import { isNumber } from '../../../../utils';

const TableRow: React.FC<ITableRowProps> = ({ el, headKeys }) => {
  const formatValue = (key: string, value: string) => {
    if (
      ['temperature_avg', 'pressure_avg', 'temperature', 'pressure'].includes(
        key,
      )
    )
      return twoDigitsFormatter.format(
        parseFloat(parseFloat(value).toFixed(2)),
      );
    return threeDigitsFormatter.format(
      parseFloat(parseFloat(value).toFixed(3)),
    );
  };
  return (
    <tr className={classnames('table-row')}>
      {headKeys.map((key: string) => (
        <td
          className={classnames(
            'table-data',
            ['name'].includes(key) ? 'left width-112' : 'width-150',
          )}
          key={key}
        >
          {isNumber(el[key]) ? formatValue(key, el[key]) : el[key]}
        </td>
      ))}
    </tr>
  );
};

export default TableRow;
