import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { ReactComponent as EditIcon } from 'images/Project/icn-pencil.svg';
import { selectActiveObject } from 'store/mapSlice';
import {
  addUpdatedModel,
  removeUpdatedModel,
  selectWellModelById,
  updateWellModal,
} from 'store/projectSlice';

import Button from '../../../../../Components/Button';
import { components } from '../../../../../generated/apiTypes';
import {
  getTasks,
  getWellModel,
  updateWellModel as saveNewWellModel,
} from '../../../../../services/apiRequests';
import { selectTaskId, setIsActual } from '../../../../../store/taskSlice';
import { oneDigitsFormatter, smartFormat } from '../../../../../utils';
import ParameterInput from './ParameterInput';
import { IEditModel } from './types';
import {
  getInitTableByType,
  getTableHeaderByType,
  getTableValueByType,
} from './utils';

const EditModel: React.FC<IEditModel> = ({
  tableType,
  wellOperationType,
  setViewMode,
}) => {
  const dispatch = useDispatch();
  const wellsModelsById = useSelector(selectWellModelById);
  const activeObject = useSelector(selectActiveObject);
  const params = useParams() as { id: string };
  const taskId = useSelector(selectTaskId);
  const [tableData, setTableData] =
    React.useState<components['schemas']['GetWellModelQueryResult']>();
  const [wellModel, setWellModel] =
    React.useState<components['schemas']['GetWellModelQueryResult']>();

  React.useEffect(() => {
    setWellModel(wellsModelsById[activeObject.object_uid as string]);
  }, [
    activeObject.object_uid,
    wellsModelsById[activeObject.object_uid as string],
  ]);

  React.useEffect(() => {
    if (wellModel) {
      const initData = getInitTableByType(tableType, wellModel);
      if (initData.model) {
        dispatch(updateWellModal(initData.model));
        setTableData(initData.model);
      }
      if (initData.modified)
        dispatch(addUpdatedModel(activeObject.object_uid as string));
    }
  }, [wellModel]);

  const getServerWellModel = async () => {
    try {
      const response = await getWellModel(activeObject.object_uid as string);
      if (response.data) {
        dispatch(removeUpdatedModel(activeObject.object_uid as string));
        dispatch(updateWellModal(response.data));
      }
    } catch (e) {
      //
    }
  };

  const saveWellModel = async () => {
    if (wellModel) {
      const modelToSend =
        wellModel as components['schemas']['GetWellModelQueryResult'];
      try {
        const response = await saveNewWellModel(
          activeObject.object_uid as string,
          modelToSend,
        );
        if (response.data) {
          dispatch(removeUpdatedModel(activeObject.object_uid as string));
        }
        const res = await getTasks(params.id);
        if (res?.data?.length > 0) {
          const task = res?.data?.find(item => item.task_uid === taskId);
          if (task && !task.is_actual) {
            dispatch(setIsActual(task.is_actual!));
          }
        }
      } catch (e) {
        //
      }
    }
  };

  const onCancelClick = () => {
    getServerWellModel();
    setViewMode();
  };

  const onSaveClick = () => {
    saveWellModel();
    setViewMode();
  };

  const getControlActions = () => {
    if (wellOperationType === 'ЭЦН') {
      return (
        tableData?.model as components['schemas']['GetPeriodicPumpWellModelQueryResult']
      )?.frequencies__Hz;
    }
    if (wellOperationType === 'Время') {
      return (
        tableData?.model as components['schemas']['GetPermanentTimedWellModelQueryResult']
      )?.work_intervals__min;
    }
    if (wellOperationType === 'Газлифт') {
      return (
        tableData?.model as components['schemas']['GetPermanentGasliftWellModelQueryResult']
      )?.gaslift_flows__thos_m3_per_day;
    }
    return undefined;
  };

  const controlActions = getControlActions();

  return (
    <>
      <div className="well-model-panel__tables-wrapper">
        <table className="well-model-panel__non-scrollable-table">
          <thead>
            <tr>
              <th
                rowSpan={2}
                colSpan={2}
                className="well-model-panel__table-cell well-model-panel__table-header-cell"
              >
                {' '}
              </th>
              <th
                colSpan={tableData?.model.pressures__atmg.length}
                className="well-model-panel__table-cell well-model-panel__table-header-cell caption-cell"
              >
                P лин, атм
              </th>
            </tr>
            <tr>
              {tableData?.model.pressures__atmg?.map(() => (
                <th className="well-model-panel__table-cell well-model-panel__table-header-cell value-cell right-aligned">
                  {' '}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {controlActions?.map((item, control_action_index) => (
              <tr>
                {control_action_index === 0 && (
                  <td
                    rowSpan={controlActions?.length}
                    className="well-model-panel__table-cell well-model-panel__table-header-cell vertical-text"
                  >
                    {getTableHeaderByType(wellOperationType)}
                  </td>
                )}
                <td className="well-model-panel__table-cell well-model-panel__table-header-cell value-cell control-action-cell right-aligned">
                  {wellOperationType === 'Газлифт'
                    ? item && smartFormat(item)
                    : item && oneDigitsFormatter.format(item)}
                </td>
                {tableData?.model.pressures__atmg.map(() => (
                  <td> </td>
                ))}
              </tr>
            ))}
            <tr>
              <td className="well-model-panel__table-bottom-header-cell"> </td>
              <td className="well-model-panel__table-bottom-header-cell"> </td>
              {tableData?.model.pressures__atmg.map(() => (
                <td className="well-model-panel__table-bottom-cell"> </td>
              ))}
            </tr>
          </tbody>
        </table>
        <div className="well-model-panel__scrollable-table-wrapper">
          <table className="well-model-panel__scrollable-table">
            <thead>
              <tr>
                {tableData?.model.pressures__atmg?.map(item => (
                  <th className="well-model-panel__table-cell well-model-panel__table-header-cell value-cell pressure-cell right-aligned">
                    {item && oneDigitsFormatter.format(item)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {controlActions?.map((item, control_action_index) => (
                <tr>
                  {tableData?.model.pressures__atmg.map(
                    (val, pressure_index) => (
                      <td className="well-model-panel__table-cell value-cell pressure-cell editable-cell">
                        <ParameterInput
                          tableType={tableType}
                          initValue={
                            getTableValueByType(
                              tableType,
                              tableData,
                              pressure_index,
                              control_action_index,
                            ) as string
                          }
                          pressure_index={pressure_index}
                          control_action_index={control_action_index}
                        />
                        <EditIcon className="editable-cell-icon" />
                      </td>
                    ),
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="well-model-panel__buttons">
        <Button
          type="button"
          className="well-model-panel__save-btn"
          onClick={onSaveClick}
        >
          Сохранить
        </Button>
        <Button type="button" mode="secondary" onClick={onCancelClick}>
          Отменить
        </Button>
      </div>
    </>
  );
};

export default EditModel;
