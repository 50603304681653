import * as React from 'react';

export const templateRowsNumber = 5;

export const separatorsAvgTableHead = {
  separator_name: 'Название',
  start_pressure__atm: {
    title: 'Давление (вход.)',
    component: (
      <>
        Давление (вход.), <br />
        атм
      </>
    ),
  },
  end_pressure__atm: {
    title: 'Давление (вых.)',
    component: (
      <>
        Давление (вых.), <br />
        атм
      </>
    ),
  },
  start_fluid_volume_flow__m3_per_day: {
    title: 'Расход жидкости (вход.)',
    component: (
      <>
        Расход жидкости <br />
        (вход.), м3/сут
      </>
    ),
  },
  end_fluid_volume_flow__m3_per_day: {
    title: 'Расход жидкости (вых.)',
    component: (
      <>
        Расход жидкости (вых.), <br />
        м3/сут
      </>
    ),
  },
  start_oil_mass_flow__t_per_day: {
    title: 'Расход нефти (вход.)',
    component: (
      <>
        Расход нефти (вход.), <br />
        т/сут
      </>
    ),
  },
  end_oil_mass_flow__t_per_day: {
    title: 'Расход нефти (вых.)',
    component: (
      <>
        Расход нефти (вых.), <br />
        т/сут
      </>
    ),
  },
  start_gas_volume_flow__thos_m3_per_day: {
    title: 'Расход газа (вход.)',
    component: (
      <>
        Расход газа (вход.), <br />
        тыс. м3/сут
      </>
    ),
  },
  end_gas_volume_flow__thos_m3_per_day: {
    title: 'Расход газа (вых.)',
    component: (
      <>
        Расход газа (вых.), <br />
        тыс. м3/сут
      </>
    ),
  },
};

export const separatorsTableItems = ({
  separator_name,
  start_pressure__atm,
  start_pressure_abs_error__atm,
  start_pressure_rel_error,
  end_pressure__atm,
  end_pressure_abs_error__atm,
  end_pressure_rel_error,
  start_fluid_volume_flow__m3_per_day,
  start_fluid_volume_flow_abs_error__m3_per_day,
  start_fluid_volume_flow_rel_error,
  end_fluid_volume_flow__m3_per_day,
  end_fluid_volume_flow_abs_error__m3_per_day,
  end_fluid_volume_flow_rel_error,
  start_oil_mass_flow__t_per_day,
  start_oil_mass_flow_abs_error__t_per_day,
  start_oil_mass_flow_rel_error,
  end_oil_mass_flow__t_per_day,
  end_oil_mass_flow_abs_error__t_per_day,
  end_oil_mass_flow_rel_error,
  start_gas_volume_flow__thos_m3_per_day,
  start_gas_volume_flow_abs_error__thos_m3_per_day,
  start_gas_volume_flow_rel_error,
  end_gas_volume_flow__thos_m3_per_day,
  end_gas_volume_flow_abs_error__thos_m3_per_day,
  end_gas_volume_flow_rel_error,
}: any = {}) => [
  {
    dem: 'separator_name',
    value: separator_name,
  },
  {
    dem: 'start_pressure__atm',
    value: start_pressure__atm,
    abs: start_pressure_abs_error__atm,
    rel: start_pressure_rel_error,
    unit: 'атм',
  },
  {
    dem: 'end_pressure__atm',
    value: end_pressure__atm,
    abs: end_pressure_abs_error__atm,
    rel: end_pressure_rel_error,
    unit: 'атм',
  },
  {
    dem: 'start_fluid_volume_flow__m3_per_day',
    value: start_fluid_volume_flow__m3_per_day,
    abs: start_fluid_volume_flow_abs_error__m3_per_day,
    rel: start_fluid_volume_flow_rel_error,
    unit: 'м3/сут',
  },
  {
    dem: 'end_fluid_volume_flow__m3_per_day',
    value: end_fluid_volume_flow__m3_per_day,
    abs: end_fluid_volume_flow_abs_error__m3_per_day,
    rel: end_fluid_volume_flow_rel_error,
    unit: 'м3/сут',
  },
  {
    dem: 'start_oil_mass_flow__t_per_day',
    value: start_oil_mass_flow__t_per_day,
    abs: start_oil_mass_flow_abs_error__t_per_day,
    rel: start_oil_mass_flow_rel_error,
    unit: 'т/сут',
  },
  {
    dem: 'end_oil_mass_flow__t_per_day',
    value: end_oil_mass_flow__t_per_day,
    abs: end_oil_mass_flow_abs_error__t_per_day,
    rel: end_oil_mass_flow_rel_error,
    unit: 'т/сут',
  },
  {
    dem: 'start_gas_volume_flow__thos_m3_per_day',
    value: start_gas_volume_flow__thos_m3_per_day,
    abs: start_gas_volume_flow_abs_error__thos_m3_per_day,
    rel: start_gas_volume_flow_rel_error,
    unit: 'тыс. м3/сут',
  },
  {
    dem: 'end_pressure__atm',
    value: end_gas_volume_flow__thos_m3_per_day,
    abs: end_gas_volume_flow_abs_error__thos_m3_per_day,
    rel: end_gas_volume_flow_rel_error,
    unit: 'тыс. м3/сут',
  },
];
