import React from 'react';
import { useDispatch } from 'react-redux';

import { updateWellModal } from 'store/projectSlice';

import { getWellModel } from 'services/apiRequests';

const useGetWellModel = (projectUid: string, wellUid: string) => {
  const dispatch = useDispatch();

  const fetchWellModel = async () => {
    try {
      const response = await getWellModel(wellUid);
      dispatch(updateWellModal(response.data));
    } catch (e) {
      //
    }
  };

  React.useEffect(() => {
    fetchWellModel();
  }, [projectUid, wellUid]);
};

export default useGetWellModel;
