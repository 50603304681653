import React from 'react';

import AvgTable from './AvgTable';
import { separatorsAvgTableHead } from './utils';

import '../separators.scss';

const UASeparatorsResults: React.FC = () => {
  return (
    <>
      <div className="separators__table-title">Средние значения</div>
      <AvgTable tableHead={separatorsAvgTableHead} />
    </>
  );
};

export default UASeparatorsResults;
