import * as React from 'react';

import SourcesTable from './SourceTable';
import { wellfieldsTableHead } from './utils';

const Table: React.FC = () => {
  return <SourcesTable tableHead={wellfieldsTableHead} />;
};

export default Table;
