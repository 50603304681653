import * as React from 'react';
import { useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { components } from 'generated/apiTypes';
import StepInput from 'Pages/Project/ResultsPanel/components/StepInput';
import { selectSolution } from 'store/taskSlice';

import NodesTable from './NodesTable';
import { nodesTableHead } from './utils';

const Table: React.FC = () => {
  return (
    <>
      <NodesTable tableHead={nodesTableHead} />
    </>
  );
};

export default Table;
