import * as React from 'react';
import { useSelector } from 'react-redux';

import classnames from 'classnames';
import { components } from 'generated/apiTypes';
import { selectSolution } from 'store/taskSlice';

import TableHead from '../../../../components/TableHead';
import TableWrapper from '../../../../components/TableWrapper';
import { ISourcesTableProps } from '../../../../types';
import TemplateTableRow from '../../UA/Table/TemplateTableRow';
import { templateRowsNumber } from '../../UA/Table/utils';
import TableRow from './TableRow';
import { wellsTableItems } from './utils';

const SourcesByStepTable: React.FC<ISourcesTableProps> = ({
  tableHead,
  keyD,
}) => {
  const solution = useSelector(selectSolution)
    ?.solution as components['schemas']['PNOTechModeOptimizationProblem'];
  const [data, setData] = React.useState<any[]>([]);
  const headKeys = Object.keys(tableHead);

  const getData = () => {
    const newData: any = [];
    keyD &&
      solution?.solution?.well_step_table?.time2states[keyD]?.forEach(state => {
        newData.push(wellsTableItems(state));
      });

    setData(newData);
  };

  React.useEffect(() => {
    getData();
  }, [solution, keyD]);

  return (
    <TableWrapper vertical horizontal smoothScrolling>
      <table className={classnames('table', 'table__sources-table')}>
        <TableHead headKeys={headKeys} tableHead={tableHead} />
        <tbody>
          {data?.length > 0
            ? data.map((value: any, index: number) => {
                return (
                  <TableRow
                    key={index}
                    headKeys={headKeys}
                    el={value}
                    status={value.current_state}
                  />
                );
              })
            : Array.from({ length: templateRowsNumber }, (item, index) => (
                <TemplateTableRow key={index} headKeys={headKeys} />
              ))}
        </tbody>
      </table>
    </TableWrapper>
  );
};

export default SourcesByStepTable;
