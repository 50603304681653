import * as React from 'react';
import { useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { ReactComponent as ChartIcon } from 'images/Project/icn-line-chart.svg';
import { ReactComponent as TableIcon } from 'images/Project/icn-table.svg';
import { selectActiveObject } from 'store/mapSlice';
import {
  selectUpdatedModel,
  selectWellById,
  selectWellModelById,
} from 'store/projectSlice';

import { components } from '../../../../../generated/apiTypes';
import { getWellOperationType } from '../../../utils';
import Chart from '../Chart';
import Table from '../Table';
import { isModelExistByType } from '../Table/utils';
import useChartData from './hooks/use-chart-data';

const FlowRates: React.FC = () => {
  const wellsModelsById = useSelector(selectWellModelById);
  const wellsById = useSelector(selectWellById);
  const activeObject = useSelector(selectActiveObject);
  const updatedModel = useSelector(selectUpdatedModel);
  const [tabIndex, setTabIndex] = React.useState<number>(
    isModelExistByType(
      'FlowRates',
      wellsModelsById[activeObject.object_uid as string],
    )
      ? 0
      : 1,
  );
  const [modelExist, setModelExist] = React.useState<boolean>(
    isModelExistByType(
      'FlowRates',
      wellsModelsById[activeObject.object_uid as string],
    ),
  );
  const [wellOperationType, setWellOperationType] = React.useState<
    components['schemas']['PNOWellOperationType'] | '5d'
  >();
  const [wellModel, setWellModel] = React.useState<
    components['schemas']['GetWellModelQueryResult']
  >(wellsModelsById[activeObject.object_uid as string]);
  const [tableState, setTableState] = React.useState<
    'noModel' | 'view' | 'edit'
  >(
    updatedModel.includes(activeObject.object_uid as string) &&
      isModelExistByType('FlowRates', wellModel)
      ? 'edit'
      : 'view',
  );
  const chartData = useChartData(
    wellModel,
    wellOperationType as components['schemas']['PNOWellOperationType'],
  );

  React.useEffect(() => {
    const exist = isModelExistByType(
      'FlowRates',
      wellsModelsById[activeObject.object_uid as string],
    );
    setModelExist(exist);
    setWellOperationType(
      getWellOperationType(wellsById[activeObject.object_uid as string].type),
    );
    setWellModel(wellsModelsById[activeObject.object_uid as string]);
    if (!exist) setTabIndex(1);
  }, [
    activeObject.object_uid,
    (
      wellsModelsById[activeObject.object_uid as string]
        ?.model as components['schemas']['GetPeriodicPumpWellModelQueryResult']
    ).flow_rates__m3_per_day,
  ]);

  React.useEffect(() => {
    setTableState(
      updatedModel.includes(activeObject.object_uid as string) &&
        isModelExistByType('FlowRates', wellModel)
        ? 'edit'
        : 'view',
    );
  }, [activeObject.object_uid]);

  React.useEffect(() => {
    if (tableState === undefined || tableState === 'view') {
      if (wellModel)
        setTableState(
          isModelExistByType('FlowRates', wellModel) ? 'view' : 'noModel',
        );
    }
    if (tableState === 'edit') setTabIndex(1);
  }, [wellModel, tableState]);

  return (
    <>
      <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
        <div className="well-model-panel__tab-list-wrapper">
          <TabList className="results__tab-list">
            <Tab className="results__icon" disabled={!modelExist}>
              <ChartIcon />
            </Tab>
            <Tab className="results__icon">
              <TableIcon />
            </Tab>
          </TabList>
          {/* tabIndex === 1 && (
            <div className="well-model-panel__table-caption">
              {getTableCaptionByType('FlowRates')}
            </div>
          ) */}
        </div>
        <div>
          <TabPanel>
            <Chart
              chartType="FlowRates"
              chartData={chartData}
              wellOperationType={
                wellOperationType as components['schemas']['PNOWellOperationType']
              }
              tableState={tableState}
              setTableState={setTableState}
            />
          </TabPanel>
          <TabPanel>
            <Table
              wellOperationType={
                wellOperationType as components['schemas']['PNOWellOperationType']
              }
              tableType="FlowRates"
              tableState={tableState}
              setTableState={setTableState}
            />
          </TabPanel>
        </div>
      </Tabs>
    </>
  );
};

export default FlowRates;
