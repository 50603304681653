import React from 'react';

import classnames from 'classnames';

import { ITableRowProps } from '../../../../types';

const CompareTableRow: React.FC<ITableRowProps> = ({ el }) => {
  const renderStatus = (status: string) => {
    return (
      <p
        className={classnames(
          'table-status',
          status === 'Работает' ? 'working' : 'stopped',
        )}
      >
        {' '}
        {status.slice(0, 3).toUpperCase()}
      </p>
    );
  };
  const renderField = (item: any) => {
    return (
      <div>
        <p className={classnames('compare-field', 'value')}>{item.value}</p>
        {!['well_name', 'operation_type', 'control_type'].includes(item.dem) &&
          item.value !== 0 &&
          item.value !== undefined &&
          item.value !== null && (
            <div className={classnames('compare-field', 'compare')}>
              <p>
                <text>{item.abs}</text>
                <text>{item.abs !== undefined && ` ${item.unit}`}</text>
              </p>
              <p>
                <text>{item.rel}</text>
                <text>{item.rel !== undefined && '%'}</text>
              </p>
            </div>
          )}
      </div>
    );
  };
  return (
    <tr className={classnames('table-row big')}>
      {el.map((item: any, index: number) => (
        <td className={classnames('table-data compare', index < 5 && 'left')}>
          {item.dem === 'fact_current_state' ||
          item.dem === 'base_current_state'
            ? renderStatus(item.value)
            : renderField(item)}
        </td>
      ))}
    </tr>
  );
};

export default CompareTableRow;
