import * as React from 'react';
import { useSelector } from 'react-redux';

import classnames from 'classnames';
import { components } from 'generated/apiTypes';
import { selectSolution } from 'store/taskSlice';

import TableWrapper from '../../../../components/TableWrapper';
import { IThead } from '../../../../types';
import TableHead from './TableHead';
import TableRow from './TableRow';
import TemplateTableRow from './TemplateTableRow';
import { templateRowsNumber } from './utils';

export interface INodesTableProps {
  tableHead: IThead;
  step?: number;
  nodeUids: (string | undefined)[];
}

const NodesAvgTable: React.FC<INodesTableProps> = ({ tableHead, nodeUids }) => {
  const solution = useSelector(selectSolution)
    ?.solution as components['schemas']['PNOPeriodicNodalAnalysisProblem'];
  const [data, setData] = React.useState<any[]>([]);
  const headKeys = Object.keys(tableHead);

  const getData = () => {
    const newData: any[] = [];
    solution?.solution?.node_avg_table?.states
      .filter(state => nodeUids?.includes(state.uid))
      .forEach(state => {
        newData.push({
          name: state.name,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          oil_flow_rate: state.oil_flow_rate_mass.t_per_day,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          fluid_flow_rate: state.fluid_flow_rate_volume.m3_per_day,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          gas_flow_rate: state.gas_flow_rate_volume.thousand_m3_per_day,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          pressure: state.pressure.atm,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          temperature: state.temperature.celsius,
        });
      });
    setData(newData);
  };

  React.useEffect(() => {
    getData();
  }, [solution, nodeUids]);

  return (
    <TableWrapper vertical horizontal smoothScrolling>
      <table className={classnames('table', 'table__nodes-table')}>
        <TableHead headKeys={headKeys} tableHead={tableHead} />
        <tbody>
          {data?.length > 0
            ? data.map((value: any, index: number) => {
                return <TableRow key={index} headKeys={headKeys} el={value} />;
              })
            : Array.from({ length: templateRowsNumber }, (item, index) => (
                <TemplateTableRow key={index} headKeys={headKeys} />
              ))}
        </tbody>
      </table>
    </TableWrapper>
  );
};

export default NodesAvgTable;
