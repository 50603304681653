import * as React from 'react';

import LayersTable from './AvgTable';
import { layersTableHead } from './utils';

const Table: React.FC = () => {
  return <LayersTable tableHead={layersTableHead} />;
};

export default Table;
