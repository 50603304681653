import * as React from 'react';

export const templateRowsNumber = 5;

export const nodesTableHead = {
  name: 'Название',
  type: {
    title: 'Тип',
    component: <>Тип</>,
  },
  oil_flow_rate: {
    title: 'Расход нефти',
    component: (
      <>
        Расход нефти,
        <br /> т/сут
      </>
    ),
  },
  fluid_flow_rate: {
    title: 'Расход жидкости',
    component: (
      <>
        Расход жидкости,
        <br /> м<span className="sup">3</span>/сут
      </>
    ),
  },
  gas_flow_rate: {
    title: 'Расход газа',
    component: (
      <>
        Расход газа, <br />
        тыс.м<span className="sup">3</span>/сут
      </>
    ),
  },
  pressure: {
    title: 'Давление',
    component: (
      <>
        Давление, <br />
        атм
      </>
    ),
  },
  temperature: {
    title: 'Температура',
    component: (
      <>
        Температура,
        <br />
        ˚C
      </>
    ),
  },
};
