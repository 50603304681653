import * as React from 'react';
import { useSelector } from 'react-redux';

import classnames from 'classnames';
import { components } from 'generated/apiTypes';
import { selectSegments } from 'store/projectSlice';
import { selectSolution } from 'store/taskSlice';

import TableHead from '../../../components/TableHead';
import TableWrapper from '../../../components/TableWrapper';
import { ISegmentsTableProps } from '../../../types';
import TableRow from './TableRow';
import TemplateTableRow from './TemplateTableRow';
import { segmentsTableItems, templateRowsNumber } from './utils';

const SegmentsAvgTable: React.FC<ISegmentsTableProps> = ({ tableHead }) => {
  const solution = useSelector(selectSolution)
    ?.solution as components['schemas']['PNOTechModeOptimizationProblem'];
  const [data, setData] = React.useState<any[]>([]);
  const headKeys = Object.keys(tableHead);

  const getData = () => {
    const newData: any = [];
    solution?.solution?.segment_avg_table?.states.forEach(state => {
      newData.push(segmentsTableItems(state));
    });
    setData(newData);
  };

  React.useEffect(() => {
    getData();
  }, [solution]);

  return (
    <TableWrapper vertical horizontal smoothScrolling>
      <table className={classnames('table', 'table__segments-table')}>
        <TableHead headKeys={headKeys} tableHead={tableHead} />
        <tbody>
          {data?.length > 0
            ? data.map((value: any, index: number) => {
                return <TableRow key={index} headKeys={headKeys} el={value} />;
              })
            : Array.from({ length: templateRowsNumber }, (item, index) => (
                <TemplateTableRow key={index} headKeys={headKeys} />
              ))}
        </tbody>
      </table>
    </TableWrapper>
  );
};

export default SegmentsAvgTable;
