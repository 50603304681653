import * as React from 'react';

import { threeDigitsFormatter, twoDigitsFormatter } from 'utils';

export const templateRowsNumber = 5;

export const wellfieldsAvgTableHead = {
  current_state: 'Статус',
  well_name: 'Название',
  operation_type: {
    title: 'Способ эксплуатации',
    component: (
      <>
        Способ <br /> эксплуатации
      </>
    ),
  },
  control_type: {
    title: 'Режим работы',
    component: (
      <>
        Режим <br /> работы
      </>
    ),
  },
  offset_time: {
    title: 'Оффсет',
    component: (
      <>
        Оффсет, <br />
        мин
      </>
    ),
  },
  oil_flow_rate: {
    title: 'Дебит нефти',
    component: (
      <>
        Дебит нефти, <br /> т/сут
      </>
    ),
  },
  fluid_flow_rate: {
    title: 'Дебит жидкости',
    component: (
      <>
        Дебит жидкости, <br /> м3/сут
      </>
    ),
  },
  gas_flow_rate: {
    title: 'Дебит газа',
    component: (
      <>
        Дебит газа,
        <br />
        тыс.м3/сут
      </>
    ),
  },
  pressure: {
    title: 'Давление',
    component: (
      <>
        Давление,
        <br /> атм
      </>
    ),
  },
  control_freq: {
    title: 'Частота работы',
    component: (
      <>
        Частота <br />
        работы, Гц
      </>
    ),
  },
  accumulation_freq: {
    title: 'Частота накопления',
    component: (
      <>
        Частота <br />
        накопления, Гц
      </>
    ),
  },
  work_time: {
    title: 'Длит. интервала работы',
    component: (
      <>
        Длит. интервала <br />
        работы, мин
      </>
    ),
  },
  accumulation_time: {
    title: 'Длит. интервала накопления',
    component: (
      <>
        Длит. интервала <br />
        накопления, мин
      </>
    ),
  },
  control_flow: {
    title: 'Расход АГ',
    component: (
      <>
        Расход АГ, <br /> тыс.м3/сут
      </>
    ),
  },
  power: {
    title: 'Мощность',
    component: (
      <>
        Мощность, <br /> кВт
      </>
    ),
  },
  interval: {
    title: 'Интервал работы',
    component: (
      <>
        Интервал работы, <br /> мин
      </>
    ),
  },
};

export const wellfieldsByStepTableHead = {
  name: 'Название',
  operation_type: {
    title: 'Способ эксплуатации',
    component: (
      <>
        Способ <br /> эксплуатации
      </>
    ),
  },
  control_type: {
    title: 'Режим работы',
    component: (
      <>
        Режим <br /> работы
      </>
    ),
  },
  oil_flow_rate: {
    title: 'Qн, т/сут',
    component: <>Qн, т/сут</>,
  },
  fluid_flow_rate: {
    title: 'Qж, т/сут',
    component: <>Qж, т/сут</>,
  },
  gas_flow_rate: {
    title: 'Qг, тыс. м3/сут',
    component: (
      <>
        Qг, <br />
        <span className="relative">
          тыс. м<span className="index">3</span>
        </span>
        &nbsp; /сут
      </>
    ),
  },
  frequency: {
    title: 'F, Гц',
    component: <>F, Гц</>,
  },
  consumption: {
    title: 'Qг/г, м3/сут',
    component: (
      <>
        Qг/г, <br />
        <span className="relative">
          м<span className="index">3</span>
        </span>
        &nbsp; /сут
      </>
    ),
  },
  pressure: {
    title: 'P, атм',
    component: <>Р, атм</>,
  },
  temperature: {
    title: 't, °С',
    component: <>t,°С</>,
  },
};

export const compareWellsTableHead = {
  fact_current_state: {
    title: 'Факт. статус',
    component: (
      <>
        Факт. <br /> статус
      </>
    ),
  },
  base_current_state: {
    title: 'Тек. статус',
    component: (
      <>
        Тек. <br /> статус
      </>
    ),
  },
  well_name: {
    title: 'Название',
    component: <>Название</>,
  },
  operation_type: {
    title: 'Способ эксплуатации',
    component: (
      <>
        Способ <br /> эксплуатации
      </>
    ),
  },
  control_type: {
    title: 'Режим работы',
    component: (
      <>
        Режим <br /> работы
      </>
    ),
  },
  fact_offset_time: {
    title: 'Факт. оффсет',
    component: (
      <>
        Факт. оффсет
        <br /> мин
      </>
    ),
  },
  base_offset_time: {
    title: 'Тек. оффсет',
    component: (
      <>
        Тек. оффсет
        <br /> мин
      </>
    ),
  },
  fact_pressure: {
    title: 'Факт. давление',
    component: (
      <>
        Факт. лин.
        <br /> давление, атм
      </>
    ),
  },
  base_pressure: {
    title: 'Тек. давление',
    component: (
      <>
        Тек. лин.
        <br /> давление, атм
      </>
    ),
  },
  fact_fluid_flow_rate: {
    title: 'Факт. расход жидкости',
    component: (
      <>
        Факт. расход
        <br /> жидкости, м3/сут
      </>
    ),
  },
  base_fluid_flow_rate: {
    title: 'Тек. расход жидкости',
    component: (
      <>
        Тек. расход
        <br /> жидкости, м3/сут
      </>
    ),
  },
  fact_gas_flow_rate: {
    title: 'Факт. расход газа',
    component: (
      <>
        Факт. расход
        <br /> газа, тыс. м3/сут
      </>
    ),
  },
  base_gas_flow_rate: {
    title: 'Тек. расход газа',
    component: (
      <>
        Тек. расход
        <br /> газа, тыс. м3/сут
      </>
    ),
  },
  fact_oil_flow_rate: {
    title: 'Факт. расход нефти',
    component: (
      <>
        Факт. расход
        <br /> нефти, т/сут
      </>
    ),
  },
  base_oil_flow_rate: {
    title: 'Тек. расход нефти',
    component: (
      <>
        Тек. расход нефти,
        <br />
        т/сут
      </>
    ),
  },
  fact_control_freq: {
    title: 'Факт. управляющее воздействие',
    component: (
      <>
        Факт. управляющее <br /> воздействие, Гц
      </>
    ),
  },
  base_control_freq: {
    title: 'Тек. управляющее воздействие',
    component: (
      <>
        Тек. управляющее <br /> воздействие, Гц
      </>
    ),
  },
  fact_control_flow: {
    title: 'Факт. управляющее воздействие',
    component: (
      <>
        Факт. управляющее <br /> воздействие, <br /> тыс. м3/сут
      </>
    ),
  },
  base_control_flow: {
    title: 'Тек. управляющее воздействие',
    component: (
      <>
        Тек. управляющее <br /> воздействие, <br /> тыс. м3/сут
      </>
    ),
  },
  fact_work_time: {
    title: 'Факт. длит. интервала работы',
    component: (
      <>
        Факт. длит. <br /> интервала работы, <br /> мин
      </>
    ),
  },
  base_work_time: {
    title: 'Тек. длит. интервала работы',
    component: (
      <>
        Тек. длит. <br /> интервала работы, <br /> мин
      </>
    ),
  },
  fact_accumulation_time: {
    title: 'Факт. длит. интервала накопления',
    component: (
      <>
        Факт. длит. <br /> интервала накопления, <br /> мин
      </>
    ),
  },
  base_accumulation_time: {
    title: 'Тек. длит. интервала накопления',
    component: (
      <>
        Тек. длит. <br /> интервала накопления, <br /> мин
      </>
    ),
  },
};

export const compareTableItems = ({
  well_name,
  operation_type,
  control_type,
  calc_current_state,
  preset_current_state,
  calc_avg_pressure,
  preset_avg_pressure,
  pressure_abs_error,
  pressure_rel_error,
  calc_oil_avg_flow_rate,
  preset_oil_avg_flow_rate,
  oil_flow_rate_abs_error,
  oil_flow_rate_rel_error,
  calc_fluid_avg_flow_rate,
  preset_fluid_avg_flow_rate,
  fluid_flow_rate_abs_error,
  fluid_flow_rate_rel_error,
  calc_gas_avg_flow_rate,
  preset_gas_avg_flow_rate,
  gas_flow_rate_abs_error,
  gas_flow_rate_rel_error,
  calc_control,
  preset_control,
  control_abs_error,
  control_rel_error,
  preset_offset_time,
  calc_offset_time,
  offset_time_abs_error,
  calc_work_time__min,
  preset_work_time__min,
  work_time_abs_error__min,
  work_time_rel_error,
  calc_accumulation_time__min,
  preset_accumulation_time__min,
  accumulation_time_abs_error__min,
  accumulation_time_rel_error,
}: any = {}) => [
  {
    dem: 'fact_current_state',
    value: preset_current_state,
  },
  {
    dem: 'base_current_state',
    value: calc_current_state,
  },
  {
    dem: 'well_name',
    value: well_name,
  },
  {
    dem: 'operation_type',
    value: operation_type,
  },
  {
    dem: 'control_type',
    value: control_type,
  },
  {
    dem: 'fact_offset_time',
    value: preset_offset_time && twoDigitsFormatter.format(preset_offset_time),
  },
  {
    dem: 'base_offset_time',
    value: calc_offset_time && twoDigitsFormatter.format(calc_offset_time),
    abs:
      offset_time_abs_error && twoDigitsFormatter.format(offset_time_abs_error),
    unit: 'мин',
  },
  {
    dem: 'fact_pressure',
    value:
      preset_avg_pressure &&
      threeDigitsFormatter.format(preset_avg_pressure.atm),
  },
  {
    dem: 'base_pressure',
    value:
      calc_avg_pressure && threeDigitsFormatter.format(calc_avg_pressure.atm),
    abs:
      pressure_abs_error && twoDigitsFormatter.format(pressure_abs_error.atm),
    rel: pressure_rel_error && twoDigitsFormatter.format(pressure_rel_error),
    unit: 'атм',
  },
  {
    dem: 'fact_fluid_flow_rate',
    value:
      preset_fluid_avg_flow_rate &&
      threeDigitsFormatter.format(preset_fluid_avg_flow_rate.m3_per_day),
  },
  {
    dem: 'base_fluid_flow_rate',
    value:
      calc_fluid_avg_flow_rate &&
      threeDigitsFormatter.format(calc_fluid_avg_flow_rate.m3_per_day),
    abs:
      fluid_flow_rate_abs_error &&
      twoDigitsFormatter.format(fluid_flow_rate_abs_error.m3_per_day),
    rel:
      fluid_flow_rate_rel_error &&
      twoDigitsFormatter.format(fluid_flow_rate_rel_error),
    unit: 'м3/сут',
  },
  {
    dem: 'fact_gas_flow_rate',
    value:
      preset_gas_avg_flow_rate &&
      threeDigitsFormatter.format(preset_gas_avg_flow_rate.thousand_m3_per_day),
  },
  {
    dem: 'base_gas_flow_rate',
    value:
      calc_gas_avg_flow_rate &&
      threeDigitsFormatter.format(calc_gas_avg_flow_rate.thousand_m3_per_day),
    abs:
      gas_flow_rate_abs_error &&
      twoDigitsFormatter.format(gas_flow_rate_abs_error.thousand_m3_per_day),
    rel:
      gas_flow_rate_rel_error &&
      twoDigitsFormatter.format(gas_flow_rate_rel_error),
    unit: 'тыс. м3/сут',
  },
  {
    dem: 'fact_oil_flow_rate',
    value:
      preset_oil_avg_flow_rate &&
      threeDigitsFormatter.format(preset_oil_avg_flow_rate.t_per_day),
  },
  {
    dem: 'base_oil_flow_rate',
    value:
      calc_oil_avg_flow_rate &&
      threeDigitsFormatter.format(calc_oil_avg_flow_rate.t_per_day),
    abs:
      oil_flow_rate_abs_error &&
      twoDigitsFormatter.format(oil_flow_rate_abs_error.t_per_day),
    rel:
      oil_flow_rate_rel_error &&
      twoDigitsFormatter.format(oil_flow_rate_rel_error),
    unit: 'т/сут',
  },

  {
    dem: 'fact_control_freq',
    value:
      operation_type === 'ЭЦН' && preset_control
        ? threeDigitsFormatter.format(preset_control.hertz)
        : undefined,
  },
  {
    dem: 'base_control_freq',
    value:
      operation_type === 'ЭЦН' && calc_control
        ? threeDigitsFormatter.format(calc_control.hertz)
        : undefined,
    abs:
      operation_type === 'ЭЦН' && control_abs_error
        ? twoDigitsFormatter.format(control_abs_error.hertz)
        : 0,
    rel:
      operation_type === 'ЭЦН' && control_rel_error
        ? twoDigitsFormatter.format(control_rel_error)
        : 0,
    unit: 'Гц',
  },
  {
    dem: 'fact_control_flow',
    value:
      operation_type === 'Газлифт' && preset_control
        ? threeDigitsFormatter.format(preset_control.thousand_m3_per_day)
        : undefined,
  },
  {
    dem: 'base_control_flow',
    value:
      operation_type === 'Газлифт' && calc_control
        ? threeDigitsFormatter.format(calc_control.thousand_m3_per_day)
        : undefined,
    abs:
      operation_type === 'Газлифт' && control_abs_error
        ? twoDigitsFormatter.format(control_abs_error.thousand_m3_per_day)
        : 0,
    rel:
      operation_type === 'Газлифт' && control_rel_error
        ? twoDigitsFormatter.format(control_rel_error)
        : 0,
    unit: 'тыс. м3/сут',
  },
  {
    dem: 'fact_work_time',
    value:
      preset_work_time__min && twoDigitsFormatter.format(preset_work_time__min),
  },
  {
    dem: 'base_work_time',
    value:
      calc_work_time__min && twoDigitsFormatter.format(calc_work_time__min),
    abs:
      work_time_abs_error__min &&
      twoDigitsFormatter.format(work_time_abs_error__min),
    rel: work_time_rel_error && twoDigitsFormatter.format(work_time_rel_error),
    unit: 'мин',
  },
  {
    dem: 'fact_accumulation_time',
    value:
      preset_accumulation_time__min &&
      twoDigitsFormatter.format(preset_accumulation_time__min),
  },
  {
    dem: 'base_accumulation_time',
    value:
      calc_accumulation_time__min &&
      twoDigitsFormatter.format(calc_accumulation_time__min),
    abs:
      accumulation_time_abs_error__min &&
      twoDigitsFormatter.format(accumulation_time_abs_error__min),
    rel:
      accumulation_time_rel_error &&
      twoDigitsFormatter.format(accumulation_time_rel_error),
    unit: 'мин',
  },
];
