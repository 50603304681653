import * as React from 'react';

import { formatter } from 'utils';

export const dashboardItems = ({
  oil_flow_rate_mass,
  oil_flow_rate_mass_abs,
  oil_flow_rate_mass_rel,
  consumed_electricity,
  consumed_electricity_abs,
  consumed_electricity_rel,
  consumed_active_gas,
  consumed_active_gas_abs,
  consumed_active_gas_rel,
  fluid_flow_rate_volume,
  fluid_flow_rate_volume_abs,
  fluid_flow_rate_volume_rel,
  associated_petroleum_gas,
  associated_petroleum_gas_abs,
  associated_petroleum_gas_rel,
  water_flow_rate,
  water_flow_rate_abs,
  water_flow_rate_rel,
  consumed_electricity_per_oil,
  consumed_electricity_per_oil_abs,
  consumed_electricity_per_oil_rel,
  consumed_active_gas_per_oil,
  consumed_active_gas_per_oil_abs,
  consumed_active_gas_per_oil_rel,
}: any = {}) => [
  {
    dem: 'Сбор нефти',
    value:
      oil_flow_rate_mass !== undefined
        ? oil_flow_rate_mass.t_per_day
        : oil_flow_rate_mass,
    abs:
      oil_flow_rate_mass_abs !== undefined
        ? oil_flow_rate_mass_abs.t_per_day
        : oil_flow_rate_mass_abs,
    rel: oil_flow_rate_mass_rel !== undefined && oil_flow_rate_mass_rel,
    unit: 'т/сут',
  },
  {
    dem: 'Затраты электроэнергии',
    value:
      consumed_electricity !== undefined
        ? consumed_electricity.kW_h_per_day
        : consumed_electricity,
    abs:
      consumed_electricity_abs !== undefined
        ? consumed_electricity_abs.kW_h_per_day
        : consumed_electricity_abs,
    rel: consumed_electricity_rel !== undefined && consumed_electricity_rel,
    unit: 'кВт·ч/сут',
  },
  {
    dem: 'Расход активного газа',
    value:
      consumed_active_gas !== undefined
        ? consumed_active_gas.thousand_m3_per_day
        : consumed_active_gas,
    abs:
      consumed_active_gas_abs !== undefined
        ? consumed_active_gas_abs.thousand_m3_per_day
        : consumed_active_gas_abs,
    rel: consumed_active_gas_rel !== undefined && consumed_active_gas_rel,
    unit: (
      <>
        <span className="unit">
          тыс.м<span className="index">3</span>
        </span>
        {' /сут'}
      </>
    ),
  },
  {
    dem: 'Сбор жидкости',
    value:
      fluid_flow_rate_volume !== undefined
        ? fluid_flow_rate_volume.m3_per_day
        : fluid_flow_rate_volume,
    abs:
      fluid_flow_rate_volume_abs !== undefined
        ? fluid_flow_rate_volume_abs.m3_per_day
        : fluid_flow_rate_volume_abs,
    rel: fluid_flow_rate_volume_rel !== undefined && fluid_flow_rate_volume_rel,
    unit: (
      <>
        <span className="unit">
          м<span className="index">3</span>
        </span>
        {' /сут'}
      </>
    ),
  },
  {
    dem: 'Сбор ПНГ',
    value:
      associated_petroleum_gas !== undefined
        ? associated_petroleum_gas.thousand_m3_per_day
        : associated_petroleum_gas,
    abs:
      associated_petroleum_gas_abs !== undefined
        ? associated_petroleum_gas_abs.thousand_m3_per_day
        : associated_petroleum_gas_abs,
    rel:
      associated_petroleum_gas_rel !== undefined &&
      associated_petroleum_gas_rel,
    unit: (
      <>
        <span className="unit">
          тыс.м<span className="index">3</span>
        </span>
        {' /сут'}
      </>
    ),
  },
  {
    dem: 'Сбор воды',
    value:
      water_flow_rate !== undefined
        ? water_flow_rate.m3_per_day
        : water_flow_rate,
    abs:
      water_flow_rate_abs !== undefined
        ? water_flow_rate_abs.m3_per_day
        : water_flow_rate_abs,
    rel: water_flow_rate_rel !== undefined && water_flow_rate_rel,
    unit: (
      <>
        <span className="unit">
          м<span className="index">3</span>
        </span>
        {' /сут'}
      </>
    ),
  },
  {
    dem: 'УРЭ',
    value:
      consumed_electricity_per_oil !== undefined
        ? consumed_electricity_per_oil.value
        : consumed_electricity_per_oil,
    abs:
      consumed_electricity_per_oil_abs !== undefined
        ? consumed_electricity_per_oil_abs.value
        : consumed_electricity_per_oil_abs,
    rel:
      consumed_electricity_per_oil_rel !== undefined &&
      consumed_electricity_per_oil_rel,
    unit: 'кВт·ч/т',
  },
  {
    dem: 'УРГГ',
    value:
      consumed_active_gas_per_oil !== undefined
        ? consumed_active_gas_per_oil.thousand_m3_per_t
        : consumed_active_gas_per_oil,
    abs:
      consumed_active_gas_per_oil_abs !== undefined
        ? consumed_active_gas_per_oil_abs.thousand_m3_per_t
        : consumed_active_gas_per_oil_abs,
    rel:
      consumed_active_gas_per_oil_rel !== undefined &&
      consumed_active_gas_per_oil_rel,
    unit: (
      <>
        <span className="unit">
          тыс.м<span className="index">3</span>
        </span>
        {' /т'}
      </>
    ),
  },
];
