import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { components } from 'generated/apiTypes';
import update from 'immutability-helper';
import { RootState } from 'store/store';

import { composeObjectsById } from '../Pages/Project/utils';
import { initialState } from './initialState';
import {
  IProjectSlice,
  SelectGatheringCentersFactPressuresById,
  SelectGatheringCentersModelById,
  SelectInjectionWellById,
  SelectInjectionWellModelById,
  SelectNodeById,
  SelectPhysChemPackageById,
  SelectProductionWellById,
  SelectProductionWellModelById,
  SelectPumpById,
  SelectSegmentById,
  SelectSeparatorById,
  SelectSinkById,
  SelectSourceById,
  SelectThreePhaseSeparatorById,
  SelectUnconnectedWellModelById,
  SelectWellById,
  SelectWellControlModelById,
  SelectWellFactMeasurementById,
  SelectWellHydroModelById,
  SelectWellModelById,
} from './types';

const projectInitialState = initialState as IProjectSlice;

export const projectSlice = createSlice({
  name: 'project',
  initialState: {
    id: projectInitialState.id,
    name: projectInitialState.name,
    issuesState: projectInitialState.issuesState,
    showDetailsPanel: projectInitialState.showDetailsPanel,
    nodes: projectInitialState.nodes,
    wells: projectInitialState.wells,
    injection_wells: projectInitialState.injection_wells,
    production_wells: projectInitialState.production_wells,
    injection_well_models: projectInitialState.injection_well_models,
    production_well_models: projectInitialState.production_well_models,
    wellsModels: projectInitialState.wellsModels,
    unconnectedWellsModels: projectInitialState.unconnectedWellsModels,
    wellsControlModels: projectInitialState.wellsControlModels,
    wellsHydroModels: projectInitialState.wellsHydroModels,
    gatheringCentersModels: projectInitialState.gatheringCentersModels,
    gatheringCentersFactPressures:
      projectInitialState.gatheringCentersFactPressures,
    segments: projectInitialState.segments,
    physChemPackages: projectInitialState.physChemPackages,
    pipesCatalog: projectInitialState.pipesCatalog,
    separators: projectInitialState.separators,
    clusters: projectInitialState.clusters,
    three_phase_separators: projectInitialState.three_phase_separators,
    sinks: projectInitialState.sinks,
    pumps: projectInitialState.pumps,
    sources: projectInitialState.sources,
    reservoir: projectInitialState.reservoir,
  },
  reducers: {
    toggleIssues: (
      state,
      action: PayloadAction<IProjectSlice['issuesState']['show']>,
    ) => {
      if (typeof action.payload === 'boolean') {
        state.issuesState.show = action.payload;
      } else {
        state.issuesState.show = !state.issuesState.show;
      }
    },
    setIssues: (
      state,
      action: PayloadAction<IProjectSlice['issuesState']['issues']>,
    ) => {
      state.issuesState.issues = action.payload;
    },
    setShowDetailsPanel: (
      state,
      action: PayloadAction<IProjectSlice['showDetailsPanel']>,
    ) => {
      state.showDetailsPanel = action.payload;
    },
    setNodes: (
      state,
      action: PayloadAction<IProjectSlice['nodes']['items']>,
    ) => {
      state.nodes = {
        items: [...action.payload],
        isFetching: true,
        isUpdate: false,
      };
    },
    updateNodes: (
      state,
      action: PayloadAction<components['schemas']['PNOPipelineNode']>,
    ) => {
      const index = state.nodes.items.findIndex(
        ({ uid }) => action.payload.uid === uid,
      );
      state.nodes.items = update(state.nodes.items, {
        [index]: { $set: action.payload },
      });
    },
    updateNodesNetwork: (
      state,
      action: PayloadAction<IProjectSlice['nodes']['isUpdate']>,
    ) => {
      state.nodes = {
        ...state.nodes,
        isUpdate: action.payload,
      };
    },
    setWells: (
      state,
      action: PayloadAction<IProjectSlice['wells']['items']>,
    ) => {
      state.wells = {
        items: action.payload,
        isFetching: true,
      };
    },
    setInjectionWells: (
      state,
      action: PayloadAction<IProjectSlice['injection_wells']['items']>,
    ) => {
      state.injection_wells = {
        items: action.payload,
        isFetching: true,
      };
    },
    setProductionWells: (
      state,
      action: PayloadAction<IProjectSlice['production_wells']['items']>,
    ) => {
      state.production_wells = {
        items: action.payload,
        isFetching: true,
      };
    },
    updateWell: (
      state,
      action: PayloadAction<components['schemas']['GetWellQueryResult']>,
    ) => {
      let index = state.wells.items.findIndex(
        ({ id }) => action.payload.node_id === id,
      );

      if (index === -1) {
        index = state.wells.items.findIndex(
          ({ node_id }) => action.payload.node_id === node_id,
        );
      }
      state.wells.items = update(state.wells.items, {
        [index]: { $set: action.payload },
      });
    },
    setWellsModels: (
      state,
      action: PayloadAction<IProjectSlice['wellsModels']['items']>,
    ) => {
      state.wellsModels.items = action.payload;
    },
    setInjectionWellModels: (
      state,
      action: PayloadAction<IProjectSlice['injection_well_models']['items']>,
    ) => {
      state.injection_well_models = {
        items: action.payload,
        isFetching: true,
      };
    },
    setProductionWellModels: (
      state,
      action: PayloadAction<IProjectSlice['production_well_models']['items']>,
    ) => {
      state.production_well_models = {
        items: action.payload,
        isFetching: true,
      };
    },
    updateWellModal: (
      state,
      action: PayloadAction<components['schemas']['GetWellModelQueryResult']>,
    ) => {
      const index = state.wellsModels.items.findIndex(
        ({ well_id }) => action.payload.well_id === well_id,
      );
      if (index > -1) {
        state.wellsModels.items = update(state.wellsModels.items, {
          [index]: { $set: action.payload },
        });
      } else {
        state.wellsModels.items = update(state.wellsModels.items, {
          $push: [action.payload],
        });
      }
    },
    addUpdatedModel: (state, action: PayloadAction<string>) => {
      state.wellsModels.updatedModels = update(
        state.wellsModels.updatedModels,
        { $push: [action.payload] },
      );
    },
    removeUpdatedModel: (state, action: PayloadAction<string>) => {
      const index = state.wellsModels.updatedModels.findIndex(
        item => action.payload === item,
      );
      state.wellsModels.updatedModels = update(
        state.wellsModels.updatedModels,
        { $splice: [[index, 1]] },
      );
    },
    setUnconnectedWellsModels: (
      state,
      action: PayloadAction<IProjectSlice['unconnectedWellsModels']>,
    ) => {
      state.unconnectedWellsModels = action?.payload;
    },
    setWellsControlModels: (
      state,
      action: PayloadAction<IProjectSlice['wellsControlModels']>,
    ) => {
      state.wellsControlModels = action?.payload?.map(model => {
        if (model.offset_time == null) model.offset_time = 0;
        return model;
      });
    },
    updateWellsControlModel: (
      state,
      action: PayloadAction<components['schemas']['PNOWellControlModel']>,
    ) => {
      const index = state.wellsControlModels.findIndex(
        ({ well_id }) => action.payload.well_id === well_id,
      );
      state.wellsControlModels = update(state.wellsControlModels, {
        [index]: { $set: action.payload },
      });
    },
    setWellsHydroModels: (
      state,
      action: PayloadAction<IProjectSlice['wellsHydroModels']>,
    ) => {
      state.wellsHydroModels = action.payload;
    },
    updateWellsHydroModel: (
      state,
      action: PayloadAction<components['schemas']['PNOWellHydroModel']>,
    ) => {
      const index = state.wellsHydroModels.findIndex(
        ({ well_id }) => action.payload.well_id === well_id,
      );
      state.wellsHydroModels = update(state.wellsHydroModels, {
        [index]: { $set: action.payload },
      });
    },
    setGatheringCentersModels: (
      state,
      action: PayloadAction<IProjectSlice['gatheringCentersModels']>,
    ) => {
      state.gatheringCentersModels = action.payload;
    },
    updateGatheringCentersModel: (
      state,
      action: PayloadAction<components['schemas']['PNOGatheringCenterModel']>,
    ) => {
      const index = state.gatheringCentersModels.findIndex(
        ({ node_id }) => action.payload.node_id === node_id,
      );
      state.gatheringCentersModels = update(state.gatheringCentersModels, {
        [index]: { $set: action.payload },
      });
    },
    setSegments: (
      state,
      action: PayloadAction<IProjectSlice['segments']['items']>,
    ) => {
      state.segments = {
        items: [...action.payload],
        isFetching: true,
        isUpdate: false,
      };
    },
    updateSegments: (
      state,
      action: PayloadAction<components['schemas']['PNOPipelineSegment']>,
    ) => {
      const index = state.segments.items.findIndex(
        ({ uid }) => action.payload.uid === uid,
      );
      state.segments.items = update(state.segments.items, {
        [index]: { $set: action.payload },
      });
    },
    updateSegmentsNetwork: (
      state,
      action: PayloadAction<IProjectSlice['segments']['isUpdate']>,
    ) => {
      state.segments = {
        ...state.segments,
        isUpdate: action.payload,
      };
    },
    setPhysChemPackages: (
      state,
      action: PayloadAction<IProjectSlice['physChemPackages']>,
    ) => {
      state.physChemPackages = action.payload;
    },
    updatePhysChemPackages: (
      state,
      action: PayloadAction<components['schemas']['PNOPhysChemPackage']>,
    ) => {
      const index = state.physChemPackages.findIndex(
        ({ uid }) => action.payload.uid === uid,
      );
      state.physChemPackages = update(state.physChemPackages, {
        [index]: { $set: action.payload },
      });
    },
    setGatheringCentersFactPressures: (
      state,
      action: PayloadAction<IProjectSlice['gatheringCentersFactPressures']>,
    ) => {
      state.gatheringCentersFactPressures = action.payload;
    },
    updateGatheringCentersFactPressures: (
      state,
      action: PayloadAction<
        components['schemas']['PNOGatheringCenterFactPressure']
      >,
    ) => {
      const index = state.gatheringCentersFactPressures.findIndex(
        ({ gc_uid }) => action.payload.gc_uid === gc_uid,
      );
      state.gatheringCentersFactPressures = update(
        state.gatheringCentersFactPressures,
        {
          [index]: { $set: action.payload },
        },
      );
    },
    setProjectId: (state, action: PayloadAction<IProjectSlice['id']>) => {
      state.id = action.payload;
    },
    setProjectName: (state, action: PayloadAction<IProjectSlice['name']>) => {
      state.name = action.payload;
    },
    setPipesCatalog: (
      state,
      action: PayloadAction<IProjectSlice['pipesCatalog']>,
    ) => {
      state.pipesCatalog = action.payload;
    },
    setSeparators: (
      state,
      action: PayloadAction<IProjectSlice['separators']>,
    ) => {
      state.separators = action.payload;
    },
    setClusters: (state, action: PayloadAction<IProjectSlice['clusters']>) => {
      state.clusters = action.payload;
    },
    setThreePhaseSeparators: (
      state,
      action: PayloadAction<IProjectSlice['three_phase_separators']['items']>,
    ) => {
      state.three_phase_separators = {
        items: action.payload,
        isFetching: true,
      };
    },
    setSinks: (
      state,
      action: PayloadAction<IProjectSlice['sinks']['items']>,
    ) => {
      state.sinks = {
        items: action.payload,
        isFetching: true,
      };
    },
    setPumps: (
      state,
      action: PayloadAction<IProjectSlice['pumps']['items']>,
    ) => {
      state.pumps = {
        items: action.payload,
        isFetching: true,
      };
    },
    setSources: (
      state,
      action: PayloadAction<IProjectSlice['sources']['items']>,
    ) => {
      state.sources = {
        items: action.payload,
        isFetching: true,
      };
    },
    setReservoir: (
      state,
      action: PayloadAction<IProjectSlice['reservoir']>,
    ) => {
      state.reservoir = action.payload;
    },
    resetProjectSliceState: state => {
      state.name = projectInitialState.name;
      state.issuesState = projectInitialState.issuesState;
      state.showDetailsPanel = projectInitialState.showDetailsPanel;
      state.nodes = projectInitialState.nodes;
      state.wells = projectInitialState.wells;
      state.wellsModels = projectInitialState.wellsModels;
      state.wellsControlModels = projectInitialState.wellsControlModels;
      state.wellsHydroModels = projectInitialState.wellsHydroModels;
      state.gatheringCentersModels = projectInitialState.gatheringCentersModels;
      state.gatheringCentersFactPressures =
        projectInitialState.gatheringCentersFactPressures;
      state.segments = projectInitialState.segments;
      state.physChemPackages = projectInitialState.physChemPackages;
      state.pipesCatalog = projectInitialState.pipesCatalog;
      state.separators = projectInitialState.separators;
      state.clusters = projectInitialState.clusters;
      state.sinks = projectInitialState.sinks;
      state.three_phase_separators = projectInitialState.three_phase_separators;
      state.production_wells = projectInitialState.production_wells;
      state.injection_wells = projectInitialState.injection_wells;
      state.production_well_models = projectInitialState.production_well_models;
      state.injection_well_models = projectInitialState.injection_well_models;
      state.pumps = projectInitialState.pumps;
      state.sources = projectInitialState.sources;
      state.reservoir = projectInitialState.reservoir;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setNodes,
  setWells,
  setProductionWells,
  setInjectionWells,
  setInjectionWellModels,
  setProductionWellModels,
  setWellsModels,
  setUnconnectedWellsModels,
  setWellsControlModels,
  setWellsHydroModels,
  setGatheringCentersModels,
  setSegments,
  toggleIssues,
  setIssues,
  setPhysChemPackages,
  setGatheringCentersFactPressures,
  setProjectName,
  setProjectId,
  updateGatheringCentersModel,
  updateNodes,
  updateWell,
  updateWellModal,
  updateWellsControlModel,
  updateWellsHydroModel,
  updateSegments,
  updatePhysChemPackages,
  updateGatheringCentersFactPressures,
  resetProjectSliceState,
  setShowDetailsPanel,
  setPipesCatalog,
  updateNodesNetwork,
  updateSegmentsNetwork,
  addUpdatedModel,
  removeUpdatedModel,
  setSeparators,
  setClusters,
  setThreePhaseSeparators,
  setSinks,
  setPumps,
  setSources,
  setReservoir,
} = projectSlice.actions;

const selectSelf = (state: RootState) => state.project;

export const selectShowErrors = createSelector(
  selectSelf,
  project => project.issuesState.show,
);

export const selectErrors = createSelector(
  selectSelf,
  project => project.issuesState.issues,
);

export const selectShowDetailsPanel = createSelector(
  selectSelf,
  project => project.showDetailsPanel,
);

export const selectProject = createSelector(selectSelf, project => project);

export const selectProjectName = createSelector(
  selectSelf,
  project => project.name,
);

export const selectProjectId = createSelector(
  selectSelf,
  project => project.id,
);

export const selectSegments = createSelector(
  selectSelf,
  project => project.segments,
);

export const selectSegmentById = createSelector(selectSelf, project =>
  project.segments.items.reduce<SelectSegmentById>(composeObjectsById, {}),
);
export const selectNodes = createSelector(selectSelf, project => project.nodes);

export const selectNodeById = createSelector(selectSelf, project =>
  project.nodes.items.reduce<SelectNodeById>(composeObjectsById, {}),
);

export const selectWellById = createSelector(selectSelf, project =>
  project.wells.items.reduce<SelectWellById>(composeObjectsById, {}),
);

export const selectInjectionWellById = createSelector(selectSelf, project =>
  project.injection_wells.items.reduce<SelectInjectionWellById>(
    composeObjectsById,
    {},
  ),
);

export const selectProductionWellById = createSelector(selectSelf, project =>
  project.production_wells.items.reduce<SelectProductionWellById>(
    composeObjectsById,
    {},
  ),
);

export const selectInjectionWellModelById = createSelector(
  selectSelf,
  project =>
    project.injection_well_models.items.reduce<SelectInjectionWellModelById>(
      composeObjectsById,
      {},
    ),
);

export const selectProductionWellModelById = createSelector(
  selectSelf,
  project =>
    project.production_well_models.items.reduce<SelectProductionWellModelById>(
      composeObjectsById,
      {},
    ),
);

export const selectUnconnectedWellModelById = createSelector(
  selectSelf,
  project =>
    project.unconnectedWellsModels.reduce<SelectUnconnectedWellModelById>(
      composeObjectsById,
      {},
    ),
);

export const selectWellControlModelById = createSelector(selectSelf, project =>
  project.wellsControlModels.reduce<SelectWellControlModelById>(
    composeObjectsById,
    {},
  ),
);

export const selectWellHydroModelById = createSelector(selectSelf, project =>
  project.wellsHydroModels.reduce<SelectWellHydroModelById>(
    composeObjectsById,
    {},
  ),
);

export const selectWells = createSelector(selectSelf, project => project.wells);

export const selectSeparators = createSelector(
  selectSelf,
  project => project.separators,
);

export const selectClusters = createSelector(
  selectSelf,
  project => project.clusters,
);

export const selectWellModelById = createSelector(selectSelf, project =>
  project.wellsModels.items.reduce<SelectWellModelById>(composeObjectsById, {}),
);

export const selectUpdatedModel = createSelector(
  selectSelf,
  project => project.wellsModels.updatedModels,
);

export const selectPhysChemPackages = createSelector(
  selectSelf,
  project => project.physChemPackages,
);

export const selectPhysChemPackagesById = createSelector(selectSelf, project =>
  project.physChemPackages.reduce<SelectPhysChemPackageById>(
    composeObjectsById,
    {},
  ),
);

export const selectEquipmentById = createSelector(selectSelf, project =>
  project.separators.reduce<SelectSeparatorById>(composeObjectsById, {}),
);

export const selectThreePhaseSeparatorById = createSelector(
  selectSelf,
  project =>
    project.three_phase_separators.items.reduce<SelectThreePhaseSeparatorById>(
      composeObjectsById,
      {},
    ),
);

export const selectSinkById = createSelector(selectSelf, project =>
  project.sinks.items.reduce<SelectSinkById>(composeObjectsById, {}),
);

export const selectPumpById = createSelector(selectSelf, project =>
  project.pumps.items.reduce<SelectPumpById>(composeObjectsById, {}),
);

export const selectSourceById = createSelector(selectSelf, project =>
  project.sources.items.reduce<SelectSourceById>(composeObjectsById, {}),
);

export const selectReservoir = createSelector(
  selectSelf,
  project => project.reservoir,
);

export const selectGatheringCentersModelById = createSelector(
  selectSelf,
  project =>
    project.gatheringCentersModels.reduce<SelectGatheringCentersModelById>(
      composeObjectsById,
      {},
    ),
);

export const selectGatheringCentersFactPressuresById = createSelector(
  selectSelf,
  project =>
    project.gatheringCentersFactPressures.reduce<SelectGatheringCentersFactPressuresById>(
      composeObjectsById,
      {},
    ),
);

export const selectPipesCatalog = createSelector(
  selectSelf,
  project => project.pipesCatalog,
);

export default projectSlice.reducer;
