import * as React from 'react';
import { useSelector } from 'react-redux';

import classnames from 'classnames';
import { components } from 'generated/apiTypes';
import { selectSolution } from 'store/taskSlice';

import TableWrapper from '../../../../components/TableWrapper';
import { IThead } from '../../../../types';
import TableHead from './TableHead';
import TableRow from './TableRow';
import TemplateTableRow from './TemplateTableRow';
import { templateRowsNumber } from './utils';

export interface INodesTableProps {
  tableHead: IThead;
}

const EquipmentsTable: React.FC<INodesTableProps> = ({ tableHead }) => {
  const solution = useSelector(selectSolution)
    ?.solution as components['schemas']['PNOReservoirBaseProblem'];
  const [data, setData] = React.useState<any[]>([]);
  const headKeys = Object.keys(tableHead);

  const getData = () => {
    const newData: any[] = [];
    solution?.solution?.pump_states?.forEach(state => {
      newData.push({
        name: state.pump_name,
        type: 'Насос',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        in_oil_flow_rate: state.in_oil_flow__t_per_day,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        in_fluid_flow_rate: state.in_fluid_flow__m3_per_day,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        in_gas_flow_rate: state.in_gas_flow__m3_per_day / 1000,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        out_oil_flow_rate: state.out_oil_flow__t_per_day,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        out_fluid_flow_rate: state.out_fluid_flow__m3_per_day,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        out_gas_flow_rate: state.out_gas_flow__m3_per_day / 1000,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        in_pressure: state.in_pressure__atm,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        out_pressure: state.out_pressure__atm,
      });
    });
    solution?.solution?.three_phase_separator_states?.forEach(state => {
      newData.push({
        name: state.separator_name,
        type: 'Сепаратор',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        in_oil_flow_rate: state.in_oil_flow__t_per_day,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        in_fluid_flow_rate: state.in_fluid_flow__m3_per_day,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        in_gas_flow_rate: state.in_gas_flow__m3_per_day / 1000,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        out_oil_flow_rate: state.out_oil_flow__t_per_day,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        out_fluid_flow_rate: state.out_fluid_flow__m3_per_day,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        out_gas_flow_rate: state.out_gas_flow__m3_per_day / 1000,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        in_pressure: state.in_pressure__atm,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        out_pressure: state.out_pressure__atm,
      });
    });
    setData(newData);
  };

  React.useEffect(() => {
    getData();
  }, [solution]);

  return (
    <TableWrapper vertical horizontal smoothScrolling>
      <table className={classnames('table', 'table__nodes-table')}>
        <TableHead headKeys={headKeys} tableHead={tableHead} />
        <tbody>
          {data?.length > 0
            ? data.map((value: any, index: number) => {
                return <TableRow key={index} headKeys={headKeys} el={value} />;
              })
            : Array.from({ length: templateRowsNumber }, (item, index) => (
                <TemplateTableRow key={index} headKeys={headKeys} />
              ))}
        </tbody>
      </table>
    </TableWrapper>
  );
};

export default EquipmentsTable;
