import React from 'react';
import { useSelector } from 'react-redux';

import { selectSolution } from 'store/taskSlice';

import MOResults from './MO';
import UAResults from './UA';

const SeparatorsResults: React.FC = () => {
  const experimentType = useSelector(selectSolution)?.type;
  if (experimentType === 'Базовый режим') return <UAResults />;
  if (experimentType === 'Оптимизация режима') return <MOResults />;
  return <></>;
};

export default SeparatorsResults;
