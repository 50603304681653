import * as React from 'react';
import { useSelector } from 'react-redux';

import classnames from 'classnames';
import { components } from 'generated/apiTypes';
import { selectSeparatorsSolution } from 'store/taskSlice';

import TableHead from '../../../components/TableHead';
import TableWrapper from '../../../components/TableWrapper';
import { ITableProps } from '../UA/AvgTable';
import TemplateTableRow from '../UA/TemplateTableRow';
import { templateRowsNumber } from '../UA/utils';
import TableRow from './TableRow';
import { separatorsTableItems } from './utils';

const SourcesAvgTable: React.FC<ITableProps> = ({ tableHead }) => {
  const avgTable = useSelector(selectSeparatorsSolution);
  const [data, setData] = React.useState<any[]>([]);
  const headKeys = Object.keys(tableHead);

  const getData = () => {
    const newData: any = [];
    (
      avgTable?.solution as components['schemas']['GetTechModeOptimizationSolutionSeparatorAverageStateQueryResult'][]
    ).forEach(item => {
      newData.push(separatorsTableItems(item));
    });
    setData(newData);
  };

  React.useEffect(() => {
    getData();
  }, [avgTable]);

  return (
    <TableWrapper vertical horizontal smoothScrolling>
      <table className={classnames('table', 'table__sources-table')}>
        <TableHead headKeys={headKeys} tableHead={tableHead} />
        <tbody>
          {data?.length > 0
            ? data.map((value: any, index: number) => {
                return <TableRow key={index} headKeys={headKeys} el={value} />;
              })
            : Array.from({ length: templateRowsNumber }, (item, index) => (
                <TemplateTableRow key={index} headKeys={headKeys} />
              ))}
        </tbody>
      </table>
    </TableWrapper>
  );
};

export default SourcesAvgTable;
