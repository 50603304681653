import * as React from 'react';

export const templateRowsNumber = 5;

export const wellfieldsTableHead = {
  current_state: 'Статус',
  well_name: 'Название',
  operation_type: {
    title: 'Способ эксплуатации',
    component: (
      <>
        Способ <br /> эксплуатации
      </>
    ),
  },
  control_type: {
    title: 'Режим работы',
    component: (
      <>
        Режим <br /> работы
      </>
    ),
  },
  offset_time: {
    title: 'Тек. оффсет',
    component: <>Оффсет, мин</>,
  },

  oil_flow_rate: {
    title: 'Дебит нефти',
    component: (
      <>
        Дебит нефти,
        <br /> т/сут
      </>
    ),
  },
  fluid_flow_rate: {
    title: 'Дебит жидкости',
    component: (
      <>
        Дебит жидкости,
        <br /> м3/сут
      </>
    ),
  },
  gas_flow_rate: {
    title: 'Дебит газа',
    component: (
      <>
        Дебит газа,
        <br /> тыс. м3/сут
      </>
    ),
  },
  pressure: {
    title: 'Лин. давление',
    component: (
      <>
        Лин. давление, <br /> атм
      </>
    ),
  },
  control_freq: {
    title: 'Управляющее воздействие',
    component: <>Частота работы, Гц</>,
  },
  accumulation_freq: {
    title: 'Частота накопления',
    component: (
      <>
        Частота <br />
        накопления, Гц
      </>
    ),
  },
  work_time: {
    title: 'Длит. интервала работы',
    component: (
      <>
        Длит. интервала <br />
        работы, мин
      </>
    ),
  },
  accumulation_time: {
    title: 'Длит. интервала накопления',
    component: (
      <>
        Длит. интервала <br />
        накопления, мин
      </>
    ),
  },
  control_flow: {
    title: 'Управляющее воздействие',
    component: (
      <>
        Расход АГ, <br /> тыс. м3/сут
      </>
    ),
  },
  power: {
    title: 'Мощность',
    component: <>Мощность, кВт</>,
  },
  interval: {
    title: 'Интервал работы',
    component: (
      <>
        Интервал работы, <br /> мин
      </>
    ),
  },
};

export const wellsTableItems = ({
  well_name,
  operation_type,
  control_type,
  current_state,
  compare_offset_time,
  compare_oil_flow_rate,
  compare_fluid_flow_rate,
  compare_gas_flow_rate,
  compare_pressure,
  compare_control,
  compare_power,
  compare_work_time,
  compare_accumulation_time,
  compare_accumulation_control,
}: any = {}) => [
  {
    dem: 'current_state',
    value: current_state,
  },
  {
    dem: 'well_name',
    value: well_name,
  },
  {
    dem: 'operation_type',
    value: operation_type,
  },
  {
    dem: 'control_type',
    value: control_type,
  },
  {
    dem: 'offset_time',
    value:
      compare_offset_time?.fact !== undefined
        ? compare_offset_time.fact
        : undefined,
    abs: compare_offset_time?.abs ? compare_offset_time.abs : undefined,
    unit: 'мин',
  },
  {
    dem: 'oil_flow_rate',
    value: compare_oil_flow_rate?.fact?.t_per_day,
    abs: compare_oil_flow_rate?.abs?.t_per_day,
    rel: compare_oil_flow_rate?.rel,
    unit: 'т/сут',
  },
  {
    dem: 'fluid_flow_rate',
    value: compare_fluid_flow_rate?.fact?.m3_per_day,
    abs: compare_fluid_flow_rate?.abs?.m3_per_day,
    rel: compare_fluid_flow_rate?.rel,
    unit: 'м3/сут',
  },
  {
    dem: 'gas_flow_rate',
    value: compare_gas_flow_rate?.fact?.thousand_m3_per_day,
    abs: compare_gas_flow_rate?.abs?.thousand_m3_per_day,
    rel: compare_gas_flow_rate?.rel,
    unit: 'тыс. м3/сут',
  },
  {
    dem: 'pressure',
    value: compare_pressure?.fact?.atm,
    abs: compare_pressure?.abs?.atm,
    rel: compare_pressure?.rel,
    unit: 'атм',
  },
  {
    dem: 'control_freq',
    value:
      operation_type === 'ЭЦН' && compare_control?.fact?.hertz !== undefined
        ? compare_control.fact.hertz
        : undefined,
    abs: operation_type === 'ЭЦН' && compare_control?.abs?.hertz,
    rel: operation_type === 'ЭЦН' && compare_control?.rel,
    unit: 'Гц',
  },
  {
    dem: 'accumulation_freq',
    value:
      compare_accumulation_control?.fact?.hertz !== undefined
        ? compare_accumulation_control.fact.hertz
        : undefined,
    abs:
      compare_accumulation_control?.abs?.hertz !== undefined
        ? compare_accumulation_control.abs.hertz
        : undefined,
    rel: compare_accumulation_control?.rel,
    unit: 'Гц',
  },
  {
    dem: 'work_time',
    value: compare_work_time?.fact,
    abs: compare_work_time?.abs,
    rel: compare_work_time?.rel,
    unit: 'мин',
  },
  {
    dem: 'accumulation_time',
    value: compare_accumulation_time?.fact,
    abs: compare_accumulation_time?.abs,
    rel: compare_accumulation_time?.rel,
    unit: 'мин',
  },
  {
    dem: 'control_flow',
    value:
      operation_type === 'Газлифт' &&
      compare_control?.fact?.thousand_m3_per_day !== undefined
        ? compare_control.fact.thousand_m3_per_day
        : undefined,
    abs:
      operation_type === 'Газлифт' && compare_control?.abs?.thousand_m3_per_day,
    rel: operation_type === 'Газлифт' && compare_control?.rel,
    unit: 'тыс. м3/сут',
  },
  {
    dem: 'power',
    value:
      operation_type === 'ЭЦН' && compare_power?.fact?.kW !== undefined
        ? compare_power.fact.kW
        : undefined,
    abs: operation_type === 'ЭЦН' && compare_power?.abs?.kW,
    rel: operation_type === 'ЭЦН' && compare_power?.rel,
    unit: 'кВт',
  },
  {
    dem: 'control',
    value:
      operation_type === 'Время' && compare_control?.fact?.min !== undefined
        ? compare_control.fact.min
        : undefined,
    abs: operation_type === 'Время' && compare_control?.abs?.min,
    rel: operation_type === 'Время' && compare_control?.rel,
    unit: 'мин',
  },
];
