import { useContext, useEffect, useRef, useState } from 'react';
import { MapContext } from 'react-mapbox-gl';

interface AddSourceProps {
  features: any;
  empty?: boolean;
  hovered?: boolean;
  selected?: boolean;
  layer_name?: string;
  source_id: string;
  source_type: 'geojson';
  layer_type: 'symbol' | 'circle' | 'fill' | 'line';
  layer_paint?: any;
  layer_layout?: any;
  getColor?: any;
  update?: boolean;
  before?: string;
  handleMouseEnter?: () => void;
  handleMouseUp?: () => void;
  handleMouseLeave?: () => void;
  handleMouseMove?: (e: any) => void;
  handleMouseDown?: (e: any) => void;
  handleMouseClick?: (e: any) => void;
  handleMouseTouchStart?: (e: any) => void;
  handleMouseClickOnce?: () => void;
  setIsLoaded?: (e: any) => void;
  setUpdate?: (e: any) => void;
}

function AddSource(props: AddSourceProps) {
  const mapContext = useContext(MapContext);
  const sourceId = useRef(null);
  const [init, setInit] = useState(false);

  useEffect(() => {
    if ((props.features.length || props.empty) && (!init || props.update)) {
      const layer = mapContext?.getLayer(
        props.layer_name ? props.layer_name : props.source_id,
      );
      if (layer)
        mapContext?.removeLayer(
          props.layer_name ? props.layer_name : props.source_id,
        );
      if (props.hovered) {
        const hoveredLayer = mapContext?.getLayer(
          props.layer_name
            ? `${props.layer_name}_hovered`
            : `${props.source_id}_hovered`,
        );

        if (hoveredLayer)
          mapContext?.removeLayer(
            props.layer_name
              ? `${props.layer_name}_hovered`
              : `${props.source_id}_hovered`,
          );
      }

      if (props.selected) {
        const hoveredLayer = mapContext?.getLayer(
          props.layer_name
            ? `${props.layer_name}_selected`
            : `${props.source_id}_selected`,
        );

        if (hoveredLayer)
          mapContext?.removeLayer(
            props.layer_name
              ? `${props.layer_name}_selected`
              : `${props.source_id}_selected`,
          );
      }

      const source = mapContext?.getSource(props.source_id);

      if (source) mapContext?.removeSource(props.source_id);

      mapContext?.addSource(props.source_id, {
        type: props.source_type,
        data: {
          type: 'FeatureCollection',
          features: props.features ? props.features : [],
        },
      });
      // Add a new layer to visualize the polygon.
      let layerPaint: any;

      if (props.layer_paint) {
        layerPaint = props.layer_paint;
        if (props.getColor) {
          layerPaint['line-color'] = props.getColor;
        }
      } else {
        layerPaint = {};
      }

      mapContext?.addLayer(
        {
          id: props.layer_name ? props.layer_name : props.source_id,
          minzoom: 5,
          type: props.layer_type,
          source: props.source_id, // reference the data source
          layout: props.layer_layout ? props.layer_layout : {},
          paint: layerPaint,
        },
        props.before,
      );

      props.handleMouseEnter &&
        mapContext?.on(
          'mouseenter',
          props.layer_name ? props.layer_name : props.source_id,
          props.handleMouseEnter,
        );
      props.handleMouseUp &&
        mapContext?.on(
          'mouseup',
          props.layer_name ? props.layer_name : props.source_id,
          props.handleMouseUp,
        );
      props.handleMouseLeave &&
        mapContext?.on(
          'mouseleave',
          props.layer_name ? props.layer_name : props.source_id,
          props.handleMouseLeave,
        );
      props.handleMouseMove &&
        mapContext?.on(
          'mousemove',
          props.layer_name ? props.layer_name : props.source_id,
          props.handleMouseMove,
        );
      props.handleMouseDown &&
        mapContext?.on(
          'mousedown',
          props.layer_name ? props.layer_name : props.source_id,
          props.handleMouseDown,
        );
      props.handleMouseClick &&
        mapContext?.on(
          'click',
          props.layer_name ? props.layer_name : props.source_id,
          props.handleMouseClick,
        );
      props.handleMouseTouchStart &&
        mapContext?.on(
          'touchstart',
          props.layer_name ? props.layer_name : props.source_id,
          props.handleMouseTouchStart,
        );
      props.handleMouseClickOnce &&
        mapContext?.once(
          'click',
          props.layer_name ? props.layer_name : props.source_id,
          props.handleMouseClickOnce,
        );

      props.setIsLoaded && props.setIsLoaded(true);
      // props.update && props.setUpdate(false);
      setInit(true);
    }
  }, [props.features.length, props.update]);

  return null;
}

export default AddSource;
