import React from 'react';

import classnames from 'classnames';

import { twoDigitsFormatter } from 'utils';
import { ITableRowProps } from '../../../types';
import { isNumber } from '../../../utils';

const TableRow: React.FC<ITableRowProps> = ({ el, headKeys }) => {
  const formatValue = (key: string, value: string) => {
    return twoDigitsFormatter.format(parseFloat(parseFloat(value).toFixed(2)));
  };

  const renderField = (key: string, value: any) => {
    return isNumber(value) ? formatValue(key, value) : value;
  };
  return (
    <tr className={classnames('table-row')}>
      {headKeys.map((key: string) => (
        <td
          className={classnames(
            'table-data',
            ['well_name'].includes(key) && 'left',
          )}
          key={key}
        >
          {renderField(key, el[key])}
        </td>
      ))}
    </tr>
  );
};

export default TableRow;
