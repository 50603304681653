import * as React from 'react';
import { useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { components } from 'generated/apiTypes';
import StepInput from 'Pages/Project/ResultsPanel/components/StepInput';
import { selectSolution } from 'store/taskSlice';

import { nodesTableHead } from '../../../Nodes/UA/Table/utils';
import SinksAvgTable from './AvgTable';
import SinksByStepTable from './ByStepTable';

const Table: React.FC = () => {
  const solution = useSelector(selectSolution)
    ?.solution as components['schemas']['PNOPeriodicNodalAnalysisProblem'];
  const initStep = '0.0';
  const [tabIndex, setTabIndex] = React.useState<number>(0);
  const [step, setStep] = React.useState<string>(initStep);
  const getSinks = () => {
    return solution?.task?.pipeline_network?.nodes
      ?.filter(node => node.type === 'SINK')
      .map(node => node.uid);
  };
  const [sinksUidList, setSinksUidList] = React.useState<
    (string | undefined)[]
  >(getSinks());
  React.useEffect(() => {
    setSinksUidList(getSinks());
  }, [solution]);
  return (
    <>
      <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
        <TabList className="results__tab-list tables-tab-list">
          <div className="results__table-tabs">
            <Tab className="results__tab">Средние</Tab>
            <Tab className="results__tab">По шагам</Tab>
          </div>
          <StepInput
            value={step}
            setStep={setStep}
            tabIndex={tabIndex}
            initStep={initStep}
            max={
              solution?.solution?.well_step_table?.time2states
                ? Object.keys(solution?.solution?.well_step_table?.time2states)[
                    Object.entries(
                      solution?.solution?.well_step_table?.time2states,
                    )?.length - 1
                  ]?.toString()
                : '0.0'
            }
            time2states={solution?.solution?.node_step_table?.time2states}
          />
        </TabList>
        <div>
          <TabPanel>
            <SinksAvgTable tableHead={nodesTableHead} sinksUID={sinksUidList} />
          </TabPanel>
          <TabPanel>
            <SinksByStepTable
              tableHead={nodesTableHead}
              keyD={step}
              sinksUID={sinksUidList}
            />
          </TabPanel>
        </div>
      </Tabs>
    </>
  );
};

export default Table;
