import * as React from 'react';

import { threeDigitsFormatter, twoDigitsFormatter } from 'utils';

export const templateRowsNumber = 5;

export const nodesTableHead = {
  name: 'Название',
  oil_flow_rate: {
    title: 'Расход нефти',
    component: (
      <>
        Расход нефти,
        <br /> т/сут
      </>
    ),
  },
  fluid_flow_rate: {
    title: 'Расход жидкости',
    component: (
      <>
        Расход жидкости,
        <br /> м3/сут
      </>
    ),
  },
  gas_flow_rate: {
    title: 'Расход газа',
    component: (
      <>
        Расход газа, <br />
        тыс.м3/сут
      </>
    ),
  },
  pressure: {
    title: 'Давление',
    component: (
      <>
        Давление, <br />
        атм
      </>
    ),
  },
  temperature: {
    title: 'Температура',
    component: (
      <>
        Температура,
        <br />
        ˚C
      </>
    ),
  },
};

export const nodesTableItems = ({
  node_name,
  compare_oil_flow_rate,
  compare_fluid_flow_rate,
  compare_gas_flow_rate,
  compare_pressure,
  compare_temperature,
}: any = {}) => [
  {
    dem: 'name',
    value: node_name,
  },
  {
    dem: 'oil_flow_rate',
    value: compare_oil_flow_rate?.fact && compare_oil_flow_rate.fact.t_per_day,
    abs: compare_oil_flow_rate?.abs?.t_per_day,
    rel: compare_oil_flow_rate?.rel,
    unit: 'т/сут',
  },
  {
    dem: 'fluid_flow_rate',
    value:
      compare_fluid_flow_rate?.fact && compare_fluid_flow_rate.fact.m3_per_day,
    abs: compare_fluid_flow_rate?.abs?.m3_per_day,
    rel: compare_fluid_flow_rate?.rel,
    unit: 'м3/сут',
  },
  {
    dem: 'gas_flow_rate',
    value:
      compare_gas_flow_rate?.fact &&
      compare_gas_flow_rate.fact.thousand_m3_per_day,
    abs: compare_gas_flow_rate?.abs?.thousand_m3_per_day,
    rel: compare_gas_flow_rate?.rel,
    unit: 'тыс. м3/сут',
  },
  {
    dem: 'pressure',
    value: compare_pressure?.fact && compare_pressure.fact.atm,
    abs: compare_pressure?.abs?.atm,
    rel: compare_pressure?.rel,
    unit: 'атм',
  },
  {
    dem: 'temperature',
    value: compare_temperature?.fact && compare_temperature.fact.celsius,
    abs: compare_temperature?.abs?.celsius,
    rel: compare_temperature?.rel,
    unit: '℃',
  },
];
