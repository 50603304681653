import React from 'react';

import classnames from 'classnames';

import { ITableRowProps } from '../../../types';
import { formatFloat, isNumber, prepareTableValue } from '../../../utils';

const TableRow: React.FC<ITableRowProps> = ({ el }) => {
  const renderField = (item: any) => {
    return (
      <div>
        <p className={classnames('compare-field', 'value')}>
          {isNumber(item.value) ? formatFloat(item.value, false) : item.value}
        </p>
        {!['separator_name'].includes(item.dem) && !!item.value && (
          <div className={classnames('compare-field', 'compare')}>
            <p>
              <text>
                {item.abs !== undefined && prepareTableValue(item.abs)}
              </text>
              <text>{item.abs !== undefined && item.unit}</text>
            </p>
            <p>
              <text>
                {item.rel !== undefined && prepareTableValue(item.rel)}
              </text>
              <text>{item.rel !== undefined && '%'}</text>
            </p>
          </div>
        )}
      </div>
    );
  };

  return (
    <tr className={classnames('table-row')}>
      {el.map((item: any) => (
        <td
          className={classnames(
            'table-data',
            ['separator_name'].includes(item.dem) && 'left',
            !['separator_name'].includes(item.dem) && 'width-150',
          )}
          key={item.dem}
        >
          {renderField(item)}
        </td>
      ))}
    </tr>
  );
};

export default TableRow;
