import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import {
  selectExperimentSettings,
  selectExperimentStatus,
} from 'store/taskSlice';

import { components } from '../../../generated/apiTypes';
import { setExperimentSettings } from '../../../services/apiRequests';
import { selectReservoir, selectWells } from '../../../store/projectSlice';
import { setExperimentSettings as setStoreExperimentSettings } from '../../../store/taskSlice';
import useGetExperimentSettings from './hooks/use-get-experiment-settings';
import TaskSettingsInputRow from './TaskSettingInputRow';

type SettingsType = components['schemas']['ExperimentSettings'];

interface IPNASettingsProps {
  setExperimentSettingsSaved(val: boolean): void;
}

const PNASettings: React.FC<IPNASettingsProps> = ({
  setExperimentSettingsSaved,
}) => {
  const params = useParams() as { id: string };
  const settings = useSelector(selectExperimentSettings);
  const status = useSelector(selectExperimentStatus);
  const reservoir = useSelector(selectReservoir);
  const wells = useSelector(selectWells);
  const [settingsState, setSettingsState] = React.useState<SettingsType>();
  const dispatch = useDispatch();

  useGetExperimentSettings(params.id);

  React.useEffect(() => {
    setSettingsState(settings);
  }, [settings]);

  const saveNewValue = async (value: number, name: string) => {
    dispatch(
      setStoreExperimentSettings({
        ...(settingsState as SettingsType),
        [name]: value,
      }),
    );
    setSettingsState({
      ...(settingsState as SettingsType),
      [name]: value,
    });
    setExperimentSettingsSaved(false);
    await setExperimentSettings(params.id, {
      ...(settingsState as SettingsType),
      [name]: value,
    });
    setExperimentSettingsSaved(true);
  };

  return (
    <>
      {!reservoir?.uid && wells?.items.length > 0 && (
        <div className="project-task-settings__row">
          <TaskSettingsInputRow
            label="Длительность"
            value={settingsState?.period}
            unit="мин"
            saveNewValue={saveNewValue}
            name="period"
            disabled={[
              'DRAFT',
              'SENT',
              'PREPARING',
              'PREPARED',
              'SOLVING',
            ].includes(status)}
          />
          <TaskSettingsInputRow
            label="Шаг"
            value={settingsState?.time_step}
            unit="мин"
            saveNewValue={saveNewValue}
            name="time_step"
            disabled={[
              'DRAFT',
              'SENT',
              'PREPARING',
              'PREPARED',
              'SOLVING',
            ].includes(status)}
          />
        </div>
      )}
      <div className="project-task-settings__row">
        <TaskSettingsInputRow
          label="Точность расчетов"
          value={settingsState?.accuracy}
          unit="aтм"
          saveNewValue={saveNewValue}
          name="accuracy"
          disabled={[
            'DRAFT',
            'SENT',
            'PREPARING',
            'PREPARED',
            'SOLVING',
          ].includes(status)}
        />
      </div>
    </>
  );
};

export default PNASettings;
