import * as React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { ReactComponent as ChartIcon } from 'images/Project/icn-line-chart.svg';
import { ReactComponent as TableIcon } from 'images/Project/icn-table.svg';

import Table from '../Table';

const Loading: React.FC = () => {
  const [tabIndex, setTabIndex] = React.useState<number>(1);
  return (
    <>
      <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
        <div className="well-model-panel__tab-list-wrapper">
          <TabList className="results__tab-list">
            <Tab className="results__icon" disabled>
              <ChartIcon />
            </Tab>
            <Tab className="results__icon">
              <TableIcon />
            </Tab>
          </TabList>
        </div>
        <div>
          <TabPanel />
          <TabPanel>
            <Table
              wellOperationType="ЭЦН"
              tableType="Loading"
              tableState="noModel"
              setTableState={() => {}}
            />
          </TabPanel>
        </div>
      </Tabs>
    </>
  );
};

export default Loading;
