import * as React from 'react';
import { useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { selectSeparators } from 'store/projectSlice';

import Nodes from '../Nodes';
import Segments from '../Segments';
import Separators from '../Separators';
import Sink from '../Sink';
import Sources from '../Sources';

const UAResults: React.FC = () => {
  const [tabIndex, setTabIndex] = React.useState(0);
  const separators = useSelector(selectSeparators);

  return (
    <Tabs
      className="results__tabs"
      selectedIndex={tabIndex}
      onSelect={index => setTabIndex(index)}
    >
      <TabList className="results__tab-list underline">
        <Tab className="results__tab">Центр сбора</Tab>
        <Tab className="results__tab">Скважины</Tab>
        <Tab className="results__tab">Узлы</Tab>
        <Tab className="results__tab">Трубы</Tab>
        {separators.length > 0 && (
          <Tab className="results__tab">Сепараторы</Tab>
        )}
      </TabList>
      <div className="results-tables__container">
        <TabPanel>
          <Sink />
        </TabPanel>
        <TabPanel>
          <Sources />
        </TabPanel>
        <TabPanel>
          <Nodes />
        </TabPanel>
        <TabPanel>
          <Segments />
        </TabPanel>
        {separators.length > 0 && (
          <TabPanel>
            <Separators />
          </TabPanel>
        )}
      </div>
    </Tabs>
  );
};

export default UAResults;
