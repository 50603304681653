import * as React from 'react';
import { useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { selectActiveObject } from '../../../../store/mapSlice';
import { selectWellById } from '../../../../store/projectSlice';
import { getWellOperationType } from '../../utils';
import Amperage from './Amperage';
import BottomholePressures from './BottomHolePressures';
import FlowRates from './FlowRates';
import Loading from './Loading';
import Power from './Power';

const WellModel: React.FC = () => {
  const [tabIndex, setTabIndex] = React.useState(0);
  const activeObject = useSelector(selectActiveObject);
  const wellsById = useSelector(selectWellById);

  const operationType = getWellOperationType(
    wellsById[activeObject.object_uid as string]?.type,
  );

  return (
    <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
      <TabList className="results__tab-list underline">
        <Tab className="results__tab">Дебит жидкости</Tab>
        {operationType !== 'Время' && (
          <>
            {' '}
            <Tab className="results__tab">Загрузка</Tab>
            <Tab className="results__tab">Сила тока</Tab>
            <Tab className="results__tab">Мощность</Tab>{' '}
          </>
        )}
        <Tab className="results__tab">Забойные давления</Tab>
      </TabList>
      <div className="results-tables__container">
        <TabPanel>
          <FlowRates />
        </TabPanel>
        {operationType !== 'Время' && (
          <>
            <TabPanel>
              <Loading />
            </TabPanel>
            <TabPanel>
              <Amperage />
            </TabPanel>
            <TabPanel>
              <Power />
            </TabPanel>
          </>
        )}
        <TabPanel>
          <BottomholePressures />
        </TabPanel>
      </div>
    </Tabs>
  );
};

export default WellModel;
