export const layerProps = {
  'icon-size': ['interpolate', ['linear'], ['zoom'], 10, 0.1, 16, 1],
  'text-field': ['get', 'name'],
  'text-font': ['Roboto Regular'],
  'text-size': 10,
  // 'text-allow-overlap': true,
  'icon-allow-overlap': true,
  'text-optional': true,
  'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
  'text-justify': 'auto',
};

export const linePaint: mapboxgl.LinePaint = {
  'line-color': '#D7DDEA',
  'line-width': 3,
};

export const separatorPaint: mapboxgl.LinePaint = {
  'line-color': '#A978CF',
  'line-width': 3,
};

export const lineDraw: mapboxgl.LinePaint = {
  'line-color': '#FDB0D5',
  'line-width': 3,
};

export const selectedLinePaint: mapboxgl.LinePaint = {
  'line-color': '#FF007A',
  'line-width': 3,
};

export const loopingsLinePaint: mapboxgl.LinePaint = {
  'line-color': '#AFB2CD',
  'line-width': 3,
  'line-offset': 5,
};

export const colorsLinePaint: mapboxgl.LinePaint = {
  'line-width': 3,
};

export const hoverLinePaint: mapboxgl.LinePaint = {
  'line-width': 3,
  'line-color': '#FF007A',
  'line-opacity': [
    'case',
    ['boolean', ['feature-state', 'hover'], false],
    1,
    0,
  ],
};
