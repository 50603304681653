import * as React from 'react';
import { useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { components } from 'generated/apiTypes';

import EquipmentsTable from './AvgTable';
import { equipmentsTableHead } from './utils';

const Table: React.FC = () => {
  return <EquipmentsTable tableHead={equipmentsTableHead} />;
};

export default Table;
