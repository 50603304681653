import * as React from 'react';

export const templateRowsNumber = 5;

export const wellfieldsTableHead = {
  current_state: 'Статус',
  well_name: 'Название',
  operation_type: {
    title: 'Способ эксплуатации',
    component: (
      <>
        Способ <br /> эксплуатации
      </>
    ),
  },
  oil_flow_rate: {
    title: 'Дебит нефти',
    component: (
      <>
        Дебит нефти, <br /> т/сут
      </>
    ),
  },
  fluid_flow_rate: {
    title: 'Дебит жидкости',
    component: (
      <>
        Дебит жидкости, <br /> м<span className="sup">3</span>/сут
      </>
    ),
  },
  gas_flow_rate: {
    title: 'Дебит газа',
    component: (
      <>
        Дебит газа,
        <br />
        тыс.м<span className="sup">3</span>/сут
      </>
    ),
  },
  pressure: {
    title: 'Линейное давление',
    component: (
      <>
        Линейное давление,
        <br /> атм
      </>
    ),
  },
  bottomhole_pressure: {
    title: 'Забойное давление',
    component: (
      <>
        Забойное давление,
        <br /> атм
      </>
    ),
  },
  frequency: {
    title: 'Частота работы',
    component: (
      <>
        Частота работы,
        <br />
        Гц
      </>
    ),
  },
  diameter: {
    title: 'Диаметр штуцера',
    component: (
      <>
        Диаметр штуцера,
        <br />
        мм
      </>
    ),
  },
};

export const wellsTableItems = ({
  well_name,
  operation_type,
  current_state,
  oil_flow_rate,
  oil_flow_rate_mass_abs,
  oil_flow_rate_mass_rel,
  fluid_flow_rate,
  fluid_flow_rate_volume_abs,
  fluid_flow_rate_volume_rel,
  gas_flow_rate,
  gas_flow_rate_abs,
  gas_flow_rate_rel,
  pressure,
  pressure_abs,
  pressure_rel,
  bottomhole_pressure,
  bottomhole_pressure_abs,
  bottomhole_pressure_rel,
  control,
  control_abs,
  control_rel,
}: any = {}) => [
  {
    dem: 'current_state',
    value: current_state,
  },
  {
    dem: 'well_name',
    value: well_name,
  },
  {
    dem: 'operation_type',
    value: operation_type,
  },
  {
    dem: 'oil_flow_rate',
    value: oil_flow_rate?.t_per_day,
    abs: oil_flow_rate_mass_abs?.t_per_day,
    rel: oil_flow_rate_mass_rel,
    unit: 'т/сут',
  },
  {
    dem: 'fluid_flow_rate',
    value: fluid_flow_rate?.m3_per_day,
    abs: fluid_flow_rate_volume_abs?.m3_per_day,
    rel: fluid_flow_rate_volume_rel,
    unit: (
      <>
        м<span className="sup">3</span>/сут
      </>
    ),
  },
  {
    dem: 'gas_flow_rate',
    value: gas_flow_rate?.thousand_m3_per_day,
    abs: gas_flow_rate_abs?.thousand_m3_per_day,
    rel: gas_flow_rate_rel,
    unit: (
      <>
        тыс. м<span className="sup">3</span>/сут
      </>
    ),
  },
  {
    dem: 'pressure',
    value: pressure?.atm,
    abs: pressure_abs?.atm,
    rel: pressure_rel,
    unit: 'атм',
  },
  {
    dem: 'bottomhole_pressure',
    value: bottomhole_pressure.atm,
    abs: bottomhole_pressure_abs?.atm,
    rel: bottomhole_pressure_rel,
    unit: 'атм',
  },
  {
    dem: 'frequency',
    value:
      operation_type === 'Добывающая' && control?.hertz !== undefined
        ? control?.hertz
        : undefined,
    abs: operation_type === 'Добывающая' && control_abs?.hertz,
    rel: operation_type === 'Добывающая' && control_rel,
    unit: 'Гц',
  },
  {
    dem: 'diameter',
    value:
      operation_type === 'Нагнетательная' && control?.mm !== undefined
        ? control?.mm
        : undefined,
    abs: operation_type === 'Нагнетательная' && control_abs?.mm,
    rel: operation_type === 'Нагнетательная' && control_rel,
    unit: 'мм',
  },
];
