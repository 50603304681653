import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import update from 'immutability-helper';
import { selectActiveObject } from 'store/mapSlice';
import {
  addUpdatedModel,
  selectUpdatedModel,
  selectWellModelById,
  updateWellModal,
} from 'store/projectSlice';

import { oneDigitsFormatter } from '../../../../../utils';
import { ChartType } from '../types';
import { getParameterByType, getUnitByType } from './utils';

interface IParameterInput {
  initValue: string;
  pressure_index: number;
  control_action_index: number;
  tableType: ChartType;
}

const ParameterInput: React.FC<IParameterInput> = ({
  initValue,
  pressure_index,
  control_action_index,
  tableType,
}) => {
  const dispatch = useDispatch();
  const wellsModelsById = useSelector(selectWellModelById);
  const activeObject = useSelector(selectActiveObject);
  const updatedModel = useSelector(selectUpdatedModel);
  const wellModel = wellsModelsById[activeObject.object_uid as string];
  const [value, setValue] = React.useState<string | undefined>(undefined);
  const ref = React.useRef<HTMLInputElement | null>(null);

  React.useEffect(() => {
    setValue(initValue);
  }, [initValue]);

  const changeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const valueWithoutSpaces = e.target.value.replace(/\s/g, '');
    const digitOnly = new RegExp('^[-]?([0-9]*)*[.,]?([0-9]{0,8})?$', 'g');
    if (valueWithoutSpaces.match(digitOnly)) {
      setValue(e.target.value);
    }
  };

  const onKeyDownHandler = (e: React.KeyboardEvent) => {
    const { key } = e;
    if (['Delete', 'Backspace'].includes(key)) {
      e.stopPropagation();
    }
    if (['Enter', 'Escape'].includes(key)) {
      e.stopPropagation();
      ref?.current?.blur();
    }
  };

  const onBlur = () => {
    const commaToDotValue = value?.replace(',', '.');
    const valueWithoutSpaces = commaToDotValue?.replace(/\s/g, '');
    if (valueWithoutSpaces) {
      const newValue = parseFloat(valueWithoutSpaces);
      if (newValue) {
        setValue(oneDigitsFormatter.format(newValue));
        if (!updatedModel.includes(activeObject.object_uid as string)) {
          dispatch(addUpdatedModel(activeObject.object_uid as string));
        }
        dispatch(
          updateWellModal(
            update(wellModel, {
              model: {
                [getParameterByType(tableType)]: {
                  [control_action_index]: {
                    [pressure_index]: {
                      $set: newValue,
                    },
                  },
                },
              },
            }),
          ),
        );
      }
    }
  };

  return (
    <input
      ref={ref}
      className="well-model-panel__parameter-input"
      value={value}
      onChange={changeValue}
      onKeyDown={onKeyDownHandler}
      onBlur={onBlur}
    />
  );
};

export default ParameterInput;
