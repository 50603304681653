import * as React from 'react';
import { useSelector } from 'react-redux';

import classNames from 'classnames';
import { components } from 'generated/apiTypes';
import {
  selectExperimentSettings,
  selectIntegralCharacteristics,
} from 'store/taskSlice';

import { dashboardItems } from './utils';

const UACalculationInfoDashboard: React.FC = () => {
  const integralCharacteristic = useSelector(
    selectIntegralCharacteristics,
  ) as components['schemas']['PNOReservoirBaseSolution']['integral_characteristic'];
  const experimentSettings = useSelector(selectExperimentSettings);

  const getData = () => {
    return {
      calculation_lasting: integralCharacteristic?.calculation_lasting,
      achieved_calculation_accuracy:
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        integralCharacteristic?.achieved_calculation_accuracy?.atm,
      specified_calculation_accuracy:
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        integralCharacteristic?.specified_calculation_accuracy?.atm,
    };
  };

  return (
    <div className="dashboard calc-info">
      {dashboardItems(getData()).map((el, key) => {
        return (
          <div key={key} className={classNames('calculation_info calc-info')}>
            <p>{el.dem}</p>
            {el.value !== undefined ? el.value : '-'}
            &nbsp;{el.value !== undefined && el.unit}
          </div>
        );
      })}
    </div>
  );
};

export default UACalculationInfoDashboard;
