import React from 'react';

import { components } from '../../../../../../generated/apiTypes';
import {
  formatter,
  oneDigitsFormatter,
  smartFormat,
} from '../../../../../../utils';
import { UseChartData } from '../../Chart/types';
import { seriesColors } from '../../Chart/utils';

const useChartData: UseChartData = (wellModel, wellOperationType) => {
  const { pressures } = React.useMemo(() => {
    if (wellModel?.model.pressures__atmg) {
      return {
        pressures: wellModel?.model.pressures__atmg?.map(pressure => {
          return oneDigitsFormatter.format(pressure);
        }),
      };
    }
    return {};
  }, [wellModel?.model.pressures__atmg]);

  return React.useMemo(() => {
    if (pressures) {
      const chartData: { name: string; data: any[] }[] = [];
      const controlActions: any[] =
        (wellModel?.model as Record<string, any>).frequencies__Hz ||
        (wellModel?.model as Record<string, any>)
          .gaslift_flows__thos_m3_per_day ||
        (wellModel?.model as Record<string, any>).work_intervals__min;
      controlActions?.map((control_action, control_action_ix) => {
        let legendName = '';
        if (wellOperationType === 'ЭЦН') {
          legendName =
            (control_action !== undefined &&
              `${formatter.format(control_action)} Гц`) ||
            '';
        } else if (wellOperationType === 'Газлифт') {
          legendName =
            (control_action !== undefined &&
              `${smartFormat(control_action)} тыс. м3/сут`) ||
            '';
        } else if (wellOperationType === 'Время') {
          legendName =
            (control_action !== undefined &&
              `${smartFormat(control_action)} мин`) ||
            '';
        } else if (wellOperationType === 'Фонтан') {
          legendName = 'QP-характеристика';
        }
        return chartData.push({
          name: legendName,
          data: pressures.map((pressure, pressure_ix) => {
            return (
              (
                wellModel?.model as components['schemas']['GetPeriodicPumpWellModelQueryResult']
              ).bottomhole_pressures__atmg &&
              (
                wellModel?.model as components['schemas']['GetPeriodicPumpWellModelQueryResult']
              ).bottomhole_pressures__atmg[control_action_ix][pressure_ix]
            );
          }),
        });
      });

      return {
        labels: pressures,
        datasets: Array.from(chartData).map((el, index) => {
          return {
            label:
              wellOperationType !== 'Фонтан'
                ? `${el.name} [${index + 1}]`
                : el.name,
            data: el.data,
            fill: false,
            backgroundColor: seriesColors[index % 9],
            borderColor: seriesColors[index % 9],
            color: seriesColors[index % 9],
            borderWidth: 1,
            tension: 0.1,
            pointHoverBorderWidth: 0,
            pointRadius: 0,
            pointHitRadius: 0,
            pointBorderWidth: 1,
            pointHoverRadius: 1,
          };
        }),
      };
    }
    return { labels: [], datasets: [] };
  }, [
    pressures,
    (
      wellModel?.model as components['schemas']['GetPeriodicPumpWellModelQueryResult']
    ).bottomhole_pressures__atmg,
    wellOperationType,
  ]);
};

export default useChartData;
