import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectNodeById } from 'store/projectSlice';

import { ReactComponent as JunctionIcon } from '../../../images/Project/icn-junction.svg';
import { IObject } from '../types';

const Junction: React.FC<IObject> = ({ objectUid, projectUid }) => {
  const nodeById = useSelector(selectNodeById);

  return (
    <div className="active-object__content">
      <div className="active-object__top-row">
        <p className="active-object__title">
          <JunctionIcon />
          {nodeById[objectUid as string]?.name}
        </p>
      </div>
    </div>
  );
};

export default Junction;
