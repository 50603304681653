import * as React from 'react';

import { threeDigitsFormatter, twoDigitsFormatter } from 'utils';

export const templateRowsNumber = 5;

export const wellfieldsTableHead = {
  current_state: 'Статус',
  well_name: 'Название',
  operation_type: {
    title: 'Способ эксплуатации',
    component: (
      <>
        Способ <br /> эксплуатации
      </>
    ),
  },
  oil_flow_rate: {
    title: 'Дебит нефти',
    component: (
      <>
        Дебит нефти, <br /> т/сут
      </>
    ),
  },
  fluid_flow_rate: {
    title: 'Дебит жидкости',
    component: (
      <>
        Дебит жидкости, <br /> м<span className="sup">3</span>/сут
      </>
    ),
  },
  gas_flow_rate: {
    title: 'Дебит газа',
    component: (
      <>
        Дебит газа,
        <br />
        тыс.м<span className="sup">3</span>/сут
      </>
    ),
  },
  pressure: {
    title: 'Линейное давление',
    component: (
      <>
        Линейное давление,
        <br /> атм
      </>
    ),
  },
  bottomhole_pressure: {
    title: 'Забойное давление',
    component: (
      <>
        Забойное давление,
        <br /> атм
      </>
    ),
  },
  frequency: {
    title: 'Частота работы',
    component: (
      <>
        Частота работы,
        <br />
        Гц
      </>
    ),
  },
  diameter: {
    title: 'Диаметр штуцера',
    component: (
      <>
        Диаметр штуцера,
        <br />
        мм
      </>
    ),
  },
};
