import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import {
  selectInjectionWellById,
  selectShowDetailsPanel,
  setShowDetailsPanel,
} from 'store/projectSlice';

import { ReactComponent as DetailsIcon } from '../../../../images/Project/icn-details.svg';
import { IObject } from '../../types';
import ObjectInput from '../components/ObjectInput';
import SourceState from '../Source/SourceState';

const InjWellPPD: React.FC<IObject> = ({ objectUid }) => {
  const dispatch = useDispatch();
  const wellById = useSelector(selectInjectionWellById);
  const showDetailsPanel = useSelector(selectShowDetailsPanel);

  const saveValue = () => {};

  const handleToggleDetails = () => {
    if (showDetailsPanel === 'wellModelPPD')
      dispatch(setShowDetailsPanel(null));
    else dispatch(setShowDetailsPanel('wellModelPPD'));
  };

  return (
    <div className="active-object__content">
      <div className="active-object__top-row source-state-row">
        <SourceState
          state={
            wellById[objectUid as string]?.current_state === 'Работает'
              ? 'IN_WORK'
              : undefined
          }
          saveNewValue={saveValue}
          wellName={wellById[objectUid as string]?.name}
          disabled
        />
        <button
          className={classNames(
            'active-object__well-model-button',
            showDetailsPanel === 'wellModelPPD' && 'active',
          )}
          type="button"
          onClick={handleToggleDetails}
        >
          <DetailsIcon className="well-model-button-icon" />
        </button>
      </div>
      <div className="active-object__row">
        <div className="d-flex">
          <div className="active-object__input-with-unit-wrapper left">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Диаметр штуцера"
              name=""
              initValue={
                wellById[objectUid as string]?.current_choke_diameter__mm
              }
              saveNewValue={saveValue}
              disabled
            />
            <p className="active-object__row-input-unit">мм</p>
          </div>
          <div className="active-object__input-with-unit-wrapper">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Коэф. приемистости"
              name=""
              initValue={
                wellById[objectUid as string]
                  ?.productivity_index__m3_per_day_per_atm
              }
              saveNewValue={saveValue}
              disabled
            />
            <p className="active-object__row-input-unit">
              <span className="relative">
                м<span className="index unit-small">3</span>
              </span>
              &nbsp; /сут/атм
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InjWellPPD;
