import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectThreePhaseSeparatorById } from 'store/projectSlice';

import { ReactComponent as SeparatorPPDIcon } from '../../../../images/Project/icn-separator-ppd.svg';
import { IObject } from '../../types';
import ObjectInput from '../components/ObjectInput';

const SeparatorPPD: React.FC<IObject> = ({ objectUid }) => {
  const separatorById = useSelector(selectThreePhaseSeparatorById);

  const saveValue = () => {};

  return (
    <div className="active-object__content">
      <div className="active-object__top-row">
        <p className="active-object__title">
          <SeparatorPPDIcon />
          {separatorById[objectUid as string]?.name}
        </p>
      </div>
      <div className="active-object__row">
        <div className="d-flex">
          <div className="active-object__input-with-unit-wrapper left">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Коэф. сепарации газа "
              name=""
              initValue={
                separatorById[objectUid as string]
                  ?.gas_separation_efficiency__part * 100
              }
              saveNewValue={saveValue}
              disabled
            />
            <p className="active-object__row-input-unit">%</p>
          </div>
          <div className="active-object__input-with-unit-wrapper">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Коэф. сброса воды"
              name=""
              initValue={
                separatorById[objectUid as string]?.water_sep_efficiency__part *
                100
              }
              saveNewValue={saveValue}
              disabled
            />
            <p className="active-object__row-input-unit">%</p>
          </div>
        </div>
      </div>
      <div className="active-object__row">
        <div className="d-flex">
          <div className="active-object__input-with-unit-wrapper">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Давление"
              name=""
              initValue={separatorById[objectUid as string]?.pressure__atm}
              saveNewValue={saveValue}
              disabled
            />
            <p className="active-object__row-input-unit">атм</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeparatorPPD;
