import * as React from 'react';

import { formatter, oneDigitsFormatter } from 'utils';

export const dashboardItems = ({
  pressure,
  fluid_flow_rate,
  oil_flow_rate,
  gas_flow_rate,
}: any = {}) => [
  {
    dem: 'Дебит нефти',
    absAvg:
      oil_flow_rate !== undefined
        ? oneDigitsFormatter.format(oil_flow_rate.abs.avg)
        : oil_flow_rate,
    absMax:
      oil_flow_rate !== undefined
        ? oneDigitsFormatter.format(oil_flow_rate.abs.max)
        : oil_flow_rate,
    relAvg:
      oil_flow_rate !== undefined
        ? oneDigitsFormatter.format(oil_flow_rate.rel.avg)
        : oil_flow_rate,
    relMax:
      oil_flow_rate !== undefined
        ? oneDigitsFormatter.format(oil_flow_rate.rel.max)
        : oil_flow_rate,
    style: 'error',
    unit: 'т/сут',
  },
  {
    dem: 'Дебит жидкости',
    absAvg:
      fluid_flow_rate !== undefined
        ? oneDigitsFormatter.format(fluid_flow_rate.abs.avg)
        : fluid_flow_rate,
    absMax:
      fluid_flow_rate !== undefined
        ? oneDigitsFormatter.format(fluid_flow_rate.abs.max)
        : fluid_flow_rate,
    relAvg:
      fluid_flow_rate !== undefined
        ? oneDigitsFormatter.format(fluid_flow_rate.rel.avg)
        : fluid_flow_rate,
    relMax:
      oil_flow_rate !== undefined
        ? oneDigitsFormatter.format(fluid_flow_rate.rel.max)
        : oil_flow_rate,
    style: 'error',
    unit: (
      <>
        <span className="unit">
          м<span className="index small">3</span>
        </span>
        {' /сут'}
      </>
    ),
  },
  {
    dem: 'Дебит газа',
    absAvg:
      gas_flow_rate !== undefined
        ? oneDigitsFormatter.format(gas_flow_rate.abs.avg)
        : gas_flow_rate,
    absMax:
      gas_flow_rate !== undefined
        ? oneDigitsFormatter.format(gas_flow_rate.abs.max)
        : gas_flow_rate,
    relAvg:
      gas_flow_rate !== undefined
        ? oneDigitsFormatter.format(gas_flow_rate.rel.avg)
        : gas_flow_rate,
    relMax:
      gas_flow_rate !== undefined
        ? oneDigitsFormatter.format(gas_flow_rate.rel.max)
        : gas_flow_rate,
    style: 'error',
    unit: (
      <>
        <span className="unit">
          тыс.м<span className="index small">3</span>
        </span>
        {' /сут'}
      </>
    ),
  },
  {
    dem: 'Линейное давление',
    absAvg:
      pressure !== undefined
        ? oneDigitsFormatter.format(pressure.abs.avg)
        : pressure,
    absMax:
      pressure !== undefined
        ? oneDigitsFormatter.format(pressure.abs.max)
        : pressure,
    relAvg:
      pressure !== undefined
        ? oneDigitsFormatter.format(pressure.rel.avg)
        : pressure,
    relMax:
      pressure !== undefined
        ? oneDigitsFormatter.format(pressure.rel.max)
        : pressure,
    style: 'error',
    unit: 'атм',
  },
];
