import React from 'react';

import classnames from 'classnames';

import { threeDigitsFormatter, twoDigitsFormatter } from 'utils';
import { ITableRowProps } from '../../../../types';
import { isNumber } from '../../../../utils';

const TableRow: React.FC<ITableRowProps> = ({ el, headKeys }) => {
  const formatValue = (key: string, value: string) => {
    return twoDigitsFormatter.format(parseFloat(parseFloat(value).toFixed(2)));
  };
  const renderStatus = (status: string) => {
    return (
      <p
        className={classnames(
          'table-status',
          status === 'Работает' ? 'working' : 'stopped',
        )}
      >
        {' '}
        {status.slice(0, 3).toUpperCase()}
      </p>
    );
  };
  const renderField = (key: string, value: any) => {
    return isNumber(value) ? formatValue(key, value) : value;
  };
  return (
    <tr className={classnames('table-row')}>
      {headKeys.map((key: string) => (
        <td
          className={classnames(
            'table-data',
            ['well_name', 'operation_type', 'control_type'].includes(key) &&
              'left width-112',
            ![
              'well_name',
              'operation_type',
              'control_type',
              'current_state',
            ].includes(key) && 'width-150',
          )}
          key={key}
        >
          {key === 'current_state'
            ? renderStatus(el[key])
            : renderField(key, el[key])}
        </td>
      ))}
    </tr>
  );
};

export default TableRow;
