import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectPhysChemPackages, selectReservoir } from 'store/projectSlice';

import { ReactComponent as LayerPPDIcon } from '../../../../images/Project/layers.svg';
import { IObject } from '../../types';
import ObjectInput from '../components/ObjectInput';
import ObjectSelect, { OptionType } from '../components/ObjectSelect';

const LayerPPD: React.FC<IObject> = () => {
  const reservoir = useSelector(selectReservoir);
  const physChemPackages = useSelector(selectPhysChemPackages);
  const PCPackages: OptionType<string>[] = physChemPackages.map(value => {
    return {
      value: value?.uid as string,
      label: value?.name as string,
    };
  });

  const saveValue = () => {};

  return (
    <div className="active-object__content full-width">
      <div className="active-object__top-row">
        <p className="active-object__title">
          <LayerPPDIcon />
          {reservoir?.name}
        </p>
      </div>
      <div className="active-object__row">
        <div className="d-flex">
          <div className="active-object__input-with-unit-wrapper short">
            <ObjectSelect
              label="Пакет ФХС"
              classNames="active-object__row-input"
              classNamesWrapper="active-object__row-input-wrapper wide"
              name="phys_chem_package_id"
              options={PCPackages}
              value={PCPackages.find(
                item => item.value === reservoir?.phys_chem__uid,
              )}
              saveNewValue={saveValue}
              disabled
            />
          </div>
        </div>
      </div>
      <p className="active-object__subtitle well">Референсные значения</p>
      <div className="active-object__row">
        <div className="d-flex">
          <div className="active-object__input-with-unit-wrapper left">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Дебит нефти"
              name=""
              initValue={reservoir?.oil_production_ref__m3_per_day}
              saveNewValue={saveValue}
              disabled
            />
            <p className="active-object__row-input-unit">
              <span className="relative">
                м<span className="index unit-small">3</span>
              </span>
              &nbsp; /сут
            </p>
          </div>
          <div className="active-object__input-with-unit-wrapper">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Дебит воды"
              name=""
              initValue={reservoir?.water_production_ref__m3_per_day}
              saveNewValue={saveValue}
              disabled
            />
            <p className="active-object__row-input-unit">
              <span className="relative">
                м<span className="index unit-small">3</span>
              </span>
              &nbsp; /сут
            </p>
          </div>
        </div>
      </div>
      <div className="active-object__row">
        <div className="d-flex">
          <div className="active-object__input-with-unit-wrapper left">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Дебит газа"
              name=""
              initValue={reservoir?.gas_production_ref__m3_per_day}
              saveNewValue={saveValue}
              disabled
            />
            <p className="active-object__row-input-unit">
              <span className="relative">
                м<span className="index unit-small">3</span>
              </span>
              &nbsp; /сут
            </p>
          </div>
          <div className="active-object__input-with-unit-wrapper">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Расход закачки"
              name=""
              initValue={reservoir?.water_injection_ref__m3_per_day}
              saveNewValue={saveValue}
              disabled
            />
            <p className="active-object__row-input-unit">
              <span className="relative">
                м<span className="index unit-small">3</span>
              </span>
              &nbsp; /сут
            </p>
          </div>
        </div>
      </div>
      <div className="active-object__row">
        <div className="d-flex">
          <div className="active-object__input-with-unit-wrapper">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Пластовое давление"
              name=""
              initValue={reservoir?.pressure_ref__atmg}
              saveNewValue={saveValue}
              disabled
            />
            <p className="active-object__row-input-unit">атм</p>
          </div>
        </div>
      </div>
      <p className="active-object__subtitle limitations">Производные Рпл</p>
      <div className="active-object__row">
        <div className="d-flex">
          <div className="active-object__input-with-unit-wrapper left">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Дебит нефти"
              name=""
              initValue={
                reservoir?.d_pressure_per_d_oil_production__atm_day_per_m3
              }
              saveNewValue={saveValue}
              disabled
            />
            <p className="active-object__row-input-unit">
              атм∙сут/
              <span className="relative">
                м<span className="index unit-small">3</span>
              </span>
            </p>
          </div>
          <div className="active-object__input-with-unit-wrapper">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Дебит воды"
              name=""
              initValue={
                reservoir?.d_pressure_per_d_water_production__atm_day_per_m3
              }
              saveNewValue={saveValue}
              disabled
            />
            <p className="active-object__row-input-unit">
              атм∙сут/
              <span className="relative">
                м<span className="index unit-small">3</span>
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="active-object__row">
        <div className="d-flex">
          <div className="active-object__input-with-unit-wrapper left">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Дебит газа"
              name=""
              initValue={
                reservoir?.d_pressure_per_d_gas_production__atm_day_per_m3
              }
              saveNewValue={saveValue}
              disabled
            />
            <p className="active-object__row-input-unit">
              атм∙сут/
              <span className="relative">
                м<span className="index unit-small">3</span>
              </span>
            </p>
          </div>
          <div className="active-object__input-with-unit-wrapper">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Расход закачки"
              name=""
              initValue={
                reservoir?.d_pressure_per_d_water_injection__atm_day_per_m3
              }
              saveNewValue={saveValue}
              disabled
            />
            <p className="active-object__row-input-unit">
              атм∙сут/
              <span className="relative">
                м<span className="index unit-small">3</span>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LayerPPD;
