import * as React from 'react';
import { useSelector } from 'react-redux';

import classnames from 'classnames';
import { selectAvgWellsTableUA, selectSolution } from 'store/taskSlice';

import { components } from '../../../../../../../generated/apiTypes';
import TableHead from '../../../../components/TableHead';
import TableWrapper from '../../../../components/TableWrapper';
import { IThead } from '../../../../types';
import TableRow from './TableRow';
import TemplateTableRow from './TemplateTableRow';
import { templateRowsNumber } from './utils';

export interface ISourcesTableProps {
  tableHead: IThead;
  step?: number;
}

const SourcesTable: React.FC<ISourcesTableProps> = ({ tableHead }) => {
  const solution = useSelector(selectSolution)?.solution;
  const wellStates = (
    solution?.solution as components['schemas']['PNOReservoirBaseSolution']
  )?.well_states;
  const [data, setData] = React.useState<any[]>([]);
  const headKeys = Object.keys(tableHead);

  const getData = () => {
    const newData: any = [];
    wellStates?.forEach(state => {
      newData.push({
        current_state: state.current_state,
        well_name: state.well_name,
        operation_type: state.operation_type,
        control_type: state.control_type,
        offset_time: state.offset_time,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        fluid_flow_rate: state.fluid_flow_rate?.m3_per_day,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        oil_flow_rate: state.oil_flow_rate?.t_per_day,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        gas_flow_rate: state.gas_flow_rate?.thousand_m3_per_day,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        pressure: state.pressure?.atm,
        bottomhole_pressure: state.bottomhole_pressure?.atm,
        frequency:
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          state.operation_type === 'Добывающая' ? state.control?.hertz : '',
        diameter:
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          state.operation_type === 'Нагнетательная' ? state.control?.mm : '',
      });
    });

    setData(newData);
  };

  React.useEffect(() => {
    getData();
  }, [wellStates]);

  return (
    <TableWrapper>
      <table className={classnames('table', 'table__sources-table')}>
        <TableHead headKeys={headKeys} tableHead={tableHead} />
        <tbody>
          {data?.length > 0
            ? data.map((value: any, index: number) => {
                return <TableRow key={index} headKeys={headKeys} el={value} />;
              })
            : Array.from({ length: templateRowsNumber }, (item, index) => (
                <TemplateTableRow key={index} headKeys={headKeys} />
              ))}
        </tbody>
      </table>
    </TableWrapper>
  );
};

export default SourcesTable;
