import * as React from 'react';

import { smartFormat, timeFormatter } from 'utils';

export const dashboardItems = ({
  achieved_calculation_accuracy__atm,
  specified_calculation_accuracy__atm,
  calculation_lasting__s,
}: any = {}) => [
  {
    dem: 'Точность расчета',
    value:
      specified_calculation_accuracy__atm &&
      smartFormat(specified_calculation_accuracy__atm),
    unit: 'атм',
  },
  {
    dem: 'Достигнутая точность',
    value:
      achieved_calculation_accuracy__atm &&
      smartFormat(achieved_calculation_accuracy__atm),
    unit: 'атм',
  },
  {
    dem: 'Время выполнения',
    value: calculation_lasting__s && timeFormatter(calculation_lasting__s),
  },
];
