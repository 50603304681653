import * as React from 'react';

import { threeDigitsFormatter, twoDigitsFormatter } from 'utils';
import { components } from '../../../../../../../generated/apiTypes';

export const templateRowsNumber = 5;

export const nodesTableHead = {
  name: 'Название',
  type: {
    title: 'Тип',
    component: <>Тип</>,
  },
  oil_flow_rate: {
    title: 'Расход нефти',
    component: (
      <>
        Расход нефти,
        <br /> т/сут
      </>
    ),
  },
  fluid_flow_rate: {
    title: 'Расход жидкости',
    component: (
      <>
        Расход жидкости,
        <br /> м<span className="sup">3</span>/сут
      </>
    ),
  },
  gas_flow_rate: {
    title: 'Расход газа',
    component: (
      <>
        Расход газа, <br />
        тыс.м<span className="sup">3</span>/сут
      </>
    ),
  },
  pressure: {
    title: 'Давление',
    component: (
      <>
        Давление, <br />
        атм
      </>
    ),
  },
  temperature: {
    title: 'Температура',
    component: (
      <>
        Температура,
        <br />
        ˚C
      </>
    ),
  },
};

const getTypeName = (type: components['schemas']['PNOPipelineNodeType']) => {
  switch (type) {
    case 'SOURCE':
      return 'Исток';
    case 'SINK':
      return 'Сток';
    case 'JUNCTION':
      return 'Узел';
    default:
      return '';
  }
};

export const nodesTableItems = ({
  name,
  type,
  oil_flow_rate_mass,
  oil_flow_rate_mass_abs,
  oil_flow_rate_mass_rel,
  fluid_flow_rate_volume,
  fluid_flow_rate_volume_abs,
  fluid_flow_rate_volume_rel,
  gas_flow_rate_volume,
  gas_flow_rate_volume_abs,
  gas_flow_rate_volume_rel,
  pressure,
  pressure_abs,
  pressure_rel,
  temperature,
  temperature__abs,
  temperature__rel,
}: any = {}) => [
  {
    dem: 'name',
    value: name,
  },
  {
    dem: 'type',
    value: getTypeName(type),
  },
  {
    dem: 'oil_flow_rate',
    value: oil_flow_rate_mass && oil_flow_rate_mass.t_per_day,
    abs: oil_flow_rate_mass_abs?.t_per_day,
    rel: oil_flow_rate_mass_rel,
    unit: 'т/сут',
  },
  {
    dem: 'fluid_flow_rate',
    value: fluid_flow_rate_volume && fluid_flow_rate_volume.m3_per_day,
    abs: fluid_flow_rate_volume_abs?.m3_per_day,
    rel: fluid_flow_rate_volume_rel,
    unit: (
      <>
        м<span className="sup">3</span>/сут
      </>
    ),
  },
  {
    dem: 'gas_flow_rate',
    value: gas_flow_rate_volume && gas_flow_rate_volume.thousand_m3_per_day,
    abs: gas_flow_rate_volume_abs?.thousand_m3_per_day,
    rel: gas_flow_rate_volume_rel,
    unit: (
      <>
        тыс. м<span className="sup">3</span>/сут
      </>
    ),
  },
  {
    dem: 'pressure',
    value: pressure && pressure.atm,
    abs: pressure_abs?.atm,
    rel: pressure_rel,
    unit: 'атм',
  },
  {
    dem: 'temperature',
    value: temperature && temperature.celsius,
    abs: temperature__abs?.celsius,
    rel: temperature__rel,
    unit: '℃',
  },
];
