import * as React from 'react';
import { useSelector } from 'react-redux';

import classnames from 'classnames';
import { PNOAFSolution } from 'Pages/Project/types';
import { selectSolution } from 'store/taskSlice';

import TableHead from '../../../components/TableHead';
import TableWrapper from '../../../components/TableWrapper';
import { IThead } from '../../../types';
import TableRow from './TableRow';
import TemplateTableRow from './TemplateTableRow';
import { templateRowsNumber } from './utils';

export interface ITableProps {
  tableHead: IThead;
}

const AvgTable: React.FC<ITableProps> = ({ tableHead }) => {
  const solution = useSelector(selectSolution);
  const [data, setData] = React.useState<any[]>([]);
  const headKeys = Object.keys(tableHead);

  const getData = () => {
    const newData: any = [];
    (solution.solution as PNOAFSolution)?.solution?.pipeline_states?.forEach(
      state => {
        newData.push({
          start_node_name: state.start_node_name,
          end_node_name: state.end_node_name,
          friction_factor_correction: state.friction_factor_correction,
          holdup_factor_correction: state.holdup_factor_correction,
          pressure_correction: state.dP_shift__atm,
        });
      },
    );
    setData(newData);
  };

  React.useEffect(() => {
    getData();
  }, [(solution.solution as PNOAFSolution)?.solution?.pipeline_states]);

  return (
    <TableWrapper vertical horizontal smoothScrolling>
      <table className={classnames('table', 'table__sources-table')}>
        <TableHead headKeys={headKeys} tableHead={tableHead} />
        <tbody>
          {data?.length > 0
            ? data.map((value: any, index: number) => {
                return <TableRow key={index} headKeys={headKeys} el={value} />;
              })
            : Array.from({ length: templateRowsNumber }, (item, index) => (
                <TemplateTableRow key={index} headKeys={headKeys} />
              ))}
        </tbody>
      </table>
    </TableWrapper>
  );
};

export default AvgTable;
