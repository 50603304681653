import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { ValueType } from 'react-select';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import classNames from 'classnames';
import { setActiveObject } from 'store/mapSlice';
import {
  selectNodeById,
  selectShowDetailsPanel,
  selectWellById,
  selectWellControlModelById,
  selectWellModelById,
  setShowDetailsPanel,
  updateWell,
  updateWellModal,
} from 'store/projectSlice';
import {
  selectExperimentType,
  selectTaskId,
  setIsActual,
} from 'store/taskSlice';

import {
  getTasks,
  updateWell as saveWell,
  updateWellModel as saveWellModel,
} from 'services/apiRequests';
import { components } from '../../../../generated/apiTypes';
import { ReactComponent as DetailsIcon } from '../../../../images/Project/icn-details.svg';
import { selectActiveObject } from '../../../../store/mapSlice';
import { IObject } from '../../types';
import { getWellOperationType } from '../../utils';
import { OptionType } from '../components/ObjectSelect';
import {
  getGasFractionOptions,
  getWaterFractionOptions,
} from '../components/options';
import useGetWellModel from './hooks/use-get-well-model';
import SourceState from './SourceState';
import SourceView from './SourceView';
import SourceViewAF from './SourceViewAF';

const Source: React.FC<IObject> = ({ nodeUid, objectUid }) => {
  const wellsById = useSelector(selectWellById);
  const wellsModelsById = useSelector(selectWellModelById);
  const wellsControlModelsById = useSelector(selectWellControlModelById);
  const activeObject = useSelector(selectActiveObject);
  const nodeById = useSelector(selectNodeById);
  const experimentType = useSelector(selectExperimentType);
  const showDetailsPanel = useSelector(selectShowDetailsPanel);
  const params = useParams() as { id: string };
  const taskId = useSelector(selectTaskId);
  const dispatch = useDispatch();

  if (objectUid) useGetWellModel(params.id, objectUid);

  const [waterFractionType, setWaterFractionType] = React.useState<
    ValueType<OptionType<string>, false> | undefined
  >(getWaterFractionOptions()[0]);
  const [gasFractionType, setGasFractionType] = React.useState<
    ValueType<OptionType<string>, false> | undefined
  >(getGasFractionOptions()[0]);
  const [tabIndex, setTabIndex] = React.useState(0);

  React.useEffect(() => {
    setWaterFractionType(getWaterFractionOptions()[0]);
    setGasFractionType(getGasFractionOptions()[0]);
    return () => {
      // dispatch(setShowDetailsPanel(null));
    };
  }, [objectUid]);

  const saveNewWellValue = async (value: number | string, name: string) => {
    const newValue = { ...wellsById[nodeUid as string], [name]: value };

    try {
      // TODO нужен рефакторинг всей этой части так она избыточна
      // и следует формировать параллельные запросы для таких вещей.
      // Также проследить какие данные уходят в редьюсеры и убрать
      // вспомогательные библиотечные методы update и сформировать
      // собственную функцию для таких вещей в тч отлавливать
      // ошибки которые все-таки должны возникать, когда прилетает массив
      // вместо объекта в редьюсер.
      dispatch(updateWell(newValue));
      dispatch(
        setActiveObject({
          ...activeObject,
          type:
            newValue.current_state === 'STOPPED' ? 'SOURCESTOPPED' : 'SOURCE',
        }),
      );
      await saveWell(newValue.id, {
        [name]: value,
      } as components['schemas']['UpdateWellCommand']);

      const res = await getTasks(params.id);
      if (res?.data?.length > 0) {
        const task = res?.data?.find(item => item.task_uid === taskId);
        if (task && !task.is_actual) {
          dispatch(setIsActual(task.is_actual!));
        }
      }
    } catch (e) {
      //
    }
  };

  const saveNewWellModelValue = async (
    value: number | string,
    name: string,
  ) => {
    const oldValue = wellsModelsById[objectUid as string];
    const { well_id, ...model } = wellsModelsById[objectUid as string];
    const newValue = { model: { ...model.model, [name]: value } };
    dispatch(
      updateWellModal({ well_id, model: { ...model.model, [name]: value } }),
    );
    try {
      await saveWellModel(objectUid as string, newValue);
      const res = await getTasks(params.id);
      if (res?.data?.length > 0) {
        const task = res?.data?.find(item => item.task_uid === taskId);
        if (task && !task.is_actual) {
          dispatch(setIsActual(task.is_actual!));
        }
      }
    } catch (e) {
      dispatch(updateWellModal(oldValue));
    }
  };

  const saveNewWaterFractionType = (
    value: ValueType<OptionType<string>, false>,
  ) => {
    setWaterFractionType(value);
  };

  const saveNewGasFractionType = (
    value: ValueType<OptionType<string>, false>,
  ) => {
    setGasFractionType(value);
  };

  const handleToggleDetails = () => {
    if (showDetailsPanel === 'wellModel') dispatch(setShowDetailsPanel(null));
    else dispatch(setShowDetailsPanel('wellModel'));
  };

  return (
    <>
      <div className="active-object__content full-width">
        <div className="active-object__top-row source-state-row">
          <SourceState
            state={
              /* tabIndex === 0
                ? */ wellsById[objectUid as string]?.current_state
              /*: wellsById[objectUid as string]?.well_fact_measurement
                    ?.boundary_current_state */
            }
            saveNewValue={saveNewWellValue}
            wellName={wellsById[objectUid as string]?.name}
            disabled={tabIndex === 1}
          />
          {['Базовый режим', 'Оптимизация режима', 'Оптимизация сети'].includes(
            experimentType,
          ) &&
            getWellOperationType(wellsById[objectUid as string]?.type) !==
              '5d' && (
              <button
                className={classNames(
                  'active-object__well-model-button',
                  showDetailsPanel === 'wellModel' && 'active',
                )}
                type="button"
                onClick={handleToggleDetails}
              >
                <DetailsIcon className="well-model-button-icon" />
              </button>
            )}
        </div>
      </div>
      <Tabs
        className="active-object__tabs"
        selectedIndex={tabIndex}
        onSelect={index => setTabIndex(index)}
      >
        <TabList className="active-object__tab-list">
          <Tab className="active-object__tab">Текущее состояние</Tab>
          <Tab className="active-object__tab" disabled>
            Факт. состояние
          </Tab>
        </TabList>
        <div className="active-object__panels">
          <TabPanel>
            <SourceView
              well={wellsById[objectUid as string]}
              wellModel={wellsModelsById[objectUid as string]}
              wellControlModel={wellsControlModelsById[objectUid as string]}
              saveNewWellValue={saveNewWellValue}
              saveNewWellModelValue={saveNewWellModelValue}
              waterFractionType={
                waterFractionType as ValueType<OptionType<string>, false>
              }
              gasFractionType={
                gasFractionType as ValueType<OptionType<string>, false>
              }
              saveNewWaterFractionType={saveNewWaterFractionType}
              saveNewGasFractionType={saveNewGasFractionType}
            />
          </TabPanel>
          <TabPanel>
            {/* <SourceViewAF
              well={wellsById[objectUid as string]}
              wellModel={wellsModelsById[objectUid as string]}
              wellControlModel={wellsControlModelsById[objectUid as string]}
              wellFactMeasurement={
                wellsById[objectUid as string]?.well_fact_measurement
              }
              saveNewWellValue={saveNewWellValue}
              saveNewWellFactMeasurementValue={saveNewWellFactMeasurementValue}
              waterFractionType={
                waterFractionType as ValueType<OptionType<string>, false>
              }
              gasFractionType={
                gasFractionType as ValueType<OptionType<string>, false>
              }
              saveNewWaterFractionType={saveNewWaterFractionType}
              saveNewGasFractionType={saveNewGasFractionType}
            /> */}
          </TabPanel>
        </div>
      </Tabs>
    </>
  );
};

export default Source;
