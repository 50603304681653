import * as React from 'react';

import { formatter } from 'utils';

export const dashboardItems = ({
  max_absolute_error_over_wells__atm,
}: any = {}) => [
  {
    dem: 'Макс. абс. отклонение расч. давления',
    value:
      max_absolute_error_over_wells__atm !== undefined
        ? formatter.format(max_absolute_error_over_wells__atm)
        : '',
    unit: 'атм',
  },
];
