import React from 'react';
import ScrollArea from 'react-scrollbar/dist/no-css';

import classnames from 'classnames';

import { ITableWrapperProps } from '../types';

const TableWrapper: React.FC<ITableWrapperProps> = ({
  vertical,
  horizontal,
  smoothScrolling,
  className,
  contentClassName,
  children,
  verticalContainerStyle,
}) => {
  return (
    /* <ScrollArea
      vertical={false}
      horizontal={horizontal}
      smoothScrolling={smoothScrolling}
      className={classnames(
        className,
        verticalScroll && 'vertical-scroll',
        verticallyScrolled && 'scrolled',
      )}
      onScroll={onScroll}
      contentClassName={contentClassName}
      verticalContainerStyle={verticalContainerStyle}
    > */
    <div className={classnames('scrollarea')}>{children}</div>
    /* </ScrollArea> */
  );
};

export default TableWrapper;
