import * as React from 'react';

import SegmentsTable from './Table';
import { segmentsTableHead } from './utils';

const Table: React.FC = () => {
  return (
    <>
      <SegmentsTable tableHead={segmentsTableHead} />
    </>
  );
};

export default Table;
