import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { components } from 'generated/apiTypes';
import { selectClusters } from 'store/projectSlice';
import {
  selectExperimentSettings,
  setExperimentSettings as setStoreExperimentSettings,
} from 'store/taskSlice';

import {
  getClusterInequalityOptimizationConstraints,
  setExperimentSettings,
} from 'services/apiRequests';
import ObjectInput from '../../ActiveObject/components/ObjectInput';
import ObjectSelect from '../../ActiveObject/components/ObjectSelect';
import { InequalityConstraint } from '../../types';
import { getInequalityConstraintsOptions } from '../options';

interface IClusterEqualityConstraintProps {
  setIncompleteConstraints(val: boolean): void;
  setExperimentSettingsSaved(val: boolean): void;
}

const ClusterEqualityConstraint: React.FC<IClusterEqualityConstraintProps> = ({
  setIncompleteConstraints,
  setExperimentSettingsSaved,
}) => {
  const dispatch = useDispatch();
  const params = useParams() as { id: string };
  const settings = useSelector(selectExperimentSettings);
  const clusters = useSelector(selectClusters);
  const [constraintValue1, setConstraintValue1] = React.useState<
    InequalityConstraint | undefined
  >();
  const [constraintNumber1, setConstraintNumber1] = React.useState<
    number | undefined
  >();
  const [constraintValue2, setConstraintValue2] = React.useState<
    InequalityConstraint | undefined
  >();
  const [constraintNumber2, setConstraintNumber2] = React.useState<
    number | undefined
  >();
  const timerId = React.useRef<ReturnType<typeof setTimeout>>();
  const [constraintsOptionsCurrent, setConstraintsOptionsCurrent] =
    React.useState<
      {
        value: InequalityConstraint;
        label: string;
        disabled: boolean;
      }[]
    >();
  const [cluster, setCluster] =
    React.useState<components['schemas']['GetClusterQueryResult']>();

  React.useEffect(() => {
    setIncompleteConstraints(true);
  }, []);

  React.useEffect(() => {
    let allClustersConstraint1Set = true;
    let allClustersConstraint2Set = true;
    const isSecondConstraint = (
      settings?.optimization_experiment_settings
        ?.constraints as components['schemas']['ClusterInequalityNetworkOptimizationExperimentSettingsConstraints']
    )?.constraints?.some(
      constraint => constraint?.cluster_constraints?.length > 1,
    );
    clusters.forEach(clusterItem => {
      allClustersConstraint1Set =
        allClustersConstraint1Set &&
        !!(
          settings?.optimization_experiment_settings
            ?.constraints as components['schemas']['ClusterInequalityNetworkOptimizationExperimentSettingsConstraints']
        )?.constraints
          ?.find(item => item.cluster_id === clusterItem.id)
          ?.cluster_constraints?.find(
            constraintItem => constraintItem.type === constraintValue1,
          );
      allClustersConstraint2Set =
        allClustersConstraint2Set &&
        !!(
          settings?.optimization_experiment_settings
            ?.constraints as components['schemas']['ClusterInequalityNetworkOptimizationExperimentSettingsConstraints']
        )?.constraints
          ?.find(item => item.cluster_id === clusterItem.id)
          ?.cluster_constraints?.find(
            constraintItem => constraintItem.type === constraintValue2,
          );
    });
    if (
      (isSecondConstraint &&
        allClustersConstraint1Set &&
        allClustersConstraint2Set) ||
      (!isSecondConstraint && allClustersConstraint1Set)
    ) {
      setIncompleteConstraints(false);
    } else {
      setIncompleteConstraints(true);
    }
  }, [
    clusters,
    (
      settings?.optimization_experiment_settings
        ?.constraints as components['schemas']['ClusterInequalityNetworkOptimizationExperimentSettingsConstraints']
    )?.constraints,
    constraintValue1,
    constraintValue2,
  ]);

  React.useEffect(() => {
    if (clusters?.filter(clusterItem => !clusterItem.is_empty)?.length > 0) {
      setCluster(clusters[0]);
    }
  }, [clusters]);

  React.useEffect(() => {
    if (
      (
        settings?.optimization_experiment_settings
          ?.constraints as components['schemas']['ClusterInequalityNetworkOptimizationExperimentSettingsConstraints']
      )?.constraints?.find(item => item.cluster_id === cluster?.id)
        ?.cluster_constraints
    ) {
      const type = (
        settings?.optimization_experiment_settings
          ?.constraints as components['schemas']['ClusterInequalityNetworkOptimizationExperimentSettingsConstraints']
      )?.constraints?.find(item => item.cluster_id === cluster?.id)
        ?.cluster_constraints[0]?.type;

      if (type !== constraintValue1) setConstraintValue1(type);
    }
  }, [
    (
      settings?.optimization_experiment_settings
        ?.constraints as components['schemas']['ClusterInequalityNetworkOptimizationExperimentSettingsConstraints']
    )?.constraints?.find(item => item.cluster_id === cluster?.id)
      ?.cluster_constraints &&
      (
        settings?.optimization_experiment_settings
          ?.constraints as components['schemas']['ClusterInequalityNetworkOptimizationExperimentSettingsConstraints']
      )?.constraints?.find(item => item.cluster_id === cluster?.id)
        ?.cluster_constraints[0]?.type,
  ]);

  React.useEffect(() => {
    if (
      (
        settings?.optimization_experiment_settings
          ?.constraints as components['schemas']['ClusterInequalityNetworkOptimizationExperimentSettingsConstraints']
      )?.constraints?.find(item => item.cluster_id === cluster?.id)
        ?.cluster_constraints
    ) {
      const type = (
        settings?.optimization_experiment_settings
          ?.constraints as components['schemas']['ClusterInequalityNetworkOptimizationExperimentSettingsConstraints']
      )?.constraints?.find(item => item.cluster_id === cluster?.id)
        ?.cluster_constraints[1]?.type;
      if (type !== constraintValue2) setConstraintValue2(type);
    }
  }, [
    (
      settings?.optimization_experiment_settings
        ?.constraints as components['schemas']['ClusterInequalityNetworkOptimizationExperimentSettingsConstraints']
    )?.constraints?.find(item => item.cluster_id === cluster?.id)
      ?.cluster_constraints &&
      (
        settings?.optimization_experiment_settings
          ?.constraints as components['schemas']['ClusterInequalityNetworkOptimizationExperimentSettingsConstraints']
      )?.constraints?.find(item => item.cluster_id === cluster?.id)
        ?.cluster_constraints[1]?.type,
  ]);

  React.useEffect(() => {
    if (
      (
        settings?.optimization_experiment_settings
          ?.constraints as components['schemas']['ClusterInequalityNetworkOptimizationExperimentSettingsConstraints']
      )?.constraints?.find(item => item.cluster_id === cluster?.id)
        ?.cluster_constraints
    ) {
      const value = (
        settings?.optimization_experiment_settings
          ?.constraints as components['schemas']['ClusterInequalityNetworkOptimizationExperimentSettingsConstraints']
      )?.constraints?.find(item => item.cluster_id === cluster?.id)
        ?.cluster_constraints[0]?.value as any;

      const type = (
        settings?.optimization_experiment_settings
          ?.constraints as components['schemas']['ClusterInequalityNetworkOptimizationExperimentSettingsConstraints']
      )?.constraints?.find(item => item.cluster_id === cluster?.id)
        ?.cluster_constraints[0]?.type;

      if (type && value !== undefined && type === constraintValue1) {
        const valueToCompare = [
          'MaxSpecificPowerConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint',
          'MaxSpecificGasliftConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint',
        ].includes(type)
          ? value
          : value?.[getUnitByConstraint(type)];

        if (valueToCompare !== constraintNumber1) {
          setConstraintNumber1(valueToCompare);
        }
      } else if (constraintNumber1 !== undefined) {
        setConstraintNumber1(undefined);
      }
    } else if (constraintNumber1 !== undefined) {
      setConstraintNumber1(undefined);
    }
  }, [
    (
      settings?.optimization_experiment_settings
        ?.constraints as components['schemas']['ClusterInequalityNetworkOptimizationExperimentSettingsConstraints']
    )?.constraints?.find(item => item.cluster_id === cluster?.id)
      ?.cluster_constraints,
    constraintValue1,
  ]);

  React.useEffect(() => {
    if (
      (
        settings?.optimization_experiment_settings
          ?.constraints as components['schemas']['ClusterInequalityNetworkOptimizationExperimentSettingsConstraints']
      )?.constraints?.find(item => item.cluster_id === cluster?.id)
        ?.cluster_constraints
    ) {
      const value = (
        settings?.optimization_experiment_settings
          ?.constraints as components['schemas']['ClusterInequalityNetworkOptimizationExperimentSettingsConstraints']
      )?.constraints?.find(item => item.cluster_id === cluster?.id)
        ?.cluster_constraints[1]?.value as any;

      const type = (
        settings?.optimization_experiment_settings
          ?.constraints as components['schemas']['ClusterInequalityNetworkOptimizationExperimentSettingsConstraints']
      )?.constraints?.find(item => item.cluster_id === cluster?.id)
        ?.cluster_constraints[1]?.type;

      if (type && value !== undefined && type === constraintValue2) {
        const valueToCompare = [
          'MaxSpecificPowerConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint',
          'MaxSpecificGasliftConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint',
        ].includes(type)
          ? value
          : value?.[getUnitByConstraint(type)];

        if (valueToCompare !== constraintNumber2) {
          setConstraintNumber2(valueToCompare);
        }
      } else if (constraintNumber2 !== undefined) {
        setConstraintNumber2(undefined);
      }
    } else if (constraintNumber2 !== undefined) {
      setConstraintNumber2(undefined);
    }
  }, [
    (
      settings?.optimization_experiment_settings
        ?.constraints as components['schemas']['ClusterInequalityNetworkOptimizationExperimentSettingsConstraints']
    )?.constraints?.find(item => item.cluster_id === cluster?.id)
      ?.cluster_constraints,
    constraintValue2,
  ]);

  React.useEffect(() => {
    let option1ToDisable:
      | {
          value: InequalityConstraint;
          label: string;
          disabled: boolean;
        }
      | undefined;
    let option2ToDisable:
      | {
          value: InequalityConstraint;
          label: string;
          disabled: boolean;
        }
      | undefined;
    switch (settings?.optimization_experiment_settings?.target_metric) {
      case 'Maximum oil debit':
        option1ToDisable = getInequalityConstraintsOptions().find(
          item =>
            item.value ===
            'MinOilDebitInequalityNetworkOptimizationExperimentSettingsConstraint',
        );
        break;
      case 'Minimum energy consumption':
        option1ToDisable = getInequalityConstraintsOptions().find(
          item =>
            item.value ===
            'MaxEnergyConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint',
        );
        break;
      case 'Minimum active gas consumption':
        option1ToDisable = getInequalityConstraintsOptions().find(
          item =>
            item.value ===
            'MaxActiveGasConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint',
        );
        break;
      case 'MIN_PRODUCED_FLUID_VOLUME':
        option1ToDisable = getInequalityConstraintsOptions().find(
          item =>
            item.value ===
            'MaxProducedFluidVolumeInequalityNetworkOptimizationExperimentSettingsConstraint',
        );
        break;
      case 'MIN_PRODUCED_ASSOCIATED_PETROLEUM_GAS_VOLUME':
      case 'MAX_PRODUCED_ASSOCIATED_PETROLEUM_GAS_VOLUME':
        option1ToDisable = getInequalityConstraintsOptions().find(
          item =>
            item.value ===
            'MaxProducedPetroleumGasVolumeInequalityNetworkOptimizationExperimentSettingsConstraint',
        );
        break;
      case 'MIN_PRODUCED_WATER_VOLUME':
        option1ToDisable = getInequalityConstraintsOptions().find(
          item =>
            item.value ===
            'MaxProducedWaterVolumeInequalityNetworkOptimizationExperimentSettingsConstraint',
        );
        break;
      case 'MIN_SPECIFIC_POWER_CONSUMPTION':
        option1ToDisable = getInequalityConstraintsOptions().find(
          item =>
            item.value ===
            'MaxSpecificPowerConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint',
        );
        break;
      case 'MIN_SPECIFIC_GASLIFT_CONSUMPTION':
        option1ToDisable = getInequalityConstraintsOptions().find(
          item =>
            item.value ===
            'MaxSpecificGasliftConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint',
        );
        break;
      case 'MIN_TOTAL_GAS':
      case 'MAX_TOTAL_GAS':
        option1ToDisable = getInequalityConstraintsOptions().find(
          item =>
            item.value ===
            'MaxTotalGasConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint',
        );
        option2ToDisable = getInequalityConstraintsOptions().find(
          item =>
            item.value ===
            'MinTotalGasConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint',
        );
        break;
      default:
        break;
    }
    setConstraintsOptionsCurrent(
      getInequalityConstraintsOptions().map(item => {
        if (item.value === option1ToDisable?.value) {
          return { ...option1ToDisable, disabled: true };
        }
        if (item.value === option2ToDisable?.value) {
          return { ...option2ToDisable, disabled: true };
        }
        if (item.value === constraintValue1) {
          const option = getInequalityConstraintsOptions().find(
            option1 => option1.value === constraintValue1,
          );
          if (option) return { ...option, disabled: true };
        } else if (item.value === constraintValue2) {
          const option = getInequalityConstraintsOptions().find(
            option2 => option2.value === constraintValue2,
          );
          if (option) return { ...option, disabled: true };
        }
        return item;
      }),
    );
  }, [
    settings?.optimization_experiment_settings?.target_metric,
    constraintValue1,
    constraintValue2,
  ]);

  React.useEffect(() => {
    const fetchInequalityConstraints = async () => {
      let response;
      if (constraintValue1) {
        response = await getClusterInequalityOptimizationConstraints(
          params.id,
          constraintValue1,
        );
      }
      return response;
    };
    const saveInitValue = async (initValues: Record<any, number>) => {
      await tryToSaveConstraintWithSettings(
        constraintValue1,
        initValues,
        'first',
      );
    };
    const type = (
      settings?.optimization_experiment_settings
        ?.constraints as components['schemas']['ClusterInequalityNetworkOptimizationExperimentSettingsConstraints']
    )?.constraints?.find(item => item.cluster_id === cluster?.id)
      ?.cluster_constraints
      ? (
          settings?.optimization_experiment_settings
            ?.constraints as components['schemas']['ClusterInequalityNetworkOptimizationExperimentSettingsConstraints']
        )?.constraints?.find(item => item.cluster_id === cluster?.id)
          ?.cluster_constraints[0]?.type
      : undefined;
    if (type !== constraintValue1 && constraintValue1 !== undefined) {
      setIncompleteConstraints(true);
      let initValues: Record<any, number> = {};
      fetchInequalityConstraints().then(response => {
        const clusterConstraints = response?.data.constraints?.find(
          constraint => constraint.cluster_id === cluster?.id,
        )?.cluster_constraints;
        if (clusterConstraints && clusterConstraints[0]?.type) {
          if (
            [
              'MaxSpecificPowerConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint',
              'MaxSpecificGasliftConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint',
            ].includes(clusterConstraints[0].type)
          ) {
            response?.data.constraints?.forEach(
              item =>
                (initValues = {
                  ...initValues,
                  ...{
                    [item.cluster_id]: item.cluster_constraints[0]
                      .value as number,
                  },
                }),
            );
          } else
            response?.data.constraints?.forEach(
              item =>
                (initValues = {
                  ...initValues,
                  ...{
                    [item.cluster_id]: (
                      item.cluster_constraints[0].value as any
                    )?.[getUnitByConstraint(item.cluster_constraints[0].type!)],
                  },
                }),
            );
        }
        saveInitValue(initValues).then();
      });
      setIncompleteConstraints(false);
    }
    if (constraintValue1 === constraintValue2) setConstraintValue2(undefined);
  }, [constraintValue1]);

  React.useEffect(() => {
    const fetchInequalityConstraints = async () => {
      let response;
      if (constraintValue2) {
        response = await getClusterInequalityOptimizationConstraints(
          params.id,
          constraintValue2,
        );
      }
      return response;
    };
    const saveInitValue = async (initValues: Record<any, number>) => {
      await tryToSaveConstraintWithSettings(
        constraintValue2,
        initValues,
        'second',
      );
    };
    const type = (
      settings?.optimization_experiment_settings
        ?.constraints as components['schemas']['ClusterInequalityNetworkOptimizationExperimentSettingsConstraints']
    )?.constraints?.find(item => item.cluster_id === cluster?.id)
      ?.cluster_constraints
      ? (
          settings?.optimization_experiment_settings
            ?.constraints as components['schemas']['ClusterInequalityNetworkOptimizationExperimentSettingsConstraints']
        )?.constraints?.find(item => item.cluster_id === cluster?.id)
          ?.cluster_constraints[1]?.type
      : undefined;
    if (type !== constraintValue2 && constraintValue2 !== undefined) {
      setIncompleteConstraints(true);
      let initValues: Record<any, number> = {};
      fetchInequalityConstraints().then(response => {
        const clusterConstraints = response?.data.constraints?.find(
          constraint => constraint.cluster_id === cluster?.id,
        )?.cluster_constraints;
        if (clusterConstraints && clusterConstraints[0]?.type) {
          if (
            [
              'MaxSpecificPowerConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint',
              'MaxSpecificGasliftConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint',
            ].includes(clusterConstraints[0].type)
          ) {
            response?.data.constraints?.forEach(
              item =>
                (initValues = {
                  ...initValues,
                  ...{
                    [item.cluster_id]: item.cluster_constraints[0]
                      .value as number,
                  },
                }),
            );
          } else
            response?.data.constraints?.forEach(
              item =>
                (initValues = {
                  ...initValues,
                  ...{
                    [item.cluster_id]: (
                      item.cluster_constraints[0].value as any
                    )?.[getUnitByConstraint(item.cluster_constraints[0].type!)],
                  },
                }),
            );
        }
        saveInitValue(initValues).then();
      });
      setIncompleteConstraints(false);
    }
  }, [constraintValue2]);

  React.useEffect(() => {
    if (constraintValue1) {
      let valueToCompare;
      if (
        (
          settings?.optimization_experiment_settings
            ?.constraints as components['schemas']['ClusterInequalityNetworkOptimizationExperimentSettingsConstraints']
        )?.constraints?.find(item => item.cluster_id === cluster?.id)
          ?.cluster_constraints
      ) {
        valueToCompare = [
          'MaxSpecificPowerConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint',
          'MaxSpecificGasliftConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint',
        ].includes(constraintValue1)
          ? ((
              settings?.optimization_experiment_settings
                ?.constraints as components['schemas']['ClusterInequalityNetworkOptimizationExperimentSettingsConstraints']
            )?.constraints?.find(item => item.cluster_id === cluster?.id)
              ?.cluster_constraints[0]?.value as any)
          : (
              (
                settings?.optimization_experiment_settings
                  ?.constraints as components['schemas']['ClusterInequalityNetworkOptimizationExperimentSettingsConstraints']
              )?.constraints?.find(item => item.cluster_id === cluster?.id)
                ?.cluster_constraints[0]?.value as any
            )?.[getUnitByConstraint(constraintValue1)];
      }
      if (valueToCompare !== constraintNumber1) {
        tryToSaveConstraint(
          constraintValue1,
          constraintNumber1,
          cluster?.id,
          'first',
        ).then();
      }
    }
  }, [constraintNumber1]);

  React.useEffect(() => {
    if (constraintValue2) {
      let valueToCompare;
      if (
        (
          settings?.optimization_experiment_settings
            ?.constraints as components['schemas']['ClusterInequalityNetworkOptimizationExperimentSettingsConstraints']
        )?.constraints?.find(item => item.cluster_id === cluster?.id)
          ?.cluster_constraints
      ) {
        valueToCompare = [
          'MaxSpecificPowerConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint',
          'MaxSpecificGasliftConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint',
        ].includes(constraintValue2)
          ? ((
              settings?.optimization_experiment_settings
                ?.constraints as components['schemas']['ClusterInequalityNetworkOptimizationExperimentSettingsConstraints']
            )?.constraints?.find(item => item.cluster_id === cluster?.id)
              ?.cluster_constraints[1]?.value as any)
          : (
              (
                settings?.optimization_experiment_settings
                  ?.constraints as components['schemas']['ClusterInequalityNetworkOptimizationExperimentSettingsConstraints']
              )?.constraints?.find(item => item.cluster_id === cluster?.id)
                ?.cluster_constraints[1]?.value as any
            )?.[getUnitByConstraint(constraintValue2)];
      }
      if (valueToCompare !== constraintNumber2) {
        tryToSaveConstraint(
          constraintValue2,
          constraintNumber2,
          cluster?.id,
          'second',
        ).then();
      }
    }
  }, [constraintNumber2]);

  const onChangeConstraintValue1 = (value: InequalityConstraint) => {
    setConstraintValue1(value);
  };

  const onChangeConstraintValue2 = (value: InequalityConstraint) => {
    setConstraintValue2(value);
  };

  const onChangeClusterValue = (value: any) => {
    setCluster(clusters.find(item => item.id === value));
  };

  const onChangeConstraintNumber = () => {
    setIncompleteConstraints(true);
  };

  const saveConstraintNumber1 = (value: number) => {
    setConstraintNumber1(value);
  };

  const saveConstraintNumber2 = (value: number) => {
    setConstraintNumber2(value);
  };

  const getUnitByConstraint = (constraint: InequalityConstraint) => {
    switch (constraint) {
      case 'MinOilDebitInequalityNetworkOptimizationExperimentSettingsConstraint':
        return 't_per_day';
      case 'MaxEnergyConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint':
        return 'kW_h_per_day';
      case 'MaxActiveGasConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint':
      case 'MaxProducedPetroleumGasVolumeInequalityNetworkOptimizationExperimentSettingsConstraint':
      case 'MaxTotalGasConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint':
      case 'MinTotalGasConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint':
        return 'thousand_m3_per_day';
      case 'MaxProducedFluidVolumeInequalityNetworkOptimizationExperimentSettingsConstraint':
      case 'MaxProducedWaterVolumeInequalityNetworkOptimizationExperimentSettingsConstraint':
        return 'm3_per_day';
      case 'MaxSpecificPowerConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint':
        return '';
      case 'MaxSpecificGasliftConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint':
        return '';
      default:
        return '';
    }
  };

  const renderUnitByConstraint = (
    constraint: InequalityConstraint | undefined,
  ) => {
    switch (constraint) {
      case 'MinOilDebitInequalityNetworkOptimizationExperimentSettingsConstraint':
        return (
          <p className="active-object__row-input-unit">
            <span className="relative">т/сут</span>
          </p>
        );
      case 'MaxEnergyConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint':
        return (
          <p className="active-object__row-input-unit">
            <span className="relative">кВт·ч/сут</span>
          </p>
        );
      case 'MaxActiveGasConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint':
      case 'MaxProducedPetroleumGasVolumeInequalityNetworkOptimizationExperimentSettingsConstraint':
      case 'MaxTotalGasConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint':
      case 'MinTotalGasConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint':
        return (
          <p className="active-object__row-input-unit">
            <span className="relative">
              тыс.м<span className="index unit-small">3</span>
            </span>
            &nbsp; /сут
          </p>
        );
      case 'MaxProducedFluidVolumeInequalityNetworkOptimizationExperimentSettingsConstraint':
      case 'MaxProducedWaterVolumeInequalityNetworkOptimizationExperimentSettingsConstraint':
        return (
          <p className="active-object__row-input-unit">
            <span className="relative">
              м<span className="index unit-small">3</span>
            </span>
            &nbsp; /сут
          </p>
        );
      case 'MaxSpecificPowerConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint':
        return (
          <p className="active-object__row-input-unit">
            <span className="relative">кВт·ч/т</span>
          </p>
        );
      case 'MaxSpecificGasliftConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint':
        return (
          <p className="active-object__row-input-unit">
            <span className="relative">
              тыс.м<span className="index unit-small">3</span>
            </span>
            &nbsp; /т
          </p>
        );
      default:
        return <></>;
    }
  };

  const getNumberValueByConstraint = (
    constraint: InequalityConstraint,
    number: number,
  ) => {
    switch (constraint) {
      case 'MinOilDebitInequalityNetworkOptimizationExperimentSettingsConstraint':
        return { t_per_day: number };
      case 'MaxEnergyConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint':
        return { kW_h_per_day: number };
      case 'MaxActiveGasConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint':
      case 'MaxProducedPetroleumGasVolumeInequalityNetworkOptimizationExperimentSettingsConstraint':
      case 'MaxTotalGasConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint':
      case 'MinTotalGasConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint':
        return { thousand_m3_per_day: number };
      case 'MaxProducedFluidVolumeInequalityNetworkOptimizationExperimentSettingsConstraint':
      case 'MaxProducedWaterVolumeInequalityNetworkOptimizationExperimentSettingsConstraint':
        return { m3_per_day: number };
      case 'MaxSpecificPowerConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint':
        return number;
      case 'MaxSpecificGasliftConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint':
        return number;
      default:
        return {};
    }
  };

  const tryToSaveConstraint = async (
    constrValue: InequalityConstraint | undefined,
    constrNumber: number | undefined,
    clusterId: string | undefined,
    order: 'first' | 'second',
  ) => {
    const oldValue = { ...settings };
    if (
      settings?.optimization_experiment_settings &&
      constrValue &&
      constrNumber !== undefined
    ) {
      let newConstraint:
        | components['schemas']['ClusterInequalityNetworkOptimizationExperimentSettingsConstraintsForCluster']
        | Record<string, never> = {};

      if (order === 'first' && clusterId) {
        newConstraint = {
          cluster_id: clusterId,
          cluster_constraints: [
            {
              type: constrValue as any,
              value: getNumberValueByConstraint(
                constrValue,
                constrNumber,
              ) as any,
            },
            ...((
              settings?.optimization_experiment_settings
                .constraints as components['schemas']['ClusterInequalityNetworkOptimizationExperimentSettingsConstraints']
            )?.constraints
              ?.find(item => item.cluster_id === clusterId)
              ?.cluster_constraints?.filter(
                constraint => constraint.type === constraintValue2,
              ) || []),
          ],
        };
      } else if (order === 'second' && clusterId) {
        newConstraint = {
          cluster_id: clusterId,
          cluster_constraints: [
            ...((
              settings?.optimization_experiment_settings
                .constraints as components['schemas']['ClusterInequalityNetworkOptimizationExperimentSettingsConstraints']
            )?.constraints
              ?.find(item => item.cluster_id === clusterId)
              ?.cluster_constraints?.filter(
                constraint => constraint.type === constraintValue1,
              ) || []),
            {
              type: constrValue as any,
              value: getNumberValueByConstraint(
                constrValue,
                constrNumber,
              ) as any,
            },
          ],
        };
      }
      const newValue = {
        ...settings,
        optimization_experiment_settings: {
          ...settings.optimization_experiment_settings,
          constraints: {
            type: 'ClusterInequalityNetworkOptimizationExperimentSettingsConstraints' as any,
            constraints: [
              ...((
                settings?.optimization_experiment_settings
                  .constraints as components['schemas']['ClusterInequalityNetworkOptimizationExperimentSettingsConstraints']
              )?.constraints
                ?.filter(clusterItem => clusterItem.cluster_id !== clusterId)
                ?.filter(clusterItem => clusterItem?.cluster_constraints)
                ?.filter(
                  clusterItem =>
                    (clusterItem.cluster_constraints[0] !== undefined &&
                      clusterItem.cluster_constraints[0]?.type ===
                        constraintValue1) ||
                    (clusterItem.cluster_constraints[1] !== undefined &&
                      clusterItem.cluster_constraints[1]?.type ===
                        constraintValue2),
                ) || []),
              newConstraint,
            ],
          },
        },
      };
      setExperimentSettingsSaved(false);
      try {
        const response = await setExperimentSettings(
          params.id,
          newValue as any,
        );
        dispatch(setStoreExperimentSettings(response.data));
        setExperimentSettingsSaved(true);
      } catch (e) {
        dispatch(setStoreExperimentSettings(newValue as any));
        dispatch(setStoreExperimentSettings(oldValue));
      }
    }
  };

  const tryToSaveConstraintWithSettings = async (
    constrValue: InequalityConstraint | undefined,
    constrNumbers: Record<any, number>,
    order: 'first' | 'second',
  ) => {
    const oldValue = { ...settings };
    let newValue: components['schemas']['ExperimentSettings'] = { ...settings };
    if (newValue?.optimization_experiment_settings && constrValue) {
      let newConstraint:
        | components['schemas']['ClusterInequalityNetworkOptimizationExperimentSettingsConstraintsForCluster']
        | Record<string, never> = {};

      for (const cl of clusters) {
        if (order === 'first' && cl.id) {
          newConstraint = {
            cluster_id: cl.id,
            cluster_constraints: [
              {
                type: constrValue as any,
                value: getNumberValueByConstraint(
                  constrValue,
                  constrNumbers[cl.id],
                ) as any,
              },
              ...((
                newValue?.optimization_experiment_settings
                  ?.constraints as components['schemas']['ClusterInequalityNetworkOptimizationExperimentSettingsConstraints']
              )?.constraints
                ?.find(item => item.cluster_id === cl.id)
                ?.cluster_constraints?.filter(
                  constraint => constraint.type === constraintValue2,
                ) || []),
            ],
          };
        } else if (order === 'second' && cl.id) {
          newConstraint = {
            cluster_id: cl.id,
            cluster_constraints: [
              ...((
                newValue?.optimization_experiment_settings
                  ?.constraints as components['schemas']['ClusterInequalityNetworkOptimizationExperimentSettingsConstraints']
              )?.constraints
                ?.find(item => item.cluster_id === cl.id)
                ?.cluster_constraints?.filter(
                  constraint => constraint.type === constraintValue1,
                ) || []),
              {
                type: constrValue as any,
                value: getNumberValueByConstraint(
                  constrValue,
                  constrNumbers[cl.id],
                ) as any,
              },
            ],
          };
        }
        newValue = {
          ...newValue,
          optimization_experiment_settings: {
            ...newValue.optimization_experiment_settings,
            constraints: {
              type: 'ClusterInequalityNetworkOptimizationExperimentSettingsConstraints' as any,
              constraints: [
                ...((
                  newValue?.optimization_experiment_settings
                    ?.constraints as components['schemas']['ClusterInequalityNetworkOptimizationExperimentSettingsConstraints']
                )?.constraints
                  ?.filter(clusterItem => clusterItem.cluster_id !== cl.id)
                  ?.filter(clusterItem => clusterItem?.cluster_constraints)
                  ?.filter(
                    clusterItem =>
                      (clusterItem.cluster_constraints[0] !== undefined &&
                        clusterItem.cluster_constraints[0]?.type ===
                          constraintValue1) ||
                      (clusterItem.cluster_constraints[1] !== undefined &&
                        clusterItem.cluster_constraints[1]?.type ===
                          constraintValue2),
                  ) || []),
                newConstraint,
              ] as any,
            },
          },
        };
      }
      setExperimentSettingsSaved(false);
      try {
        const response = await setExperimentSettings(
          params.id,
          newValue as any,
        );
        dispatch(setStoreExperimentSettings(response.data));
        setExperimentSettingsSaved(true);
      } catch (e) {
        dispatch(setStoreExperimentSettings(newValue as any));
        dispatch(setStoreExperimentSettings(oldValue));
      }
    }
  };

  return (
    <>
      <div className="project-task-settings__separator" />
      <div className="active-object__row">
        <ObjectSelect
          label="Ограничение 1"
          classNamesWrapper="full-width"
          classNames="active-object__row-input"
          name="11"
          options={constraintsOptionsCurrent || []}
          value={constraintsOptionsCurrent?.find(
            item => item.value === constraintValue1,
          )}
          isOptionDisabled={(option: any) => (option as any)?.disabled}
          saveNewValue={onChangeConstraintValue1}
        />
      </div>
      <div className="active-object__row">
        <ObjectSelect
          label="Ограничение 2"
          classNamesWrapper="full-width"
          classNames="active-object__row-input"
          name="11"
          options={constraintsOptionsCurrent || []}
          value={constraintsOptionsCurrent?.find(
            item => item.value === constraintValue2,
          )}
          isOptionDisabled={(option: any) => (option as any)?.disabled}
          saveNewValue={onChangeConstraintValue2}
        />
      </div>
      <div className="project-task-settings__separator" />
      <div className="active-object__row">
        <ObjectSelect
          label="Кластер"
          classNamesWrapper="full-width"
          classNames="active-object__row-input"
          name="11"
          options={
            clusters
              ?.filter(clusterItem => !clusterItem.is_empty)
              ?.map(item => {
                return { label: item.name, value: item.id };
              }) || []
          }
          value={
            cluster ? { label: cluster.name, value: cluster.id } : undefined
          }
          saveNewValue={onChangeClusterValue}
        />
      </div>
      <div className="active-object__row">
        <div className="project-task-settings__row long active-object__input-with-unit-wrapper">
          <ObjectInput
            className="input active-object__row-input with-long-unit"
            label={
              constraintsOptionsCurrent?.find(
                item => item.value === constraintValue1,
              )?.label || `‎`
            }
            name="gas_constraint"
            initValue={constraintNumber1}
            minValue={0}
            saveNewValue={saveConstraintNumber1}
            onChangeValue={onChangeConstraintNumber}
          />
          {renderUnitByConstraint(constraintValue1)}
        </div>
        <div className="active-object__row">
          <div className="project-task-settings__row long active-object__input-with-unit-wrapper">
            <ObjectInput
              className="input active-object__row-input with-long-unit"
              label={
                constraintsOptionsCurrent?.find(
                  item => item.value === constraintValue2,
                )?.label || `‎`
              }
              name="gas_constraint"
              initValue={constraintNumber2}
              minValue={0}
              saveNewValue={saveConstraintNumber2}
              onChangeValue={onChangeConstraintNumber}
            />
            {renderUnitByConstraint(constraintValue2)}
          </div>
        </div>
      </div>
    </>
  );
};

export default ClusterEqualityConstraint;
