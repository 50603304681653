import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectActiveObject, setActiveObject } from 'store/mapSlice';

import { setShowDetailsPanel } from '../../../store/projectSlice';
import { IActiveObject } from '../types';
import AllSegments from './AllSegments';
import AllSources from './AllSources';
import InjWellPPD from './InjWellPPD/InjWellPPD';
import Junction from './Junction';
import LayerPPD from './LayerPPD/LayerPPD';
import Package from './Package/Package';
import ProdWellPPD from './ProdWellPPD/ProdWellPPD';
import PumpPPD from './PumpPPD/PumpPPD';
import Segment from './Segment';
import Separator from './Separator';
import SeparatorPPD from './SeparatorPPD/SeparatorPPD';
import GatheringCenter from './Sink';
import SinkPPD from './SinkPPD/SinkPPD';
import Source from './Source/Source';
import SourcePPD from './SourcePPD/SourcePPD';

const ActiveObject: React.FC<IActiveObject> = ({ projectUid }) => {
  const dispatch = useDispatch();
  const activeObject = useSelector(selectActiveObject);
  React.useEffect(() => {
    const onKeyDownHandler = (e: React.KeyboardEvent) => {
      const { key } = e;
      if (key === 'Escape') {
        dispatch(
          setActiveObject({
            node_uid: null,
            type: null,
            object_uid: null,
            object_name: null,
          }),
        );
        dispatch(setShowDetailsPanel(null));
      }
    };
    document.addEventListener('keydown', onKeyDownHandler as any);
    return () => {
      document.removeEventListener('keydown', onKeyDownHandler as any);
    };
  }, [activeObject]);

  if (activeObject?.type === 'ALLSOURCES') {
    return <AllSources projectUid={projectUid} />;
  }

  if (activeObject?.type === 'ALLPIPELINES') {
    return <AllSegments />;
  }

  if (!activeObject.object_uid) return null;

  if (activeObject?.type === 'PACKAGE') {
    return (
      <Package objectUid={activeObject.object_uid} projectUid={projectUid} />
    );
  }

  if (activeObject?.type === 'EQUIPMENT') {
    return (
      <Separator objectUid={activeObject.object_uid} projectUid={projectUid} />
    );
  }

  if (activeObject.type === 'PIPELINE' || activeObject.type === 'LINES') {
    return (
      <Segment objectUid={activeObject.object_uid} projectUid={projectUid} />
    );
  }

  if (activeObject.type === 'SINK') {
    return (
      <GatheringCenter
        objectUid={activeObject.object_uid}
        projectUid={projectUid}
      />
    );
  }

  if (activeObject?.type === 'JUNCTION') {
    return (
      <Junction objectUid={activeObject.object_uid} projectUid={projectUid} />
    );
  }

  if (activeObject?.type === 'PUMP_PPD') {
    return (
      <PumpPPD objectUid={activeObject.object_uid} projectUid={projectUid} />
    );
  }

  if (activeObject?.type === 'LAYER_PPD') {
    return (
      <LayerPPD objectUid={activeObject.object_uid} projectUid={projectUid} />
    );
  }

  if (activeObject?.type === 'THREE_PHASE_SEPARATOR_PPD') {
    return (
      <SeparatorPPD
        objectUid={activeObject.object_uid}
        projectUid={projectUid}
      />
    );
  }

  if (!activeObject.node_uid) return null;

  if (
    activeObject?.type === 'SOURCE' ||
    activeObject?.type === 'SOURCESTOPPED'
  ) {
    return (
      <Source
        nodeUid={activeObject.node_uid}
        objectUid={activeObject.object_uid}
        projectUid={projectUid}
      />
    );
  }

  if (
    activeObject?.type === 'PROD_WELL_PPD' ||
    activeObject?.type === 'PROD_WELL_PPDSTOPPED'
  ) {
    return (
      <ProdWellPPD
        nodeUid={activeObject.node_uid}
        objectUid={activeObject.object_uid}
        projectUid={projectUid}
      />
    );
  }

  if (
    activeObject?.type === 'INJ_WELL_PPD' ||
    activeObject?.type === 'INJ_WELL_PPDSTOPPED'
  ) {
    return (
      <InjWellPPD
        nodeUid={activeObject.node_uid}
        objectUid={activeObject.object_uid}
        projectUid={projectUid}
      />
    );
  }

  if (activeObject?.type === 'SOURCE_PPD') {
    return (
      <SourcePPD objectUid={activeObject.object_uid} projectUid={projectUid} />
    );
  }

  if (activeObject.type === 'SINK_PPD') {
    return (
      <SinkPPD objectUid={activeObject.object_uid} projectUid={projectUid} />
    );
  }

  return null;
};

export default ActiveObject;
