import * as React from 'react';

import { threeDigitsFormatter, twoDigitsFormatter } from 'utils';

export const templateRowsNumber = 5;

export const segmentsTableHead = {
  start_node_name: {
    title: 'Начальный узел',
    component: <>Начальный узел</>,
  },
  end_node_name: {
    title: 'Конечный узел',
    component: <>Конечный узел</>,
  },
  oil_flow_rate: {
    title: 'Расход нефти',
    component: (
      <>
        Расход нефти, <br />
        т/сут
      </>
    ),
  },
  fluid_flow_rate: {
    title: 'Расход жидкости',
    component: (
      <>
        Расход жидкости,
        <br />
        м3/сут
      </>
    ),
  },
  gas_flow_rate: {
    title: 'Расход газа',
    component: (
      <>
        Расход газа,
        <br />
        тыс.м3/сут
      </>
    ),
  },
  p_in: {
    title: 'Давление на входе',
    component: (
      <>
        Давление на входе,
        <br />
        атм
      </>
    ),
  },
  dp_dl: {
    title: 'Перепад давлений',
    component: (
      <>
        Перепад давлений,
        <br />
        атм/км
      </>
    ),
  },
  dt_dl: {
    title: 'Перепад температур',
    component: (
      <>
        Перепад температур, <br />
        ℃/км
      </>
    ),
  },
  fluid_velocity: {
    title: 'Макс. скорость жидкости',
    component: (
      <>
        Макс. скорость жидкости <br />
        м/с
      </>
    ),
  },
  gas_velocity: {
    title: 'Макс. скорость газа',
    component: (
      <>
        Макс. скорость газа <br />
        м/с
      </>
    ),
  },
};

export const segmentsTableItems = ({
  start_node_name,
  end_node_name,
  compare_oil_flow_rate,
  compare_fluid_flow_rate,
  compare_gas_flow_rate,
  compare_pressure_in,
  compare_pressure_drop,
  compare_temperature_drop,
  compare_fluid_velocity,
  compare_gas_velocity,
}: any = {}) => [
  {
    dem: 'start_node_name',
    value: start_node_name,
  },
  {
    dem: 'end_node_name',
    value: end_node_name,
  },
  {
    dem: 'oil_flow_rate',
    value: compare_oil_flow_rate?.fact && compare_oil_flow_rate.fact.t_per_day,
    abs: compare_oil_flow_rate?.abs?.t_per_day,
    rel: compare_oil_flow_rate?.rel,
    unit: 'т/сут',
  },
  {
    dem: 'fluid_flow_rate',
    value:
      compare_fluid_flow_rate?.fact && compare_fluid_flow_rate.fact.m3_per_day,
    abs: compare_fluid_flow_rate?.abs?.m3_per_day,
    rel: compare_fluid_flow_rate?.rel,
    unit: 'м3/сут',
  },
  {
    dem: 'gas_flow_rate',
    value:
      compare_gas_flow_rate?.fact &&
      compare_gas_flow_rate?.fact?.thousand_m3_per_day,
    abs: compare_gas_flow_rate?.abs?.thousand_m3_per_day,
    rel: compare_gas_flow_rate?.rel,
    unit: 'тыс. м3/сут',
  },
  {
    dem: 'pressure_in',
    value: compare_pressure_in?.fact && compare_pressure_in.fact.atm,
    abs: compare_pressure_in?.abs?.atm,
    rel: compare_pressure_in?.rel,
    unit: 'атм',
  },
  {
    dem: 'pressure_drop',
    value: compare_pressure_drop?.fact && compare_pressure_drop.fact.atm_per_km,
    abs: compare_pressure_drop?.abs?.atm_per_km,
    rel: compare_pressure_drop?.rel,
    unit: 'атм/км',
  },
  {
    dem: 'temperature_drop',
    value:
      compare_temperature_drop?.fact &&
      compare_temperature_drop.fact.celsius_per_km,
    abs: compare_temperature_drop?.abs?.celsius_per_km,
    rel: compare_temperature_drop?.rel,
    unit: '℃/км',
  },
  {
    dem: 'fluid_velocity',
    value:
      compare_fluid_velocity?.fact && compare_fluid_velocity.fact.m_per_second,
    abs: compare_fluid_velocity?.abs?.m_per_second,
    rel: compare_fluid_velocity?.rel,
    unit: 'м/с',
  },
  {
    dem: 'gas_velocity',
    value: compare_gas_velocity?.fact && compare_gas_velocity.fact.m_per_second,
    abs: compare_gas_velocity?.abs?.m_per_second,
    rel: compare_gas_velocity?.rel,
    unit: 'м/с',
  },
];
