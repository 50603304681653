import * as React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import Equipments from '../Equipments';
import Layers from '../Layers';
import Nodes from '../Nodes';
import Segments from '../Segments';
import Sources from '../Sources';

const MOResults: React.FC = () => {
  const [tabIndex, setTabIndex] = React.useState(0);

  return (
    <Tabs
      className="results__tabs"
      selectedIndex={tabIndex}
      onSelect={index => setTabIndex(index)}
    >
      <TabList className="results__tab-list underline">
        <Tab className="results__tab">Скважины</Tab>
        <Tab className="results__tab">Узлы</Tab>
        <Tab className="results__tab">Трубы</Tab>
        <Tab className="results__tab">Оборудование</Tab>
        <Tab className="results__tab">Пласт</Tab>
      </TabList>
      <div className="results-tables__container margin-top">
        <TabPanel>
          <Sources />
        </TabPanel>
        <TabPanel>
          <Nodes />
        </TabPanel>
        <TabPanel>
          <Segments />
        </TabPanel>
        <TabPanel>
          <Equipments />
        </TabPanel>
        <TabPanel>
          <Layers />
        </TabPanel>
      </div>
    </Tabs>
  );
};

export default MOResults;
