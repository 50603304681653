import { threeDigitsFormatter, twoDigitsFormatter } from 'utils';

export const templateRowsNumber = 5;

export const sinksTableItems = ({
  node_name,
  compare_oil_flow_rate,
  compare_fluid_flow_rate,
  compare_gas_flow_rate,
  compare_pressure,
  compare_temperature,
}: any = {}) => [
  {
    dem: 'name',
    value: node_name,
  },
  {
    dem: 'oil_flow_rate',
    value: compare_oil_flow_rate?.fact && compare_oil_flow_rate.fact.t_per_day,
    abs: compare_oil_flow_rate?.abs?.t_per_day,
    rel: compare_oil_flow_rate?.rel,
    unit: 'т/сут',
  },
  {
    dem: 'fluid_flow_rate',
    value:
      compare_fluid_flow_rate?.fact && compare_fluid_flow_rate.fact.m3_per_day,
    abs: compare_fluid_flow_rate?.abs?.m3_per_day,
    rel: compare_fluid_flow_rate?.rel,
    unit: 'м3/сут',
  },
  {
    dem: 'gas_flow_rate',
    value:
      compare_gas_flow_rate?.fact &&
      compare_gas_flow_rate?.fact?.thousand_m3_per_day,
    abs: compare_gas_flow_rate?.abs?.thousand_m3_per_day,
    rel: compare_gas_flow_rate?.rel,
    unit: 'тыс. м3/сут',
  },
  {
    dem: 'pressure',
    value: compare_pressure?.fact && compare_pressure.fact.atm,
    abs: compare_pressure?.abs?.atm,
    rel: compare_pressure?.rel,
    unit: 'атм',
  },
  {
    dem: 'temperature',
    value: compare_temperature?.fact && compare_temperature.fact.celsius,
    abs: compare_temperature?.abs?.celsius,
    rel: compare_temperature?.rel,
    unit: '℃',
  },
];
