import * as React from 'react';

import { threeDigitsFormatter, twoDigitsFormatter } from 'utils';

export const templateRowsNumber = 5;

export const segmentsTableHead = {
  start_node_name: {
    title: 'Начальный узел',
    component: <>Начальный узел</>,
  },
  end_node_name: {
    title: 'Конечный узел',
    component: <>Конечный узел</>,
  },
  oil_flow_rate: {
    title: 'Расход нефти',
    component: (
      <>
        Расход нефти, <br />
        т/сут
      </>
    ),
  },
  fluid_flow_rate: {
    title: 'Расход жидкости',
    component: (
      <>
        Расход жидкости,
        <br />м<span className="sup">3</span>/сут
      </>
    ),
  },
  gas_flow_rate: {
    title: 'Расход газа',
    component: (
      <>
        Расход газа,
        <br />
        тыс.м<span className="sup">3</span>/сут
      </>
    ),
  },
  p_in: {
    title: 'Давление на входе',
    component: (
      <>
        Давление на входе,
        <br />
        атм
      </>
    ),
  },
  dp_dl: {
    title: 'Перепад давлений',
    component: (
      <>
        Перепад давлений,
        <br />
        атм/км
      </>
    ),
  },
  dt_dl: {
    title: 'Перепад температур',
    component: (
      <>
        Перепад температур, <br />
        ℃/км
      </>
    ),
  },
  fluid_velocity: {
    title: 'Макс. скорость жидкости',
    component: (
      <>
        Макс. скорость жидкости <br />
        м/с
      </>
    ),
  },
  gas_velocity: {
    title: 'Макс. скорость газа',
    component: (
      <>
        Макс. скорость газа <br />
        м/с
      </>
    ),
  },
};

export const segmentsTableItems = ({
  start_node_name,
  end_node_name,
  oil_flow_rate_mass,
  oil_flow_rate_mass_abs,
  oil_flow_rate_mass_rel,
  fluid_flow_rate_volume,
  fluid_flow_rate_volume_abs,
  fluid_flow_rate_volume_rel,
  gas_flow_rate_volume,
  gas_flow_rate_volume_abs,
  gas_flow_rate_volume_rel,
  pressure_in,
  pressure_in_abs,
  pressure_in_rel,
  dp_dl,
  dp_dl_abs,
  dp_dl_rel,
  dt_dl,
  dt_dl_abs,
  dt_dl_rel,
  fluid_velocity,
  fluid_velocity_abs,
  fluid_velocity_rel,
  gas_velocity,
  gas_velocity_abs,
  gas_velocity_rel,
}: any = {}) => [
  {
    dem: 'start_node_name',
    value: start_node_name,
  },
  {
    dem: 'end_node_name',
    value: end_node_name,
  },
  {
    dem: 'oil_flow_rate',
    value: oil_flow_rate_mass && oil_flow_rate_mass.t_per_day,
    abs: oil_flow_rate_mass_abs?.t_per_day,
    rel: oil_flow_rate_mass_rel,
    unit: 'т/сут',
  },
  {
    dem: 'fluid_flow_rate',
    value: fluid_flow_rate_volume && fluid_flow_rate_volume.m3_per_day,
    abs: fluid_flow_rate_volume_abs?.m3_per_day,
    rel: fluid_flow_rate_volume_rel,
    unit: (
      <>
        м<span className="sup">3</span>/сут
      </>
    ),
  },
  {
    dem: 'gas_flow_rate',
    value: gas_flow_rate_volume && gas_flow_rate_volume?.thousand_m3_per_day,
    abs: gas_flow_rate_volume_abs?.thousand_m3_per_day,
    rel: gas_flow_rate_volume_rel,
    unit: (
      <>
        тыс. м<span className="sup">3</span>/сут
      </>
    ),
  },
  {
    dem: 'pressure_in',
    value: pressure_in && pressure_in.atm,
    abs: pressure_in_abs?.atm,
    rel: pressure_in_rel,
    unit: 'атм',
  },
  {
    dem: 'pressure_drop',
    value: dp_dl && dp_dl.atm_per_km,
    abs: dp_dl_abs?.atm_per_km,
    rel: dp_dl_rel,
    unit: 'атм/км',
  },
  {
    dem: 'temperature_drop',
    value: dt_dl && dt_dl.celsius_per_km,
    abs: dt_dl_abs?.celsius_per_km,
    rel: dt_dl_rel,
    unit: '℃/км',
  },
  {
    dem: 'fluid_velocity',
    value: fluid_velocity && fluid_velocity.m_per_second,
    abs: fluid_velocity_abs?.m_per_second,
    rel: fluid_velocity_rel,
    unit: 'м/с',
  },
  {
    dem: 'gas_velocity',
    value: gas_velocity && gas_velocity.m_per_second,
    abs: gas_velocity_abs?.m_per_second,
    rel: gas_velocity_rel,
    unit: 'м/с',
  },
];
